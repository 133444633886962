import React, { useRef, useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import Assets from '../../lib/Assets';
import { Button, Dialog } from '@mui/material';
import Utils from '../../lib/Utils';

/**
 * Help user to report an error
 * @returns 
 */
const ReportError = () => {
    console.log('{ReportError}');
    const [error, setErrorInputForm] = useState({
        show: false,
        data: {}
    });
    const [input,setFeedbackInput] = useState({
        userFeedback: '',
        email: '',
        firstname: '',
        lastname: ''
    });
    const [reportSent, setErrorReportSent] = useState({
        sent: false,
        View: () => (<></>)
    });
    const [disabled, setSubmitButtonDisabled] = useState(false);
    const [emailError, setEmailError] = useState({
        show: false,
        msg: ''
    })
    const userProfile = useRef(Utils.getCachedUserProfile());
    StateUpdators.setErrorReportSent = setErrorReportSent;
    StateUpdators.setErrorInputForm = setErrorInputForm;
    StateUpdators.setSubmitButtonDisabled = setSubmitButtonDisabled;
    useState(() => {
        if(userProfile.current) {
            setFeedbackInput({
                ...input,
                email: userProfile.current.email??'',
                lastname: userProfile.current.lastname??'',
                firstname: userProfile.current.firstname??''
            })
        }
    },[])
    return (
        <>
        <Dialog open={error.show}>
        {
            error.show && 
            <div className='animate__animated animate__fadeIn report-error-ndhd error-bnvgfg rounded'>
                <img id='dismisss-icons0jfhfgh' src={Assets.icons.closeView} alt='Dismiss' title='Dismiss' className='ptr' onClick={() => {
                    StateUpdators.setErrorInputForm({show: false});
                }}/>
                <div className='the-error-input-form'>
                    <h3 className='gfgfg-r'>Report an issue</h3>
                    <div className='report-details width-90 h-centred'>
                        <p className='error-issue-hdr'>Report details:</p>
                        <p className='error-issue-data brd-err rounded'>{JSON.stringify({
                            ...error.data,
                            location: window.location.href
                        })}</p>
                    </div>
                    
                    <div className='input-form width-90 h-centred'>
                        <p className='nvhf-error pointer' title='So that we can contact you'>Enter your email address:</p>
                        <input id='ncbd-hfgfg-nfhfh' type='text' placeholder='e.g you@example.com' className='input-dhdhdhg brd-err-input rounded' value={input.email} onChange={(e) => {
                            setFeedbackInput({
                                ...input,
                                email: e.currentTarget.value
                            });
                        }} onFocus={() => {
                            setEmailError({show:false,msg: ''});
                        }}/>
                        <p className='nvhf-error pointer'>Firstname:</p>
                        <input id='ncbd-hfgfg-nfhfh' type='text' placeholder='firstname' className='input-dhdhdhg brd-err-input rounded' value={input.firstname} onChange={(e) => {
                            setFeedbackInput({
                                ...input,
                                firstname: e.currentTarget.value
                            });
                        }} onFocus={() => {
                            setEmailError({show:false,msg: ''});
                        }}/>
                        <p className='nvhf-error pointer'>Lastname:</p>
                        <input id='ncbd-hfgfg-nfhfh' type='text' placeholder='lastname' className='input-dhdhdhg brd-err-input rounded' value={input.lastname} onChange={(e) => {
                            setFeedbackInput({
                                ...input,
                                lastname: e.currentTarget.value
                            });
                        }} onFocus={() => {
                            setEmailError({show:false,msg: ''});
                        }}/>
                        {
                            emailError.show && 
                            <div className='error-mfhh-email rounded animate__animated animate__fadeIn'>
                                <p className='error'>{emailError.msg}</p>
                            </div>
                        }
                        <p className='nvhf-error'>Feedback:</p>
                        <textarea className='text-ar-ffgdfd rounded brd-err-input text-input-multiline' value={input.userFeedback} placeholder='What happened?' onChange={(e) => {
                            setFeedbackInput({
                                ...input,
                                userFeedback: e.currentTarget.value
                            })
                        }}>
                        </textarea>
                    </div>
                    <div className='hfgfg-btn-jgjhg'>
                        <Button disabled={disabled} className='force-round-btn'  variant='contained' onClick={() => {
                            if(!input.email) {
                                setEmailError({
                                    show: true,
                                    msg: 'Please enter an email'
                                });
                                return;
                            }
                            if(!input.firstname) {
                                setEmailError({
                                    show: true,
                                    msg: 'Please enter your firstname'
                                });
                                return;
                            }
                            if(!input.lastname) {
                                setEmailError({
                                    show: true,
                                    msg: 'Please enter your lastname'
                                });
                                return;
                            }
                            if(!Utils.isValidEmail(input.email)) {
                                setEmailError({
                                    show: true,
                                    msg: 'Please enter a valid email'
                                });
                                return;
                            }
                            setEmailError({show:false,msg: ''});
                            setSubmitButtonDisabled(true);
                            Utils.reportIssue({...input,...error});
                        }}>
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        }
        {
            reportSent.sent && 
            <div className='animate__animated animate__fadeIn report-error-ndhd error-bnvgfg rounded'>
                <img id='dismisss-icons0jfhfgh' src={Assets.icons.closeView} alt='Dismiss' title='Dismiss' className='ptr' onClick={() => {
                    StateUpdators.setErrorInputForm({show: false});
                    StateUpdators.setErrorReportSent({sent: false});
                }}/>
                <div className='send-report-details'>
                    <reportSent.View />
                </div>
            </div>
        }
        </Dialog>
        </>
    )
}
export default ReportError;