import React, { Suspense, useEffect, useState } from 'react';
import Utils from '../lib/Utils';
import LoadingComponent from './tools/LoadingComponent';
import Error from './tools/Error';
import ReportError from './tools/ReportError';
import Loader from './tools/Loader';
import Assets from '../lib/Assets';
import QuickInfoCollectForm from './tools/QuickInfoCollectForm';
import RightSidePopin from './tools/RightSidePopin';
import StateUpdators from '../lib/StateUpdators';

/**
 * The Quiz Module/App
 * @returns React Comp
 */
const QuizClearSession = () => {
    console.log('{QuizClearSession}');
    const [sessionCleared, setSessionCleared] = useState(false);
    StateUpdators.setSessionCleared = setSessionCleared;
    const QuizType = React.lazy(() => import(`./tools/_quizzes/AboutELearnerQuizzes`));
    useEffect(() => {
        Utils.clearQuizSessionData();
        Utils.addAnalytics({
            page: 'QuizClearSession',
            url: window.location.href,
            title: 'NS.QuizClearSessionPageVisit'
        });
    },[]);
    return (
        <>
        <div className='el-quiz-cont cool-bkg-1 animate__animted animate__fadeIn'>
            <img className='bkg-img-cool' src={Assets.schoolyImag} alt='School Woman' />
            <Suspense fallback={<LoadingComponent />}>
                <QuizType sessionCleared={sessionCleared} />
            </Suspense>
        </div>
        <Loader />
        <Error />
        <ReportError />
        <QuickInfoCollectForm />
        <RightSidePopin />
        </>
    )
}

export default QuizClearSession;