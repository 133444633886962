import React from 'react';
import Assets from '../../lib/Assets';

const LoadingComponent = () => {
    return (
        <>
        <div className='fgfgfg-loader-comp'>
            <img src={Assets.icons.smallLoader} alt='Loading' />
        </div>
        </>
    )
}

export default LoadingComponent;