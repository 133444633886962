import React, { useState } from 'react';
import ElearnerAndICDLLogos from '../ElearnerAndICDLLogos';
import Texts from '../../../lib/Texts';
import Assets from '../../../lib/Assets';
import CandidateDetails from '../CandidateDetails';
import QuizQuestions from './QuizQuestions';
import ActionModal from '../ActionModal';
import StateUpdators from '../../../lib/StateUpdators';

/**
 * The El PreTest App
 * @returns 
 */
const ElearnerQuizApp = () => {
    console.log('{ElearnerQuizApp}');
    const [_stepsImage, setStepsImage] = useState(1);
    StateUpdators.setStepsImage = setStepsImage;
    return (
        <>
        <ElearnerAndICDLLogos />
        <div className='content-ngfgdfs'>
            <h1>{Texts.pretest.header}</h1>
            <div className='jfhfgf-nfhfhf bkg-white rounded centred w-80'>
                <img id='form-steps' className='bgfgdh-mfhfh' src={Assets.icons.formSteps[_stepsImage]} alt='Form Steps' />
                <CandidateDetails />
                <QuizQuestions />
            </div>
        </div>
        <ActionModal />
        </>
    )
}

export default ElearnerQuizApp;