import React, { useEffect,useRef, useState } from 'react';
import Utils from '../../lib/Utils';
import Texts from '../../lib/Texts';
import { useParams } from 'react-router-dom';
import AccountHeader from './AccountHeader';
import SmallLoader from './SmallLoader';
import StateUpdators from '../../lib/StateUpdators';

/**
 * Initialize a Curriculum subscrion payment
 * @param {object} info The info object 
 * @returns 
 */
const CurriculumSubscriptionPayment = () => {
    console.log('{CurriculumSubscriptionPayment}');
    const token = useRef(useParams().token);
    const [planInfo, setPlanInfo] = useState({
        C: () => <SmallLoader customClasses='small-loader' />
    })
    StateUpdators.setPlanInfoContent = setPlanInfo;
    useEffect(() => {
        Utils.setPageTitleFull({
            title: `Computers 4 Kids | Classesonline > Pay For Subscription Plan`
        })
        if(!token.current) {
            Utils.handleError({error: Texts.errorPaymentInit});
        } else {
            Utils.fetchSubscriptionPlanInfo({token: token.current});
        }
        Utils.addAnalytics({
            page: 'CurriculumSubscriptionPayment',
            url: window.location.href,
            title: 'NS.CurriculumSubscriptionPayment.Initialize'
        });
    },[])
    return (
        <>
        <AccountHeader showPopupIfAccountIncomplete={true} />
        <div className='cbbdg-curriculums-fhgf animate__animated animate__fadeIn jsjs-pwwkw'>
            <div className='w-80 h-centred bkg-w rounded nchfh-kfhf shadow box-b'>
                <planInfo.C />
            </div>
        </div>
        </>
    )
}

export default CurriculumSubscriptionPayment;