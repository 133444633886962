import React from 'react';
import Assets from '../../lib/Assets';

/**
 * ICDL and e-Learner logos
 * @returns 
 */
const ElearnerAndICDLLogos = () => {
    return (
        <>
        <img src={Assets.eLearnerLogo} alt='e-Learner' className='e-el-logo rounded shadow' />
        <img src={Assets.ICDLLogo} alt='ICDL' className='e-icdl-logo rounded shadow' />
        </>
    )
}

export default ElearnerAndICDLLogos;