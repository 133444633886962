import React, { useEffect, useState } from 'react';

/**
 * QuizTimer
 * @returns 
 */
const QuizTimer = ({duration}) => {
    //console.log('{QuizTimer}');
    const [_info, setInfo] = useState({
        seconds: 0,
        minutes: 0
    });
    useEffect(() => {
        const timerId = setInterval(() => {
            setInfo(info => {
                if(info.seconds >= 60) {
                    info.seconds = 0;
                    info.minutes += 1;
                } else {
                    info.seconds +=1;
                }
                if(info.minutes === duration) {
                    info.minutes = duration;
                    clearInterval(timerId);
                }
                return {
                    ...info
                }
            });
        },1000);
        return () => {
            clearInterval(timerId);
        }
    },[duration]);
    return (
        <>
        {_info.minutes < 10 ? `0${_info.minutes}:` : `${_info.minutes}:`}{_info.seconds < 10 ? `0${_info.seconds}` : `${_info.seconds}`}
        </>
    )
}

export default QuizTimer;