import React, { useEffect, useState,useRef } from 'react';
import Utils from '../../lib/Utils';
import PluggableLoader from './PluggableLoader';
import StateUpdators from '../../lib/StateUpdators';

/**
 * Digital Badge Album
 * @returns 
 */
const DigitalBadgeAlbum = () => {
    console.log('{DigitalBadgeAlbum}');
    const [C, setDBAlbumContent] = useState({
        data: {},
        C: () => <PluggableLoader msg={'Fetching album'} title={'Fetching album'} show={true} />
    });
    const refched = useRef(null);
    StateUpdators.setDBAlbumContent = setDBAlbumContent;
    useEffect(() => {
        if(!refched.current) {
            Utils.fetchDigitalBadgesAlbum();
            refched.current = true;
        }
    });
    return (
        <>
        <C.C />
        </>
    )
}

export default DigitalBadgeAlbum;