import React from 'react';
import CloseIcon from './CloseIcon';

/**
 * Popup title handle
 * @param {object} props The props object with the title prop and the onClose method
 */
const CRCPopupHandleTitle = (props) => {
    console.log('{CRCPopupHandleTitle}');
    return (
        <>
        <div className={`instructions-jfhdh-lsk w-30 centred rounded shadow box-b rounded-right`}>
            <div className='big-dot-red ptr rounded-left' onClick={() => {
                if(props.onLeftIconClick) {
                    props.onLeftIconClick();
                }
            }}>
                <CloseIcon iconColor={'white'} className='ptr hhdgd-jghg' />
            </div>
            <div className={`gdgd-instrs dfds-dd hdhgd-fhfhg rounded-right`}>
                <h2 className='hhfk-mdkd'>{props.title}</h2>
            </div>
        </div>
        </>
    )
}

export default CRCPopupHandleTitle;