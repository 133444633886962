import React, { useEffect, useState } from 'react';
import AccountHeader from './tools/AccountHeader';
import Texts from '../lib/Texts';
import Assets from '../lib/Assets';
import { Edit } from '@mui/icons-material';
import Separator from './tools/Separator';
import StateUpdators from '../lib/StateUpdators';
import Utils from '../lib/Utils';
import { useParams } from 'react-router-dom';
import ActionModal2 from './tools/ActionModal2';
import Error from './tools/Error';
import Loader from './tools/Loader';
import RightSidePopin from './tools/RightSidePopin';
import ReportError from './tools/ReportError';

/**
 * My account
 * @returns 
 */
const MyAccount = () => {
    console.log('{MyAccount}');
    const [profile, setUserProfileInfo] = useState({
        firstname: '',
        lastname: '',
        othernames: '',
        username: '',
        gender: '',
        dob: '0000-00-00',
        email: '',
        postalCode: '',
        phone: '',
        iconTitle: 'Avater not available',
        icon: Assets.icons.avatarPlaceHolder,
        userType: '',
        institution: '',
        instituteId: 0,
        institutionType: '',
        country: ''
    });
    const completeAccount = useParams().completeAccount;
    StateUpdators.setUserProfileInfo = setUserProfileInfo;
    useEffect(() => {
        Utils.addAnalytics({
            page: 'MyAccount',
            url: window.location.href,
            title: `NS.MyAccount`
        });
        Utils.setPageTitle('My Account');
        Utils.fetchUserInfo({completeAccount: completeAccount});
    },[completeAccount]);
    return (
        <>
        <AccountHeader />
        <div className='hddg-info-hdhdg w-80 centered box-b rounded shadow mnhd0jdh'>
            <h3>Your personal info</h3>
            <p className='shady-blk'>{Texts.aboutPI}</p>
            <div className='hdgsg-jfhf-s w-90 centered box-b'>
                <div className='box-b ndhgd'>
                    <h3>Your profile info at Computers 4 Kids</h3>
                    <p className='hfgg-hfhg shady-blk'>{Texts.aboutPI2}</p>
                </div>
                <div>
                    <img alt='About Profile' className='fhf-jghg' src={Assets.misc.aboutPI1} />
                </div>
            </div>
            <div className='basic-infolhdgd box-b bdr-bl rounded w-90 centred'>
                <div className='hdgdg-hhdr'>
                    <div>
                        <h3 className='hs-hfbd'>Basic Info</h3>
                    </div>
                    <div className='edit-ndhd-btns box-b'>
                        <Edit className='ptr' titleAccess='Update your basic info' />
                    </div>
                </div>
                <div className='info-jhdgd jhdh'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Profile Picture</p>
                    </div>
                    <div className='valie-hfgfg bbhd-lfjf'>
                        <div className='jfhd-kghjf'>
                            <p className='hfgg-hfhg shady-blk'>{Texts.whyPP}</p>
                        </div>
                        <div className='bagsfv-ahdgt-jhshs rounded-circle ptr' title={profile.iconTitle}>
                            <img title={profile.iconTitle} alt={profile.iconTitle} className='nfhdh-nv-icon' src={profile.icon} />
                        </div>
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Firstname</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.firstname}</p>
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Lastname</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.lastname}</p>
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Othernames</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.othernames}</p>
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Gender</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.gender}</p>
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Date of Birth</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.dob}</p>
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>User Type</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.userType||profile.user}</p>
                    </div>
                </div>
                {
                    profile.userType === 'Student' &&
                    <>
                    <div className='info-jhdgd'>
                        <div className='flejd-name'>
                            <p className='hfgg-hfhg shady-blk'>Student ID</p>
                        </div>
                        <div className='valie-hfgfg'>
                            <p className='hfgg-hfhg shady-blk'>{`S${profile.userId}`}</p>
                        </div>
                    </div>
                    </>
                }
            </div>
            <Separator styles={{
                height: '50px'
            }} />
            <div className='basic-infolhdgd box-b bdr-bl rounded w-90 centred'>
                <div className='hdgdg-hhdr'>
                    <div>
                        <h3 className='hs-hfbd'>Contact Info</h3>
                    </div>
                    <div className='edit-ndhd-btns box-b'>
                        <Edit className='ptr' titleAccess='Update your contact info' />
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Email</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.email}</p>
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Phone</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.phone}</p>
                    </div>
                </div>
            </div>
            <Separator styles={{
                height: '50px'
            }} />
            <div className='basic-infolhdgd box-b bdr-bl rounded w-90 centred'>
                <div className='hdgdg-hhdr'>
                    <div>
                        <h3 className='hs-hfbd'>Addresses</h3>
                    </div>
                    <div className='edit-ndhd-btns box-b'>
                        <Edit className='ptr' titleAccess='Update your address info' />
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Physical Address</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.physAddress}</p>
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Postal Address</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.postalAddress}</p>
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Postal Code</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.postalCode}</p>
                    </div>
                </div>
            </div>
            <Separator styles={{
                height: '50px'
            }} />
            <div className='basic-infolhdgd box-b bdr-bl rounded w-90 centred'>
                <div className='hdgdg-hhdr'>
                    <div>
                        <h3 className='hs-hfbd'>Login Info</h3>
                    </div>
                    <div className='edit-ndhd-btns box-b'>
                        <Edit className='ptr' titleAccess='Update your login info' />
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Username</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.username}</p>
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Password</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{'********'}</p>
                    </div>
                </div>
            </div>
            <Separator styles={{
                height: '50px'
            }} />
            <div className='basic-infolhdgd box-b bdr-bl rounded w-90 centred'>
                <div className='hdgdg-hhdr'>
                    <div>
                        <h3 className='hs-hfbd'>Institution</h3>
                    </div>
                    <div className='edit-ndhd-btns box-b'>
                        {/*cannot edit this info*/}
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Name</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.institution}</p>
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Type</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.institutionType}</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p style={{display: 'none'}} className='hfgg-hfhg shady-blk'>{profile.instituteId}</p>
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Country</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <p className='hfgg-hfhg shady-blk'>{profile.country}</p>
                    </div>
                    <div className='valie-hfgfg'>
                        
                    </div>
                </div>
            </div>
            <Separator styles={{
                height: '50px'
            }} />
        </div>
        <ActionModal2 />
        <Error />
        <ReportError />
        <Loader />
        <RightSidePopin />
        </>
    )
}

export default MyAccount;