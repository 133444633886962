import React, { useState } from 'react';
import C4KUserCategories from './C4KUserCategories';
import { Button } from '@mui/material';
import StateUpdators from '../../lib/StateUpdators';
import Forms from './Forms';
import Utils from '../../lib/Utils';

/**
 * The signup view
 * @returns 
 */
const TheSignupView = () => {
    console.log('{TheSignupView}');
    const [header, setHeader] = useState('You would like to join us as:');
    const [header1, setHeader1] = useState('Your awesome journey starts here... let\'s quickly get you on board!');
    StateUpdators.setHeader1 = setHeader1;
    StateUpdators.setHeader = setHeader;
    const [Content,setContent] = useState({
        C: () => 
        <>
        <C4KUserCategories />
        <div className='cont-btn-jghg'>
            <Button className='force-round-btn' variant='contained' color='primary' onClick={() => {
                const FormView = Forms.UserRegistrations[Utils.chosenOption]??Forms.InvalidOption;
                StateUpdators.setContent({
                    C: () => <FormView />
                })
            }}>
                Continue
            </Button>
        </div>
        </>
    });
    StateUpdators.setContent = setContent;
    return (
        <div className='sign-up-cont'>
            <h1 className='msjs-ldjks text-white'>Create Your Account</h1>
            <div className='sign-up-content box-b'>
                <div className='hdhdgf-left-jfhf'>
                </div>
                <div className='right-mvhfj-kdhd'>
                    <h2 className='mjs-pepe' dangerouslySetInnerHTML={{__html: header1}}></h2>
                    <p>{header}</p>
                    <Content.C />
                </div>
            </div>
        </div>
    )
}

export default TheSignupView;