import React from 'react';

/**
 * An element Separator
 * @param {object} props The info object with the `styles` object - css styles.
 * @returns 
 */
const Separator = (props) => {
    console.log('{Separator}');
    return (
        <>
        <div className='jshsh-sepaeratrosn' style={{...props.styles||props.style}}></div>
        </>
    )
}

export default Separator;