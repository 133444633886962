import React, { useEffect } from 'react';
import Texts from '../../lib/Texts';
import Assets from '../../lib/Assets';
import BaseName from '../../lib/BaseName';
import AccountHeader from './AccountHeader';
import Utils from '../../lib/Utils';
import LinkToOldCurriculums from './LinkToOldCurriculums';
import Separator from './Separator';

const NoCurriculums = () => {
    console.log('{NoCurriculums}');
    useEffect(() => {
        Utils.fixMissingCurriculums();
    },[]);
    return (
        <>
        <AccountHeader />
        <div className='bbdg-curriculums-fhgf animate__animated animate__fadeIn'>
            <div className='w-80 h-centred bkg-w rounded nchfh-kfhf shadow box-b'>
                <h2>You have no curriculum subscriptions</h2>
                <img alt='No subscriptions' title='No subscriptions' className='ptr' src={Assets.icons.emptyBox} />
                <p>You have no curriculum subscriptions at the moment.</p>
                <p>Please <a href={`mailto:${Texts.supportC4kEmail}`}>contact support</a> for more information on subscriptions.</p>
                <div title='Try this page' className='bvv-fhf-x hfhf-btn no-emph-element h-centred ptr rounded-circle bdr-w'>
                    <a href={`/${BaseName.name}`}>Home</a>
                </div>
                <div title='Try this page' className='bvv-fhf-x hfhf-btn no-emph-element h-centred ptr rounded-circle bdr-w'>
                    <a href={`/${BaseName.name}/logout`}>Logout</a>
                </div>
                <Separator style={{height: '10px'}} />
                <LinkToOldCurriculums />
            </div>
        </div>
        </>
    )
}

export default NoCurriculums;