import React, { useEffect,useState } from 'react';
import Utils from '../lib/Utils';
import AbsoluteCOLLogo from './tools/AbsoluteCOLLogo';
import HomePageNavMenu from './tools/HomePageNavMenu';
import MobileNavMenu from './tools/MobileNavMenu';
import StateUpdators from '../lib/StateUpdators';
import { useParams } from 'react-router-dom';
import Loader from './tools/Loader';
import Error from './tools/Error';
import ReportError from './tools/ReportError';
import RightSidePopin from './tools/RightSidePopin';

const WebContent = () => {
    console.log('{WebContent}');
    const page = useParams().page;
    const [content, setContent] = useState({
        fetched: false,
        fetching: true,
        Content: () => <></>
    })
    StateUpdators.setWebContent = setContent;
    useEffect(() => {
        Utils.addAnalytics({
            page: 'WebContent.',
            url: window.location.href,
            title: 'NS.WebContent.PageVisit'
        });
        Utils.setPageTitle('Classesonline -> Content')
        Utils.fetchWebContent({page: page})
        return undefined;
    })
    return (
        <>
        <div className='c4k-home animate__animated animate__fadeIn'>
            <div className='mshsh-lkdks'>
                <div className='ksjsjs'>
                    <AbsoluteCOLLogo styles={{
                        left: '20px',
                        top: '20px'
                    }} />
                </div>
                <div className='nshsghd-ldkdj-nav'>
                    <HomePageNavMenu page={'home'} />
                </div>
            </div>
            <MobileNavMenu page={'home'} />
            {
                content.fetching ? 
                <>
                <div>
                </div>
                </>
                : <content.Content />
            }
        </div>
        <Loader />
        <Error />
        <ReportError />
        <RightSidePopin />
        </>
    )
}


export default WebContent;