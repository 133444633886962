import React from 'react';
import Texts from '../../lib/Texts';
import { Button } from '@mui/material';
import Utils from '../../lib/Utils';
import Assets from '../../lib/Assets';

/**
 * Easy access to the old DSC educator dashboard
 * @returns 
 */
const OldEducatorDasboardLink = ({functionality = false}) => {
    console.log('{OldEducatorDasboardLink}')
    return (
        <>
        <div className='hhgs-kdjd-old-link w-70 centred rounded shadow bdr-bl animate__animated animate__fadeIn'>
            <div className='warn-info-40 centred'>
                <img alt='Warn' className='warn-info-40' src={Assets.icons.warn} />
            </div>
            <p className='light-font-300'>{Texts.oldLinkInfo}</p>
            <Button color='secondary' title='Go to old Educator Dashboard' className='ptr' onClick={() => {
                Utils.addAnalytics({
                    page: 'EducatorDashboard',
                    url: window.location.href,
                    title: `NS.EducatorDashboard.AssetAccesss.UnAvailableFunctionality`,
                    functionality: functionality
                });
                Utils.goTo({url: 'https://classesonline.mobi/members/1.8/session_initializer.php', inNewWindow: true});
            }}>
                Go to the old Educator Dashboard
            </Button>
        </div>
        </>
    )
}

export default OldEducatorDasboardLink;