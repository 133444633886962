import React from 'react';
import Assets from '../../lib/Assets';

/**
 * SmallLoader. The props can have `customClasses`  - a space-separated list to hook into the loader image.
 * Can also have `title` The title of the loader
 * @returns React View
 */
const SmallLoader = (props) => {
    console.log('{SmallLoader}')
    return(
        <img src={Assets.smallLoader} id='sm-loader-sjsj rounded' className={`pointer ${props.customClasses??''} ${props.className??''}`} title={props.title ? props.title : 'Loading...'} alt={props.title ? props.title : 'Loading...'} />
    )
}

export default SmallLoader;