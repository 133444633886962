import React from 'react';
import Texts from '../../lib/Texts';

/**
 * Curriculums not available
 * @returns 
 */
const CurriculumsNotAvailable = () => {
    return (
        <>
        <div className='animate__animated anaimate__fadeIn kdhshs-kdsjhsh w-80 centred content-centre'>
            <p>{Texts.curriNotAvailable}</p>
        </div>
        </>
    )
}

export default CurriculumsNotAvailable;