import React from 'react';
import Texts from '../../lib/Texts';

/**
 * PACE alignment popup
 * @param {object} The info object 
 * @returns 
 */
const PaceAlignment = ({info}) => {
    console.log('{PaceAlignment}');
    //const title = 'PACE Alignment';
    return (
        <>
        <div className='ndhdgdhd'>
            <div className='jdhdh-jdhd centred w-80 text-centre'>
                {/**<img className='ptr ndhsh-pace' alt={title} title={title} src={Assets.icons.pace} />**/}
                <h3>PACE Alignment</h3>
            </div>
            <div className='fhdgd-fhf text-ctr'>
                <h4>{Texts.paceAlignedTo} {info.code}</h4>
                <h4 className='mlsg-lwos'>{`Subjects: ${info.subjects.join(',')}`}</h4>
            </div>
            <div className='bgd-djfhf bdr-bl w-90 centred rounded shadow txt-ctr'>
                <h4 className='jfhjghf'><b className='ngd-lpw-sjd'>Character Trait: {info.charTraits.trait}</b></h4>
                <div className='padd-around-10 box-b'>
                    <p className='ndhs-kfkdj'>{info.charTraits.aboutTrait}</p>
                </div>
            </div>
            <div className='jsjs-d bgd-djfhf bdr-bl w-90 bdhdy-jdhd centred rounded shadow txt-ctr'>
                <h4 className='jfhjghf'><b className='ngd-lpw-sjd'>{'Bible Verse'}</b></h4>
                <div className='ncbdg-lgjd-djs padd-around-10 box-b'>
                    <p className='ndhs-kfkdj'>{info.charTraits.bibleVerse}</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default PaceAlignment;