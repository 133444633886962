import React, { useEffect } from 'react';
import Utils from '../../lib/Utils';
import BaseName from '../../lib/BaseName';
import AccountHeader from './AccountHeader';
import Assets from '../../lib/Assets';
import { Button } from '@mui/material';

/**
 * Render curriculums. Renders 
 * @param {object} plans The plans containing subscriptions 
 * @returns 
 */
const RenderCurriculumSubscriptions = ({plans}) => {
    console.log('{RenderCurriculumSubscriptions}');
    //console.log('{RenderCurriculumSubscriptions}: plans=',plans);
    useEffect(() => {
        Utils.setPageTitleFull({
            title: `Computers 4 Kids | Classesonline > Explore Curriculum Subscription Plans`
        })
    });
    return (
        <>
        <AccountHeader showPopupIfAccountIncomplete={true} />
        <div className='bbdg-curriculums-fhgf animate__animated animate__fadeIn'>
            <div className='w-80 h-centred bkg-w rounded nchfh-kfhf lls shadow box-b'>
                <h2 className='kdkd-espd'>Explore Curriculum Subscription Plans</h2>
                <p>Each plan comprises all curriculum versions.</p>
                <div className='nfhdh-suvbs-jshs box-b jsjhs-ksksl'>
                    {
                        plans.map((_plan,key) => {
                            //console.log('_plan=',_plan)
                            return (
                                <div className='mjs-2pwiw rounded shadow w-80 centred' key={key}>
                                    <div className='jdhs-odoe-pe content-centre'>
                                        <img src={Assets.curriculumBundle} className='djs-iei rounded-left' alt='Curriculum Bundle' />
                                    </div>
                                    <div className='kdjs-wpw rounded-right'>
                                        <div className='content-centre'>
                                            <h2 className='mshs-pwow kdjd-oeie'>{_plan.length} {_plan.descr} All Curriculums Access</h2>
                                        </div>
                                        <div className='content-centre'>
                                            <h2 className='shs-lww'>R{_plan.price}</h2>
                                        </div>
                                        <div  className='content-centre'>
                                            <Button onClick={() => {
                                                const token = Utils.base64Encode(JSON.stringify({
                                                    planId: _plan.planId,
                                                    service: 'CurriculumSubscriptions',
                                                    type: 'Bundle'
                                                }));
                                                Utils.goTo({url: `/${BaseName.name}/pay/${token}`});
                                            }} variant='contained' color='primary' className='force-round-btn ptr' title='Pay for this plan'>
                                                Pay for this plan
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        </>
    )
}

export default RenderCurriculumSubscriptions;