import React, { useEffect, useState,useRef } from 'react';
import Utils from '../../lib/Utils';
import PluggableLoader from './PluggableLoader';
import StateUpdators from '../../lib/StateUpdators';
import AccountHeader from './AccountHeader';

/**
 * Digital Badge Album
 * @param {*} param0 
 * @returns 
 */
const DigitalBadgePage = () => {
    console.log('{DigitalBadgePage}');
    const [C, setDBAlbumContent] = useState({
        data: {},
        C: () => <PluggableLoader msg={'Fetching album'} title={'Fetching album'} show={true} />
    });
    const refched = useRef(null);
    StateUpdators.setDBAlbumContent = setDBAlbumContent;
    useEffect(() => {
        Utils.addAnalytics({
            page: 'DigitalBadges',
            url: window.location.href,
            title: `NS.DigitalBadges`
        });
        if(!refched.current) {
            Utils.fetchDigitalBadgesAlbum();
            refched.current = true;
        }
    });
    return (
        <>
        <AccountHeader />
        <C.C />
        </>
    )
}

export default DigitalBadgePage;