import React from 'react';
import Texts from '../../lib/Texts';
import Assets from '../../lib/Assets';

/**
 * Display the EQ
 * @returns 
 */
const EQInfoGraphicView = () => {
    console.log('{EQInfoGraphicView}');
    return (
        <>
        <div className='jshsh jdjdhs text-centre'>
            <div className='w-80 centred'>
                <h3>{Texts.eqInfoGraphicHdr}</h3>
            </div>
            <div className='ksks-kdjd content-centre'>
                <img className='jdjs-ldjd' alt='EQ Infographic' src={Assets.eqInfographic.white} />
            </div>
        </div>
        </>
    )
}

export default EQInfoGraphicView;