import React from 'react';

/**
 * Current Viewing User Guide
 * @param {object} info The info object 
 * @returns 
 */
const CurrentViewingUserGuide = ({info}) => {
    console.log('{CurrentViewingUserGuide}');
    return (
        <div className='animate__animated animate__fadeIn gfgfg-ngshf-uwsh'>
        {
            info.subTopics.map((item,__key) => {
                return (
                    <React.Fragment key={__key}>
                        <h1 className='tp-lv-hdr-mfj'>{item.title}</h1>
                        {
                            item.content.map((_item,k) => {
                                return (
                                    <React.Fragment key={k}>
                                        <div dangerouslySetInnerHTML={{__html: _item}}></div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </React.Fragment>
                )
            })
        }
        </div>
    )
}

export default CurrentViewingUserGuide;