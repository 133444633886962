import React, { useEffect, useState } from 'react';
import Utils from '../../lib/Utils';
import StateUpdators from '../../lib/StateUpdators';

/**
 * Special account settings
 * @returns 
 */
const SpecialAccountSettings = () => {
    console.log('{SpecialAccountSettings}');
    const [Content, setSpecialAccountSettingsContent] = useState({
        C: () => <></>
    })
    StateUpdators.setSpecialAccountSettingsContent = setSpecialAccountSettingsContent;
    useEffect(() => {
        Utils.checkIfNewLMSSpecialAccount({resultsCallback: setSpecialAccountSettingsContent});
    },[])
    return (
        <>
        <Content.C />
        </>
    )
}

export default SpecialAccountSettings;