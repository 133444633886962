import React, { useEffect } from 'react';
import Error from './tools/Error';
import Loader from './tools/Loader';
import { useParams } from 'react-router-dom';
import Utils from '../lib/Utils';
import RightSidePopin from './tools/RightSidePopin';
import ReportError from './tools/ReportError';
import StateUpdators from '../lib/StateUpdators';

/**
 * We epect a token to be supplied. We login the user using this token
 * @returns 
 */
const SetupUserAccess = () => {
    console.log('{SetupUserAccess}');
    let token = useParams().token ?? false;
    if(token) {
        token = Utils.base64Decode(token);
        token = JSON.parse(token);
    }
    useEffect(() => {
        Utils.addAnalytics({
            page: 'AccessSetup',
            token: token,
            url: window.location.href,
            title: 'NS.AccessSetupPageVisit'
        });
        Utils.showProgress({msg: 'Setting you up for success...'});
        if(token) {
            Utils.loginUser({...token});
        } else {
            StateUpdators.setError({
                show: true,
                msg: 'Invalid Request. Please try again, all the way again.',
                extraData: {
                    error: 'noValidToken',
                    where: window.location.href
                }
            });
        }
    });
    return (
        <>
        <div className='setup-u-access animate__animated animate__fadeIn'></div>
        <Error />
        <Loader />
        <RightSidePopin />
        <ReportError />
        </>
    )
}

export default SetupUserAccess;