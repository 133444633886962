import React, {useEffect, useRef, useState} from 'react';
import StateUpdators from '../../lib/StateUpdators';
import Assets from '../../lib/Assets';

/**
 * Manges display of some information in a non-disturbing way
 * @returns React View/Comp
 */
const RightSidePopin = () => {
    console.log('{RightSidePopin}');
    const [show, setRightSidePopup] = useState({
        show: false,
        msg: '',
        textColor: '',
        showMode: 'animate__fadeInRight'
    });
    const FOUR_SECS = 4000;
    const ONE_SEC = 1000;
    StateUpdators.setRightSidePopup = setRightSidePopup;
    const timer1 = useRef({});
    const timer2 = useRef({});
    useEffect(() => {
        const _timer1 = timer1.current.timerId;
        const _timer2 = timer1.current.timerId;
        if(show.show) {
            timer1.current.timerId = setTimeout(() => {
                setRightSidePopup({
                    ...show,
                    showMode: 'animate__fadeOutRight'
                });
                timer2.current.timerId = setTimeout(() => {
                    setRightSidePopup({
                        ...show,
                        showMode: 'animate__fadeOutRight',
                        show: false
                    });
                }, ONE_SEC);
            }, FOUR_SECS);
        } else {
            clearTimeout(timer1.current.timerId);
            clearTimeout(timer2.current.timerId);
        }
        return () => {
            clearTimeout(_timer1);
            clearTimeout(_timer2);
        }
    },[show]);
    return (
        <>
        {show.show && 
            <div id='side-popin' className={`animate__animated shadow ${show.showMode}`}>
                <div id='text-dvlsk'>
                    <p style={{
                        color: show.textColor
                    }}>{show.msg}</p>
                </div>
                <div id='icon-dicvhdh'>
                    <img src={Assets.icons.closeView} onClick={() => {
                        setRightSidePopup({
                            ...show,
                            showMode: 'animate__fadeOutRight'
                        });
                    }} title='Dismiss' id='close-popin' alt='Close Window'/>
                </div>
            </div>}
        </>
    )
}

export default RightSidePopin;