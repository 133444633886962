import React, { Suspense, useEffect } from 'react';
import LoadingComponent from './LoadingComponent';
import Utils from '../../lib/Utils';
import AccountHeader from './AccountHeader';
import ActionModal from './ActionModal';

/**
 * Render a given curriculum
 * @param {object} props TYhe props object
 * @returns 
 */
const RenderCurriculum = (props) => {
    console.log('{RenderCurriculum}');
    const CurriculumView = React.lazy(() => import(`./curriculums/${props.content.curriculum.extra.UI.Component}`));
    useEffect(() => {
        Utils.setPageTitleFull({
            title: `Computers 4 Kids | Classesonline > ${props.content.curriculum.title}`
        })
    });
    return (
        <>
        <AccountHeader />
        <Suspense fallback={<LoadingComponent />}>
            <CurriculumView content={props.content} />
        </Suspense>
        <ActionModal />
        </>
    )
}

export default RenderCurriculum;