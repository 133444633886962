import React, { useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import '../../css/ProgressBarStyles.css';
import { CircularProgress, Dialog } from '@mui/material';

/**
 * Show a progress loader. It can be customized with 'mainClass' optional class name, which gives access 
 * some functions to get hold of the loader and show/hide it as needed.
 * The 'doingWhatClass' gives access to the 'doing what' text to a specific functions which uses the loader
 * @param {object} props 
 * @returns React Component
 */
const  Loader = () => {
    console.log('{Loader}');
    const [configs, setLoaderConfigs] = useState({
        show: false,
        msg: 'Processing...'
    });
    StateUpdators.setLoaderConfigs = setLoaderConfigs;
    return(
        <>
        {configs.show && 
        <Dialog open={configs.show}>
            <div id='progressBar' className='animate__animated animate__fadeIn rounded-small shadow'>
                <div id='smartLoader' title={configs.msg} className='pointer'>
                    <CircularProgress size={'large'} />
                </div>
                <div title={configs.msg} className='ptr' id='doingWhat'>
                    <p><b>{configs.msg}</b></p>
                </div>
            </div>
        </Dialog>
        }
        </>
    )
}

export default Loader;