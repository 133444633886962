import React, { useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import CloseIcon from './CloseIcon';
import { Dialog } from '@mui/material';

/**
 * Action Modal2
 * @returns 
 */
const ActionModal2 = () => {
    console.log('{ActionModal2}');
    const [Modal, setActionModal2] = useState({
        show: false,
        Content: () => (<></>)
    });
    StateUpdators.setActionModal2 = setActionModal2;
    return (
        <>
        <Dialog
            open={Modal.show}
        >
        {
            Modal.show &&
            <div className='animate__animated rounded animate__fadeIn rounded bkg-white w-50 modal-2 shadow'>
                <CloseIcon onClose={() => {
                    setActionModal2({show: false});
                }} />
                <Modal.Content />
            </div>
        }
        </Dialog>
        </>
    )
}

export default ActionModal2;