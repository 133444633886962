import React, { useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';

/**
 * Student progress for different curriculums
 * Learn more here: http://admin.computers4kids.co.za/docs/general/StudentPgress
 * @returns 
 */
const StudentProgress = () => {
    const [progress, setStudentProgress] = useState({
        show: false,
        Content: () => (<div><p>Student Progress</p></div>)
    });
    StateUpdators.setStudentProgress = setStudentProgress;
    return (
        <>
        {
            progress.show &&
            <div className='std-progressg animate__animated animate__backInRight'>
                <progress.Content />
            </div>
        }
        </>
    )
}

export default StudentProgress;