import React from 'react';
import C4KApp from './C4KApp';

/**
 * The app entry
 * @returns 
 */
const App = () => {
  console.log('{App}');
  return (
    <C4KApp />
  );
}

export default App;
