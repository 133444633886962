import React, { useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import Assets from '../../lib/Assets';
import { Button } from '@mui/material';
import Utils from '../../lib/Utils';
import ActivateDigitalBadgeForm from './ActivateDigitalBadgeForm';
import DigitalBadgeAlbum from './DigitalBadgesAlbum';

/**
 * Progress Badge
 * @returns 
 */
const ProgressBadge = () => {
    console.log('{ProgressBadge}');
    const [C, setProgressBadgeCongtent] = useState({
        C: () => <></>,
        showBadge: false
    });
    //console.log('C=',C);
    StateUpdators.setProgressBadgeCongtent = setProgressBadgeCongtent;
    return (
        <>
        {
            C.showBadge &&
            <div className='gdgdg-badge rounded shadow animate__animated animate__slideInUp'>
                <C.C />
            </div>
        }
        </>
    )
}

export default ProgressBadge;

//Exports

/**
 * When we have fetched a badge
 * @returns 
 */
const DisplayDigitalBadge = ({info}) => {
    console.log('{DisplayDigitalBadge}');
    const [_activePIN, setActivePINStatus] = useState(info.userBadgeId&&info.badgeId);
    StateUpdators.setActivePINStatus2 = setActivePINStatus;
    return (
        <>
        <div title='Digital Badge' className='bcgdg-badge-fjf animate__animated ptr'>
            <div className='badge-icon-jfhd'>
                <h4 className='hfgfg-sds'>Digital Badge</h4>
                <img alt='Digital Badge' title={`${_activePIN?'You earned it!':'Inactive Badge'}`} className={`ptr bcgdg-icons-jfhf ${_activePIN?'':'badge-inactive'}`} src={info.badgeURL} />
            </div>
            <div className='badge-btn-hdgs'>
                {
                    _activePIN ?
                    <>
                    <Button title='View Digital Badges' className='force-round-btn force-bkg-color-new-look' variant='contained' onClick={() => {
                        Utils.showFullscreenModal({
                            Content: () => <DigitalBadgeAlbum info={info} />
                        })
                    }}>
                        View Album
                    </Button>
                    </>:
                    <>
                    <Button title='Activate this Digital Badge' className='force-round-btn force-bkg-color-new-look' variant='contained' onClick={() => {
                        Utils.showModal({
                            Content: () => (<ActivateDigitalBadgeForm info={info} />)
                        });
                    }}>
                        Activate
                    </Button>
                    </>
                }
            </div>
        </div>
        </>
    )
}


/**
 * When we fail to fetch a badge
 * @returns 
 */
const ErrorFetchingDigitalBadge = () => {
    console.log('{ErrorFetchingDigitalBadge}');
    return (
        <>
        <div title='Error fetching digital badge' className='animate__animated animate_fadeIn ptr gfgdg-error-fjdh w-80 centred'>
            <img alt='Error' src={Assets.icons.error} />
            <p>Error fetching badge</p>
        </div>
        </>
    )
}

export {ErrorFetchingDigitalBadge, DisplayDigitalBadge};