import React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Separator from './Separator';
import Utils from '../../lib/Utils';
import Texts from '../../lib/Texts';
import Assets from '../../lib/Assets';

/**
 * Extra activities
 * @returns 
 */
const CurriculumExtraActivities = () => {
    console.log('{CurriculumExtraActivities.js}');
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        event = {};
        setValue(newValue);
    };
    return (
        <>
        <div className='nsgsgs'>
            <div className='nsjowsgsgs'>
                <h2>Activities</h2>
            </div>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label='Class Extra Activities'>
                    <Tab label='Keyboard & Mouse Skills' value='1' />
                    <Tab label='Typing Skills' value='2' />
                </TabList>
                </Box>
                <TabPanel value='1'>
                    <div className='jshs-jlooshs'>
                        <h3>Practice Keyboard & Mouse Skills</h3>
                        <div className='nssg-lsjshs'>
                            <div onClick={() => {
                                Utils.goTo({
                                    url: `${Utils.assetsURL}DSC/activities_assets/kbms/`,
                                    inNewWindow: true
                                });
                            }} className='lsows shadow rounded ptr' title='Go to Keyboard and Mous activity'>
                                <div>
                                    <Separator styles={{height: '10px'}} />
                                    <img className='rounded' alt='Keyboard and Mouse' src={'https://computers4kids.co.za/assets/curriculum/DSC/icons/activities/kms.png'} />
                                </div>
                                <div>
                                    <Button>
                                        Keyboard and Mouse
                                    </Button>
                                </div>
                            </div>
                            <div onClick={() => {
                                Utils.goTo({
                                    url: `${Utils.assetsURL}DSC/activities_assets/ladybug/`,
                                    inNewWindow: true
                                });
                            }} className='lsows shadow rounded ptr' title='Go to Ladybug Spots activity'>
                                <div>
                                    <Separator styles={{height: '10px'}} />
                                    <img className='rounded' alt='Ladybug Spotse' src={'https://computers4kids.co.za/assets/curriculum/DSC/icons/activities/ladybug.png'} />
                                </div>
                                <div>
                                    <Button>
                                        Ladybug Spots
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value='2'>
                    <div className='jshs-jlooshs'>
                        <h3>Practice Typing Skills</h3>
                        <p>{Texts.rapidTyping}</p>
                        <img className='nsgsfgs-lsjs' alt='RapidTyping Logo' src={Assets.rapidTypingLogo} />
                        <Separator styles={{height: '10px'}} />
                        <div>
                            <Button onClick={() => {
                                Utils.goTo({
                                    url: `https://rapidtyping.com/`,
                                    inNewWindow: true
                                });
                            }}>
                                Go to RapidTyping &raquo;
                            </Button>
                        </div>
                    </div>
                </TabPanel>
            </TabContext>
        </div>
        </>
    )
}

export default CurriculumExtraActivities;