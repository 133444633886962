import React, { useEffect, useState } from 'react';


/**
 * Color an some dive in a percentage animated manner
 * @param {number} value The number as the max % 
 * @returns 
 */
const PercetageColoration = ({value}) => {
    //console.log('{PercetageColoration}: value=',value);
    let severityColor;
    if(value <= 30) severityColor = 'red-nvhfghf';
    if(value > 30) severityColor = 'yellow-nvhfghf';
    if(value > 60) severityColor = 'green-nvhfghf';
    const [_value, setValue] = useState(0);
    useEffect(() => {
        const animId = setInterval(() => {
            if(_value < value) {
                setValue(_value => _value+1);
            } else {
                clearInterval(animId);
            }
        },30);
        return () => clearInterval(animId);
    });
    return (
        <>
        <div className={`ndhdh-animated ${_value>=99?'rounded-right':''} box-b ${severityColor}`} style={{
                width: `${_value}%`
            }}>
                <div className='content-centre'>
                    <p className={`jfhfh ${_value>=31?'color-white-ndhdgf':'ksjs-kdjhsh'}`}>{value}%</p>
                </div>
        </div>
        </>
    )
}

export default PercetageColoration;