import React, { useState } from 'react';
import Assets from '../../lib/Assets';
import BaseName from '../../lib/BaseName';
import Utils from '../../lib/Utils';
import { Menu, MenuOpen } from '@mui/icons-material';


/**
 * The mobile view
 * @returns React View/Component
 */
const MobileNavMenu = (props) => {
    console.log('{MobileNavMenu}');
    const [open, setOpen] = useState(false);
    const loggedIn = Utils.isLoggedIn();
    return (
        <>
        <div className='mshs-lksjs'>
            <div className='mspek-lsosk'>
                <img id='mdksp-s' src={Assets.colLogoWhite} alt='Classesonline Logo' title='Classesonline Logo | Learn online, anytime, anywhere' className='c4k-logo c4k-ab-logo ptr' onClick={() => {
                    Utils.goTo({inNewWindow: false, url: `/${BaseName.name}`});
                }} />
            </div>
            <div className='mjh0ps-lksjs'>
                {
                    !open ? 
                    <Menu titleAccess='Menu' className='mchd-ldksdj ptr' onClick={() => {
                        setOpen(true)
                    }} /> : 
                    <MenuOpen titleAccess='Menu' className='mchd-ldksdj ptr' onClick={() => {
                        setOpen(false)
                    }} />
                }
                {
                    open &&
                    <>
                    <div className='mksj-ldks rounded shadow animate__animated animate__fadeInRight'>
                        <div className='jshs-i0w'>
                            <a title='Go to home' href={`/${BaseName.name}`}>Home</a>
                        </div>
                        <div className='jshs-i0w'>
                            <a title='About Classesonline' target='_blank' rel='noreferrer' href={'https://computers4kids.co.za/about-ict/'}>About</a>
                        </div>
                        <div className='jshs-i0w'>
                            <a title='Go to Computers 4 Kids' target='_blank' rel='noreferrer' href={'https://computers4kids.co.za/'}>Computers 4 Kids</a>
                        </div>
                        <div className='jshs-i0w'>
                            <a title='Go to e-Learner' target='_blank' rel='noreferrer' href={'https://e-learner.mobi/'}>e-Learner</a>
                        </div>
                        <div className='jshs-i0w'>
                            <a title='Contact us' target='_blank' rel='noreferrer' href={'https://computers4kids.co.za/about-ict/contact/'}>Contact</a>
                        </div>
                        <div className='jshs-i0w'>
                        <a title='Register to get started' href={`/${BaseName.name}/signup`}>Sign up</a>
                        </div>
                        <div className={`jshs-i0w ghs-kej-on-${props.page} ptr`}>
                        {
                            loggedIn ? 
                            <a title='Access your account' href={Utils.myAccountLink}>Account</a> :
                            <button className='hsgsg-jsjs ptr' title='Login to your account' onClick={() => {
                                Utils.addAnalytics({
                                    page: 'Home',
                                    url: window.location.href,
                                    title: 'NS.LoginForm'
                                });
                                if(!(Utils.isLoggedIn())) {
                                    Utils.showLoginForm();
                                } else {
                                    window.location = Utils.myAccountLink;
                                }
                            }}>Login</button>
                        }
                        </div>
                    </div>
                    </>
                }
            </div>
        </div>
        </>
    )
}
export default MobileNavMenu;