import React, { useEffect } from 'react';
import AccountHeader from './tools/AccountHeader';
import Error from './tools/Error';
import ActionModal2 from './tools/ActionModal2';
import SideFeedback from './tools/SideFeedback';
import RightSidePopin from './tools/RightSidePopin';
import Utils from '../lib/Utils';
import Texts from '../lib/Texts';
import ReportError from './tools/ReportError';


/**
 * User guides
 * @returns 
 */
const HelpDocs = () => {
    console.log('{HelpDocs}');
    const lang = 'en-us'.replace('-','_');
    useEffect(() => {
        Utils.setPageTitle('Help & Support')
    })
    return (
        <>
        <AccountHeader />
        <div className='animate__animated animate__fadeIn lesson-mfhdh-new'>
            <div className='hjshs-jdh-hdr w-80 centred content-centre'>
                <h3 className='txt-wt'>{Texts.support[lang].hdr1}</h3>
            </div>
            <div className='bsgskd jhsg-jfh bkg-white rounded shadow w-80 centred'>
                <h4>{Texts.support[lang].hdr2}</h4>
                <p>{Texts.support[lang].howToHdr}</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/sVn2RX9_mng?si=wmhI-VKTP_q-kCCX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
        <Error />
        <ReportError />
        <ActionModal2 />
        <SideFeedback />
        <RightSidePopin />
        </>
    )
}

export default HelpDocs;