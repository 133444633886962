import React, { useState } from 'react';
import Assets from '../../lib/Assets';
import Utils from '../../lib/Utils';
import StateUpdators from '../../lib/StateUpdators';
import EQInfoGraphicView from './EQInfoGraphicView';
import Theme from '../../lib/Theme';
import eLearnerLogo from '../../lib/eLearnerLogo';
import CommunityCurriculum from '../../lib/CommunityCurriculum';

/**
 * The c4k logo that is displayed to the left-bottom. Some times it shows the eq logo when the current lesson is EQ Theme aligned.
 * @returns 
 */
const C4KLogoLeftBottom = () => {
    console.log('{C4KLogoLeftBottom}');
    const [isEqLesson, setIsEQLesson] = useState(false);
    StateUpdators.setIsEQLesson = setIsEQLesson;
    const curriculum = Utils.getCachedCurriculumInfo();
    const shouldShowElearnerLogo = eLearnerLogo.indexOf(curriculum.extra.shortName) > -1;
    return (
        <>
        {
            curriculum.extra.shortName !== CommunityCurriculum.shortName &&
            <div className='c4k-logo-left ptr' onClick={() => {
                if(!isEqLesson) {
                    Utils.goToHomePage();
                } else {
                    Utils.showModal2({
                        Content: () => <EQInfoGraphicView />
                    })
                }
            }}>
                {
                    !isEqLesson ?
                    <>
                    {
                        shouldShowElearnerLogo ?
                        <>
                        <img title='e-Learner' className={`${Theme.content.animated.fadeIn} hsh-keosh-e`} alt='e-Learner' src={Assets.eLearnerLogo} />
                        </>
                        :
                        <>
                        <img title='Computers 4 kids' className={Theme.content.animated.fadeIn} alt='Computers 4 Kids' src={Assets.c4kLogo} />
                        </>
                    }
                    </>
                    :
                    <>
                    <img alt='EQ Infographic' title='This lesson is EQ themes aligned (click to expand)' className={`${Theme.content.animated.fadeIn} kjs-kdhjs`} src={Assets.eqInfographic.white} />
                    </>
                }
            </div>
        }
        </>
    )
}

export default C4KLogoLeftBottom;