import React from 'react';
import Assets from '../../lib/Assets';
import { Button } from '@mui/material';
import Utils from '../../lib/Utils';
import { useParams } from 'react-router-dom';


/**
 * Curriculum Subscription Review Order
 * @returns 
 */
const CurriculumSubscriptionReviewOrder = ({data}) => {
    console.log('{CurriculumSubscriptionReviewOrder}');
    //console.log('{CurriculumSubscriptionReviewOrder}: data=',data);
    let token = useParams().token??false;
    if(token) {
        token = JSON.parse(Utils.base64Decode(token));
    }
    return (
        <>
        <div className='gsgs-djd  animate__animated animate__fadeIn'>
            <h2 className='mcjd-wpw'>Review Your Order</h2>
            <div className='mdjs-oepec w-80 centred rounded shadow'>
                <div className='mjs-oepe jsjs-ls box-b'>
                    <img alt='' className='mdjk-peo rounded' src={Assets.curriculumBundle} />
                </div>
                <div className='msjs-oeoem'>
                    <div className='msjs-oeoem-i w-80 box-b centred'>
                        <div className='jdhs-lkdkd box-b'>
                            <div>
                                <h3 className='mjjs-left'>Plan:</h3>
                            </div>
                            <div>
                                <h3 className='kdjd-oeie'>{data.planInfo.length} {data.planInfo.descr} All Curriculums Access</h3>
                            </div>
                        </div>
                        
                        <div className='jdhs-lkdkd box-b'>
                            <div>
                                <h3 className='mjjs-left'>Price:</h3>
                            </div>
                            <div>
                                <h3 className='shs-lww'>{data.planInfo.currencyLetter}{data.planInfo.price}</h3>
                            </div>
                        </div>

                        <div className='jdhs-lkdkd box-b'>
                            <div>
                                <h3 className='mjjs-left'>Total to Pay:</h3>
                            </div>
                            <div>
                                <h3 className='kdjd-totalndnd'>{data.planInfo.currencyLetter}{data.planInfo.totalToPayDisplay}</h3>
                            </div>
                        </div>

                        <div className='mdhd-bbtidn'>
                            <Button color='primary' variant='contained' onClick={() => {
                                Utils.beginPaymentTransaction({
                                    section: 'CurriculumSubscription',
                                    payMethod: 'card',
                                    testMode: true,
                                    type: token?token.type:'Bundle',
                                    ...data.planInfo
                                });
                            }}>
                                Pay {data.planInfo.currencyLetter}{data.planInfo.totalToPayDisplay}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default CurriculumSubscriptionReviewOrder;