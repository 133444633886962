import React, { useState } from 'react';
import Texts from '../../lib/Texts';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Utils from '../../lib/Utils';
import { Done } from '@mui/icons-material';

const DisplayDigitalBadgeAlbum = ({data}) => {
    console.log('{DisplayDigitalBadgeAlbum}');
    const curriculum = Utils.getCachedCurriculumInfo();
    const studentEarned = Object.values(data.forStudent).map(el => el.lessonId);
    const courses = {};
    const lessons = {};
    Object.values(data.album).forEach(entry => {
        if(entry.courseCategory) {
            courses[`${entry.course}.${entry.courseCategory}`] = {
                courseId: entry.courseId,
                name: `${entry.course} > ${entry.courseCategory}`,
                courseCategoryId: entry.courseCategoryId
            }
        } else {
            courses[entry.course] = {
                courseId: entry.courseId,
                name: entry.course,
                courseCategoryId: entry.courseCategoryId
            }
        }
        lessons[entry.lessonId] = {
            lessonId: entry.lessonId,
            title: entry.lessonTitle,
            courseId: entry.courseId,
            courseCategoryId: entry.courseCategoryId
        }
    });
    const firstCourse = Object.values(courses)[0]??{courseId: 7, name: 'Volume R', courseCategoryId: null};
    const [course, setCourseOnDBVAlbum] = useState({
        courseId: firstCourse.courseId,
        name: firstCourse.name,
        courseCategoryId: firstCourse.courseCategoryId
    });
    return (
        <>
        <div className='animate__animated animate__fadeIn w-90 centred rounded shadow bcdg-jfhfg'>
            <h3>Digital Badges</h3>
            <p>{Texts.yourDBs}</p>
            <p>Total Digital Badges Earned: {studentEarned.length} in <b><u>{curriculum.title}</u></b></p>
            <div className='the-d-badges'>
                <div className='the-left-jfhfgf'>
                    <div className='sshhd-d w-90 centred bdr-bl rounded box-b'>
                        <div className='hdhgdg-hfhgdg w-90 centred'>
                            <h3>Lessons</h3>
                            <div className='bgsg-jfhfh'>
                                {
                                    Object.values(lessons).filter(lesson => lesson.courseCategoryId ? (lesson.courseCategoryId === course.courseCategoryId):  (lesson.courseId === course.courseId)).map((les,i) => {
                                        const earned  = studentEarned.indexOf(les.lessonId) > -1;
                                        return (
                                            <div className='jhsd-fjfh' key={i}>
                                                <p dangerouslySetInnerHTML={{__html: `${(i+1)}. ${les.title}`}}></p>{earned&&<Done className='jfhh-greejfhdh ptr' titleAccess='You have completed this lesson' />}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='the-right-mhgd'>
                    <div className='hgse-f w-90 centred bdr-bl rounded box-b'>
                        <div className='hdhgdg-hfhgdg w-40 centred'>
                            <FormControl fullWidth>
                                <InputLabel>Volume: </InputLabel>
                                <Select
                                    labelId='course-select-jdhd'
                                    id='course-select-jhss'
                                    value={JSON.stringify(course)}
                                    label={course.name}
                                    onChange={(e) => {
                                        setCourseOnDBVAlbum(JSON.parse(e.target.value));
                                    }}
                                >
                                    {
                                        Object.values(courses).map((el,i) => {
                                            return (
                                                <MenuItem value={JSON.stringify(el)} key={i}>{el.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className='hdsg-kkfh-lkjdj'>
                            {
                                Object.values(data.album).filter(_entry => _entry.courseCategoryId ? (_entry.courseCategoryId === course.courseCategoryId):  (_entry.courseId === course.courseId)).map((entry,i) => {
                                    const earned  = studentEarned.indexOf(entry.lessonId) > -1 ? 'earned-badge': 'not-earned-badge';
                                    return (
                                        <div title={`Digital Badge for Lesson: '${entry.lessonTitle}'`} className={`ptr animate__animated animate__fadeIn hsg0fjd hhd-fjhsh-jfhfkd-${(i+1)}`} key={i}>
                                            <img alt={`Digital Badge ${entry.name??``}`} src={`${Utils.assetsURL}${curriculum.extra.shortName}/icons/digital_badges/${entry.badgeIcon}`} className={`hgsg-db-ggs-${(i+1)} ${earned}`} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default DisplayDigitalBadgeAlbum;