import React from 'react';


const ExpiredSubscription = () => {
    console.log('{ExpiredSubscription}');
    return (
        <>
        </>
    )
}

export default ExpiredSubscription;