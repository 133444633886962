import React, { Suspense, useEffect, useRef, useState } from 'react';
import StateUpdators from '../../../lib/StateUpdators';
import SmallLoader from '../SmallLoader';
import { useParams } from 'react-router-dom';
import Utils from '../../../lib/Utils';
import DisplayQuiz from './DisplayQuiz';
import LoadingComponent from '../LoadingComponent';

/**
 * QuixQuestions
 * @param {boolean} justShowPastResults Whether to just show the quiz results
 * @param {object} extraConfigs The extraConfigs for the quiz
 * @returns 
 */
const QuixQuestions = ({justShowPastResults,extraConfigs}) => {
    console.log('{QuixQuestions}');
    const [info, setActiveQuizInfo] = useState({
        questions: [],
        quizTitle: '',
        duration: 0,
        error: false
    });
    StateUpdators.setActiveQuizInfo = setActiveQuizInfo;
    const token = JSON.parse(Utils.base64Decode(useParams().token));
    const quizType = token.quizType;
    const ResultsView = React.lazy(() => import(`./Render${quizType}PastResults`));
    const ref = useRef(quizType);
    const _token = useRef(token);
    useEffect(() => {
        Utils.fetchQuizQuestions({quizType: ref.current, token: _token.current});
        Utils.setPageTitle('Pre Test > Questions');
    },[]);
    return (
        <>
        {
            info.questions.length > 0 ?
            <>
            <DisplayQuiz
                courseId={token.courseId}
                lessonId={token.lessonId}
                quizType={quizType} 
                questions={info.questions} 
                quizTitle={info.quizTitle} 
                duration={info.duration} 
                extraConfigs={extraConfigs} 
                />
            </>:
            <>
            {
                info.error ?
                <div className='fdgdf0hfgf-error error w-60 centred'>
                    {
                        typeof justShowPastResults === 'undefined' &&
                        <h2 className='error'>{info.error}</h2>
                    }
                    <Suspense fallback={<LoadingComponent />}>
                        <ResultsView results={info} />
                    </Suspense>
                </div> :
                <SmallLoader customClasses='loader-on-exams' />
            }
            </>
        }
        </>
    )
}

export default QuixQuestions;