import React, { useEffect } from 'react';
import Assets from '../../lib/Assets';
import Utils from '../../lib/Utils';


/**
 * 
 * @returns 
 */
const PathNotFound = () => {
    console.log('{PathNotFound}');
    useEffect(() => {
        Utils.addAnalytics({
            page: 'PathNotFound',
            url: window.location.href,
            title: 'NS.PathNotFound'
        });
    },[]);
    return (
        <div className='not-found aimate__animated animate__fadeIn'>
            <div className='the-content-not-found width-60'>
                <img id='confuded-robot-nvh' className='pointer' src={Assets.icons.confusedRobot} alt='Confused' title='I am really confused' />
                <h2 className='font-wt-900'>404</h2>
                <h3>Sorry! We could not find that page.</h3>
                <p>Try these:</p>
                <div title='Try this page' className='bvv-fhf hfhf-btn no-emph-element h-centred ptr rounded-circle bdr-w'>
                    <a href='./'>Home</a>
                </div>
                <div title='Try this page' className='bvv-fhf hfhf-btn no-emph-element h-centred ptr rounded-circle bdr-w'>
                    <a href='./login'>Login</a>
                </div>
                <div title='Try this page' className='bvv-fhf hfhf-btn no-emph-element h-centred ptr rounded-circle bdr-w'>
                    <a href='./signup'>Register</a>
                </div>
            </div>
        </div>
    )
}

export default PathNotFound;