import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import FileUpload from './FileUpload';
import Assets from '../../lib/Assets';
import { Button } from '@mui/material';
import Utils from '../../lib/Utils';
import Texts from '../../lib/Texts';


/**
 * Feedback tool for the Community curriculum
 * @param {object} info The info object
 * @returns 
 */
const FeedbackToolCOC = (info) => {
    console.log('{FeedbackToolCOC}');
    const [showExample, setShowExample] = useState(false);
    const [input, setFeedbackToolInput] = useState({
        emails: '',
        message: ''
    });
    const [error, setError] = useState(false);
    //console.log('input=',input);
    return (
        <>
        <div className='cngd-fjfg w-90 centered'>
            <div className='hdgsgd-mxod'>
                <h2 className='nfhf-jwrs'>Student Feedback</h2>
                <img alt='Feedback' className='dgdf-pedke' src={Assets.icons.feedback2} />
            </div>
            <div className='feedjksg-jdhdh'>
                <TextField fullWidth error={error} value={input.emails} onChange={(val) => {
                    setFeedbackToolInput({...input, emails: String(val.currentTarget.value).trim()});
                }} required id='outlined-basic' label='Send to' variant='outlined' />
                <p className='sgsg-mj'><em>separate multiple email adresses by a comma.</em>
                {
                    !showExample &&
                    <>
                    <em title='Click to see an example' className='see-exam ptr' onClick={() => {
                        setShowExample(true);
                    }}> See example</em>
                    </>
                }
                {
                    showExample &&
                    <>
                    <div className='s-ndgeg-ex shadow rounded'>
                        <em className='exjshdg-exjs animate__animated animate__fadeIn'><br />Example:<br />email1@example.com, email2@example.com</em>
                        <em className='see-less ptr' title='Dismiss' onClick={() => {
                            setShowExample(false);
                        }}><br />See less</em>
                    </div>
                    </>
                }
                </p>
                <br />
                <TextField fullWidth multiline id='outlined-basic' value={input.message} label='Include a message (optional)' onChange={(val) => {
                    setFeedbackToolInput({...input, message: val.currentTarget.value});
                }} variant='outlined' />
                <div className='jshgf-jfhh'>
                    <FileUpload title={'Upload your work *'} />
                </div>
            </div>
        </div>
        <div className='hdgsg-btn-jdhdg w-90 centred box-b'>
            <Button className='force-round-btn' variant='contained' onClick={() => {
                if(!(input.emails)) {
                    setError(true);
                    return;
                }
                setError(false);
                const emails = input.emails.split(',');
                if(emails.length > 1) {
                    let errors = false;
                    emails.forEach(email => {
                        if(!(Utils.isValidEmail(email))) {
                            setError(true);
                            Utils.showFeedbackError({msg: `${Texts.invalidEmail} ${email}`});
                            errors = true;
                        }
                    });
                    if(errors)return;
                } else {
                    if(!(Utils.isValidEmail(input.emails))) {
                        Utils.showFeedbackError({msg: Texts.emailMustBeValid});
                        setError(true);
                    }
                }
                setError(false);
                //console.log('File: ',Utils.fileToUpload);
                if(!(Utils.fileToUpload.src)) {
                    Utils.showFeedbackError({msg: Texts.atLeastOneFile});
                    return;
                }
                Utils.sendFeedback({...input, message: input.message.length > 2 ? input.message : false, ...info});
            }}>
                Send
            </Button>
        </div>
        </>
    )
}

export default FeedbackToolCOC;