import React, { useEffect, useRef, useState } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input'
import PluggableLoader from './PluggableLoader';
import StateUpdators from '../../lib/StateUpdators';
import Utils from '../../lib/Utils';
import Assets from '../../lib/Assets';
import Texts from '../../lib/Texts';


/**
 * Activate digital badge form
 * @returns 
 */
const ActivateDigitalBadgeForm = ({info}) => {
    console.log('{ActivateDigitalBadgeForm}');
    const TWO_SECS = 2000;
    const [_activePIN, setActivePINStatus] = useState(info.userBadgeId&&info.badgeId);
    const [pin, setPIN] = useState('');
    const [_validating, setValidating] = useState({
        doing: true,
        done: false
    });
    const handleOnChange = (newValue) => {
        setPIN(newValue);
    }
    const validating = useRef(null);
    let timer = useRef(null);
    const handleOnComplete = (value) => {
        if(!validating.current) {
            setValidating({
                doing: false,
                done: false,
            });
            validating.current = true;
            timer = setTimeout(() => {
                Utils.validateDigitalBadge({...info, pin: value});
            }, TWO_SECS);
        } else {
            if(_validating.retry??false) {
                setValidating({
                    doing: false,
                    done: false,
                });
                validating.current = true;
                timer = setTimeout(() => {
                    Utils.validateDigitalBadge({...info, pin: value});
                }, TWO_SECS);
            }
        }
    }
    const matchIsNumeric = (text) => {
        const isNumber = typeof text === 'number';
        return (isNumber || (text !== '')) && !isNaN(Number(text));
    }
    // eslint-disable-next-line
    const validateChar = (value,index) => {
        return matchIsNumeric(value);
    }
    const [V,setValidationResults] = useState({
        C: () => (<></>)
    });
    const _msg = 'Activating your digital badge';
    StateUpdators.setValidationResults = setValidationResults;
    StateUpdators.setValidating = setValidating;
    StateUpdators.setPIN = setPIN;
    StateUpdators.setActivePINStatus = setActivePINStatus;
    useEffect(() => {
        const __timer = timer.current;
        return () => clearTimeout(__timer);
    });
    return (
        <>
        <div className='hdgd-formp-nwhdm w-90 centred'>
            <h3>Activate this Digital Badge</h3>
            <img alt='Didigital Badge' className={`ptr bcgdg-icons-jfhf ${_activePIN?'':'badge-inactive'}`} src={info.badgeURL} />
            <div className='hshsgc centred'>
                {
                    _validating.doing ? 
                    <>
                    <p>Enter a 4-digit PIN you received from your teacher:</p>
                    <MuiOtpInput
                        autoFocus
                        TextFieldsProps={{ placeholder: '-' }}
                        value={pin} 
                        onChange={handleOnChange} 
                        onComplete={handleOnComplete}
                        validateChar={validateChar}
                    />
                    </>:
                    _validating.done ?
                    <>
                    <V.C />
                    </> :
                    <div className='gdgdf'>
                        <PluggableLoader title={_msg} show={true} parentWidth={300} parentHeight={80} width={80} />
                    </div>
                }
            </div>
        </div>
        </>
    )
}

export default ActivateDigitalBadgeForm;

//Exports

/**
 * When there was an error activating a PIN
 * @param {object} param0 The info object
 * @returns 
 */
const ErrorActivatingDGCDigitalBadge = ({info}) => {
    console.log('{ErrorActivatingDGCDigitalBadge}: info=',info);
    return (
        <>
        <div className='error-activate-d-pin'>
            <img alt='Error' src={Assets.icons.error} />
            <p className='error'>{info.message||info.errorMessage}</p>
        </div>
        </>
    )
}

/**
 * When we have succeeded activating a digital badge
 * @returns 
 */
const SuccessActivatingDGCDigitalBadge = () => {
    console.log('{SuccessActivatingDGCDigitalBadge}');
    return (
        <>
        <div className='success-activate-d-pin'>
            <img alt='Success' src={Assets.icons.statusOk} />
            <p className='success'>{Texts.successActivatePIN}</p>
        </div>
        </>
    )
}

export {ErrorActivatingDGCDigitalBadge,SuccessActivatingDGCDigitalBadge};