import React, { useEffect, useState } from 'react';
import Utils from '../../lib/Utils';
import { Button, FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Separator from './Separator';
import SmallLoader from './SmallLoader';
import StateUpdators from '../../lib/StateUpdators';
import LoginFormViewLoginPart from './LoginFormViewLoginPart';

/**
 * The enter new password form
 * @param {number} challengeId The account Id 
 */
const LoginFormResetPasswordEnterNewPasswordView = ({challengeId}) => {
    console.log('{LoginFormResetPasswordEnterNewPasswordView}');
    const [showPassword, setShowPassword] = useState(false);
    const [input, setInput] = useState({
        password1: '',
        password2: ''
    })
    const [error, setError] = useState({
        password1: false,
        password2: false,
        genError: false,
        msg: '',
        msg1: '',
        msg2: ''
    })
    const [loggingIn, setLogginIn] = useState(false);
    useEffect(() => {
        
    },[]);
    return (
        <>
        <div className='ncbs-ldks'>
            <div className='mjd-wpx'>
                <h1 className='mjdjhd-qa'>{`Welcome Back :)`}</h1>
                <p className='mdjes-pa'>{`Enter your new password.`}</p>
            </div>
            <div className='mjdjhd-soow box-b'>
                <div className='ndsh w-80 centred'>
                    <FormControl fullWidth>
                        <TextField
                            error={error.password1}
                            required
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    password1: e.target.value
                                })
                            }}
                            value={input.password1}
                            aria-label='Toggle password visibility'
                            label='New Password:'
                            InputProps={{
                                endAdornment: 
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => {
                                        setShowPassword((show) => !show);
                                    }}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                                </InputAdornment>
                            }}
                            onFocus={() => {
                                if(error.password1) {
                                    setError({
                                        ...error,
                                        password1: false
                                    })
                                }
                                if(error.password2) {
                                    setError({
                                        ...error,
                                        password2: false
                                    })
                                }
                                if(error.genError) {
                                    setError({
                                        ...error,
                                        genError: false
                                    })
                                }
                            }}
                        >
                        </TextField>
                        {
                            error.password1 ? <span className='error'>{error.msg1}</span>:''
                        }
                    </FormControl>
                    <Separator style={{height: '10px'}} />
                    <FormControl fullWidth>
                        <TextField
                            error={error.password2}
                            required
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    password2: e.target.value
                                })
                            }}
                            value={input.password2}
                            aria-label='Toggle password visibility'
                            label='Repeat Password:'
                            InputProps={{
                                endAdornment: 
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => {
                                        setShowPassword((show) => !show);
                                    }}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                                </InputAdornment>
                            }}
                            onFocus={() => {
                                if(error.password1) {
                                    setError({
                                        ...error,
                                        password1: false
                                    })
                                }
                                if(error.password2) {
                                    setError({
                                        ...error,
                                        password2: false
                                    })
                                }
                                if(error.genError) {
                                    setError({
                                        ...error,
                                        genError: false
                                    })
                                }
                            }}
                        >
                        </TextField>
                        {
                            error.password2 ? <span className='error'>{error.msg2}</span>:''
                        }
                        {
                            error.genError ? <span className='error'>{error.msg}</span>:''
                        }
                    </FormControl>
                    <Separator style={{height: '10px'}} />
                    {
                        loggingIn ? 
                        <>
                        <Separator style={{height: '20px'}} />
                        <div className='content-centre'>
                            <SmallLoader title='Saving password...' customClasses='jhjsjs-wjsgs' />
                        </div> 
                        </>: ''
                    }
                </div>
            </div>
            <div className='mdjs-wmsk'>
                <div></div>
                <div className='content-centre ptr' title='Save'>
                    <Button variant='contained' color='primary' className='force-round-btn' onClick={() => {
                        if(!input.password1) {
                            setError({
                                ...error,
                                password1: true,
                                msg1: 'Please enter your new password'
                            })
                            return;
                        } else {
                            setError({
                                ...error,
                                password1: false
                            })
                        }
                        if(!input.password2) {
                            setError({
                                ...error,
                                password2: true,
                                msg2: 'Please re-enter your new password'
                            })
                            return;
                        } else {
                            setError({
                                ...error,
                                password2: false
                            })
                        }
                        if(input.password1.length < Utils.MIN_PWD) {
                            setError({
                                ...error,
                                genError: true,
                                msg: 'Password is too short (must be between 8 and 16 characters long).'
                            })
                            return;
                        } else {
                            setError({
                                ...error,
                                genError: false
                            })
                        }
                        if(input.password1.length > Utils.MAX_PWD) {
                            setError({
                                ...error,
                                genError: true,
                                msg: 'Password is too long (must be between 8 and 16 characters long).'
                            })
                            return;
                        } else {
                            setError({
                                ...error,
                                genError: false
                            })
                        }
                        if(input.password1 !== input.password2) {
                            setError({
                                ...error,
                                genError: true,
                                msg: 'The passwords do not match'
                            })
                            return;
                        } else {
                            setError({
                                ...error,
                                genError: false
                            })
                        }
                        setLogginIn(true);
                        Utils.saveNewPassword({...input, challengeId: challengeId});
                    }}>
                        Save
                    </Button>
                </div>
            </div>
            <div className='jdjhsh-lws'>
                <div className='content-centre ptr' title='Create your account now'>
                    <Button color='primary' disabled={true}>
                        Create Account
                    </Button>
                </div>
                <div className='content-centre ptr' title='Login to your account'>
                    <Button onClick={() => {
                        StateUpdators.setLoginFormViewLoginPart({
                            C: () => <LoginFormViewLoginPart reLogin={false} />
                        });
                    }}>
                        Login
                    </Button>
                </div>
            </div>
        </div>
        </>
    )
}

export default LoginFormResetPasswordEnterNewPasswordView;