import React from 'react';
import { Avatar, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Album, Dashboard, Group, Help, Logout, PeopleAlt, Settings } from '@mui/icons-material';
import Utils from '../../lib/Utils';
import StateUpdators from '../../lib/StateUpdators';
import AdminUsers from '../../lib/AdminUsers';
import BaseName from '../../lib/BaseName';


/**
 * Nav menu
 * @param {object} props The props object with the `avatarIcon` and `avatarTitle`.
 * @returns 
 */
const NavMenu = (props) => {
    console.log('{NavMenu}');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    Utils.handleMenuClick = (event) => {
        console.log('[Utils.handleMenuClick]');
        setAnchorEl(event.currentTarget);
    }
    StateUpdators.setAnchorEl = setAnchorEl;
    const handleClose = () => {
        setAnchorEl(null);
    };
    const curriculum = Utils.getCachedCurriculumInfo();
    return (
        <Menu
            anchorEl={anchorEl}
            id='account-menu'
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
            elevation: 0,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                },
                '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                },
            },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem title={props.avatarTitle} onClick={() => {
                Utils.addAnalytics({
                    page: 'NavMenu',
                    url: window.location.href,
                    title: 'NS.AccountHeader',
                    action: 'ClickMyAccountLink'
                });
                handleClose();
                Utils.myAccount();
            }}>
                <Avatar src={props.avatarIcon?props.avatarIcon:''} alt={props.avatarTitle} title={props.avatarTitle} /> <span className='ptr' title='Manage my account'>My Account</span>
            </MenuItem>
            <Divider />
            {
                curriculum.extra && curriculum.extra.shortName === 'DSC' &&
                <MenuItem title='View didital badges' onClick={() => {
                    Utils.addAnalytics({
                        page: 'NavMenu',
                        url: window.location.href,
                        title: 'NS.AccountHeader',
                        action: 'ClickDigitalBadgesLink'
                    });
                    handleClose();
                    Utils.digitalBadges();
                }}>
                    <ListItemIcon>
                        <Album fontSize='small' />
                    </ListItemIcon>
                    Digital Badges
                </MenuItem>
            }
            {
                AdminUsers.indexOf(props.userType) !== -1 &&
                <div>
                    <MenuItem title='Manage Students' onClick={() => {
                        Utils.addAnalytics({
                            page: 'NavMenu',
                            url: window.location.href,
                            title: 'NS.AccountHeader',
                            action: 'ClickEducatorDashboardLink'
                        });
                        handleClose();
                        Utils.goTo({url: `/${BaseName.name}/edudash`});
                    }}>
                        <ListItemIcon>
                            <Dashboard fontSize='small' />
                        </ListItemIcon>
                        Educator Dashboard
                    </MenuItem>
                    <MenuItem title='Manage Students' onClick={() => {
                        Utils.addAnalytics({
                            page: 'NavMenu',
                            url: window.location.href,
                            title: 'NS.AccountHeader',
                            action: 'ClickStudentsLink'
                        });
                        handleClose();
                        Utils.goTo({url: `/${BaseName.name}/students`});
                    }}>
                        <ListItemIcon>
                            <PeopleAlt fontSize='small' />
                        </ListItemIcon>
                        Students
                    </MenuItem>
                    <MenuItem title='Manage Students' onClick={() => {
                        Utils.addAnalytics({
                            page: 'NavMenu',
                            url: window.location.href,
                            title: 'NS.AccountHeader',
                            action: 'ClickStudentGroupsLink'
                        });
                        handleClose();
                        Utils.goTo({url: `/${BaseName.name}/students/groups`});
                    }}>
                        <ListItemIcon>
                            <Group fontSize='small' />
                        </ListItemIcon>
                        Student Groups
                    </MenuItem>
                </div>
            }
            <MenuItem title='Manage settings' onClick={() => {
                Utils.addAnalytics({
                    page: 'NavMenu',
                    url: window.location.href,
                    title: 'NS.AccountHeader',
                    action: 'ClickSettingsLink'
                });
                handleClose();
                Utils.settings();
            }}>
                <ListItemIcon>
                    <Settings fontSize='small' />
                </ListItemIcon>
                Settings
            </MenuItem>
            <MenuItem title='Help & Support' onClick={() => {
                Utils.addAnalytics({
                    page: 'NavMenu',
                    url: window.location.href,
                    title: 'NS.AccountHeader',
                    action: 'ClickHelpLink'
                });
                handleClose();
                Utils.helpPage();
            }}>
                <ListItemIcon>
                    <Help fontSize='small' />
                </ListItemIcon>
                Help
            </MenuItem>
            <MenuItem title='Logout' onClick={() => {
                Utils.addAnalytics({
                    page: 'NavMenu',
                    url: window.location.href,
                    title: 'NS.AccountHeader',
                    action: 'ClickLogoutLink'
                });
                handleClose();
                Utils.logout();
            }}>
                <ListItemIcon>
                    <Logout fontSize='small' />
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    )
}

export default NavMenu;