import React from 'react';
import Assets from '../../lib/Assets';
import Texts from '../../lib/Texts';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import PercetageColoration from './PercetageColoration';
import { Button } from '@mui/material';
import BaseName from '../../lib/BaseName';
import Utils from '../../lib/Utils';
import QuizzedProgressCurriculums from '../../lib/QuizzedProgressCurriculums';

/**
 * Show student progress
 * @param {object} progress The progress object
 * @param {object} lesson The lesson object
 * @returns 
 */
const ShowProgressData = ({lesson,progress}) => {
    console.log('{ShowProgressData}');
    //console.log('progress: ',progress);
    const curriculum = Utils.getCachedCurriculumInfo();
    return (
        <>
        <div className='progress-data-nffgh jhd-fljg-d animate__animated animate__fadeIn'>
            <img alt='Progress' className='ptr' title='No progress yet' src={Assets.icons.digitalBadges} />
            <h3 className='mgn-top-0'>{Texts.progressSoFar}</h3>
            <div className='progress-section shadow rounded w-90 h-centred'>
                <div className='pgrs-left content-centre'>
                    <h3>Lesson Completion:</h3>
                </div>
                <div className='pgrs-right hdgdg-profeshsh rounded-right'>
                    <div className='x-jfhf content-centre'>
                        {
                            progress.data.lessonCompletion ?
                            <>
                            <DoneIcon className='hfgfg-ok ptr' titleAccess='Lesson Completion Status: Complete' />
                            </>
                            :
                            <>
                            <CloseIcon className='hfgfg-x ptr' titleAccess='Lesson Completion Status: Not Complete' />
                            </>
                        }
                    </div>
                    <div className='hfgfg-vomp content-centre'>
                        {
                            progress.data.lessonCompletion ?
                            <>
                            <p className='hfghfg-ok ptr' title='Lesson complete'>Complete</p>
                            </>
                            :
                            <>
                            <p className='hfghfg-x ptr' title='Lesson not complete'>Not Complete</p>
                            </>
                        }
                    </div>
                    <div className='hfgd-khd-scr content-centre'>
                        <p className='ptr' title='Your score after the lesson quiz/test'>Score: {progress.data.lessonScore??0}%</p>
                    </div>
                </div>
            </div>
            <div className='progress-section cd-nfhfg shadow rounded w-90 h-centred'>
                <div className='pgrs-left content-centre'>
                    <h3>Course Completion:</h3>
                </div>
                <div className='pgrs-right hdgdg-ncbdgrg ncbdg rounded-right ptr' title='Lessons complete for this course'>
                    <div  className='content-centre'>
                        <p className='jfhfh jjjd-fkgj ndhdfg-jfhyh'>{progress.data.completedForCourse}/{progress.data.totalCourseLessons} Lessons</p>
                    </div>
                    <div className='jshsjs'>
                        <PercetageColoration 
                            value={parseFloat(((progress.data.completedForCourse/progress.data.totalCourseLessons))*100).toFixed(2)}
                        />
                    </div>
                </div>
            </div>
            <div className='progress-section nhfh-jgh shadow rounded w-90 h-centred'>
                <div className='pgrs-left content-centre'>
                    <h3>Overall Completion:</h3>
                </div>
                <div className='pgrs-right hdgdg-ncbdgrg rounded-right ptr' title='Lessons complete for this curriculum'>
                    <PercetageColoration 
                        value={parseFloat((progress.data.totalCurriculumLessonsCompleted/progress.data.totalCurriculumLessons)*100).toFixed(2)}
                    />
                </div>
            </div>
        </div>
        {
            progress.data.canRetakeLessonQuiz &&
            <div className='nvhfgh-retakenfhj-jghg box-b rounded w-90 h-centred bdr-bl shadow'>
                <h3 dangerouslySetInnerHTML={{__html: progress.data.lessonScore < progress.data.passScore?Texts.lessonQuizRetake:Texts.retakeLesson}}></h3>
                    <Button variant='contained' color='primary' onClick={() => {
                        const token = {
                            quizType: 'LessonQuiz',
                            courseId: lesson.courseId,
                            lessonId: lesson.lessonId,
                            accessToken: Utils.getAccessToken().token,
                            curriculumShortName: curriculum.extra.shortName
                        };
                        //window.location = `/${BaseName.name}/quiz/${Utils.base64Encode(JSON.stringify(token))}`;
                        window.open(`/${BaseName.name}/quiz/${Utils.base64Encode(JSON.stringify(token))}`);
                    }}>
                        Take the Lesson Quiz
                    </Button>
            </div>
        }
        {
            progress.data.hasNotTakenLessonQuiz && (!progress.data.canRetakeLessonQuiz) && QuizzedProgressCurriculums.indexOf(curriculum.extra.shortName) > -1 &&
            <div className='nvhfgh-retakenfhj-jghg box-b rounded w-90 h-centred bdr-bl shadow'>
                <h3 dangerouslySetInnerHTML={{__html: Texts.howToMakeProgress.TBC}}></h3>
                    <Button variant='contained' color='primary' onClick={() => {
                        const token = {
                            quizType: 'LessonQuiz',
                            courseId: lesson.courseId,
                            lessonId: lesson.lessonId,
                            accessToken: Utils.getAccessToken().token,
                            curriculumShortName: Utils.getCachedCurriculumInfo().extra.shortName
                        };
                        //window.location = `/${BaseName.name}/quiz/${Utils.base64Encode(JSON.stringify(token))}`;
                        window.open(`/${BaseName.name}/quiz/${Utils.base64Encode(JSON.stringify(token))}`);
                    }}>
                        Take the Lesson Quiz
                    </Button>
            </div>
        }
        </>
    )
}

export default ShowProgressData;