import React from 'react';
import StateUpdators from '../../lib/StateUpdators';
import { Button } from '@mui/material';

/**
 * Confirm usbmission of a question paper
 * @param {fn} onConfirm The onConfirm function callback. The callback is passed with its args.
 * @param {number} answered The questions answered number
 * @param {number} questions The number of questions for the quiz
 * @returns 
 */
const ConfirmQuestionsPaperSubmission = ({onConfirm, answers,questions}) => {
    console.log('{ConfirmQuestionsPaperSubmission}');
    return (
        <>
        <div className='gdgsdgdj-mnfhfh'>
            <h1 className='nfhfgpndh'>Please confirm</h1>
            <h3>You have answered {answers} / {questions} questions.</h3>
            <h3>Are you sure you want to submit?</h3>
            <div className='gfhh-nbcgfgd w-40 centred'>
                <Button variant='contained' color='secondary' onClick={() => {
                    StateUpdators.setActionModal({show:false});
                }} className='force-round-btn'>
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={() => {
                    onConfirm();
                }} className='force-round-btn'>
                    Confirm
                </Button>
            </div>
        </div>
        </>
    )
}

export default ConfirmQuestionsPaperSubmission;