import React from 'react';
import Assets from '../../lib/Assets';
import Texts from '../../lib/Texts';

/**
 * When we did not find any tech talk content
 * @returns 
 */
const NoTechTalkContentYet = () => {
    return (
        <>
        <div className='hdhsgsgs rounded'>
            <img alt='Empty box' src={Assets.icons.emptyBox} />
            <p>{Texts.noTechTalkcontent}</p>
        </div>
        </>
    )
}

export default NoTechTalkContentYet;