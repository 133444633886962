import * as React from 'react'
import Assets from '../../lib/Assets';
import CustomImage from './CustomImage';
import Utils from '../../lib/Utils';
import Templates from './_class_actions/Templates';
import Resources from './_class_actions/Resources';
import Achievements from './_class_actions/Achievements';
import TechTalk from './TechTalk';
import LessonPageCurriculumHandlers from './LessonPageCurriculumHandlers';
import StateUpdators from '../../lib/StateUpdators';

const TaskPageItemsHTML = ({info}) => {
    console.log('{TaskPageItemsHTML}');
    //console.log('{TaskPageItemsHTML}: props=',info);
    const curriculum = Utils.getCachedCurriculumInfo();
    //console.log('curriculum',curriculum);
    const Handler = LessonPageCurriculumHandlers[curriculum.extra.shortName]??LessonPageCurriculumHandlers.Error;
    const _handler = React.useRef(Handler);
    //console.log('handler: ',_handler)
    const eqTheme = React.useRef(info.lesson.EQThemes);
    React.useEffect(() => {
        if(_handler.current.error??false) {
            Utils.handleError({error: 'handlerError', msg: _handler.current.msg});
        }
        StateUpdators.setIsEQLesson(eqTheme.current.isEQLesson);
    },[]);
    return (
        <div>
            <div className='circle-container'>
                <div className='circle-container-inner-1 shadow'>
                </div>

                <div className='fsf-hdfdf circle-container-inner shadow'>
                    <CustomImage onClick={() => {
                        Utils.addAnalytics({
                            page: 'Lesson',
                            url: window.location.href,
                            title: `NS.Lesson.ShowZoomedLessonOutcome`,
                            courseId: info.lesson.courseId,
                            lessonId: info.lesson.lessonId
                        });
                        Handler.suggestedOutcomeTitleHandler({lessonOutcome: info.lessonOutcome})
                    }} className='hggd-outcome-hshs mdjdh-ield ptr-zoom' id='hdgdg-hfhd-placeholder' src={info.lesson.lessonOutcome} title={info.lessonOutcomeTitle} alt={info.lessonTitle} />
                </div>

                <div title={Handler.instructionsTitle??'Instructions'} className='ptr circle-item shadow item-1' onClick={() => {
                    Utils.addAnalytics({
                        page: 'Lesson',
                        url: window.location.href,
                        title: `NS.Lesson.ShowInstructions`,
                        courseId: info.lesson.courseId,
                        lessonId: info.lesson.lessonId
                    });
                    Utils.currentLessonInfo = info;
                    Handler.onInstructionsClick({...info})
                }}>
                    <div className='item-hffg-text rounded shadow box-b hddg-1'>
                        <div className='gdgd-instrs rounded-left'>
                            <h3>{Handler.instructionsTitle??'Instructions'}</h3>
                        </div>
                        <div className='big-dot rounded-right'>
                        </div>
                    </div>
                    <div className='instr-hfhf itsmfhd-hfhf'>
                        <img className='hsg-icons0-dkdh' alt={Handler.instructionsTitle??'Instructions'} src={Assets.taskPage.instructions} />
                    </div>
                </div>
                <div className='line-hddg-1'></div>
                
                <div title={Handler.templatesTitle??'Templates'} className='ptr circle-item shadow item-2' onClick={() => {
                    Utils.addAnalytics({
                        page: 'Lesson',
                        url: window.location.href,
                        title: `NS.Lesson.ShowTemplates`,
                        courseId: info.lesson.courseId,
                        lessonId: info.lesson.lessonId
                    });
                    Utils.showLessonTaskPageModal({
                        Content: () => <Templates lesson={info} />
                    })
                }}>
                    <div className='item-hffg-text-right rounded shadow box-b hddg-5'>
                        <div className='big-dot-jx rounded-left'>
                        </div>
                        <div className='gdgd-instrs hdg-mldh hgdgd-right rounded-right'>
                            <h3 className='hfhd-jd'>{Handler.templatesTitle??'Templates'}</h3>
                        </div>
                    </div>
                    <div className='templatesjfhf itsmfhd-hfhf'>
                        <img className='hsg-icons0-dkdh' alt={Handler.templatesTitle??'Templates'} src={Assets.taskPage.templates} />
                    </div>
                </div>
                <div className='line-hddg-5'></div>
                
                <div title={Handler.resourcesTitle??'Resources'} className='ptr circle-item shadow item-3' onClick={() => {
                    Utils.addAnalytics({
                        page: 'Lesson',
                        url: window.location.href,
                        title: `NS.Lesson.ShowResources`,
                        courseId: info.lesson.courseId,
                        lessonId: info.lesson.lessonId
                    });
                    Utils.showLessonTaskPageModal({
                        Content: () => <Resources lesson={info} />
                    })
                }}>
                    <div className='item-hffg-text-right rounded shadow box-b hddg-6'>
                        <div className='big-dot-ja rounded-left'>
                        </div>
                        <div className='gdgd-instrs hdg-mldh hgdgd-right rounded-right'>
                            <h3 className='hfhd-jd'>{Handler.resourcesTitle??'Resources'}</h3>
                        </div>
                    </div>
                    <div className='resyhshs itsmfhd-hfhf'>
                        <img className='hsg-icons0-dkdh' alt={Handler.resourcesTitle??'Resources'} src={Assets.taskPage.resources} />
                    </div>
                </div>
                <div className='line-hddg-6'></div>
                
                <div title={Handler.achievementsTitle??'Achievements'} className='ptr circle-item shadow item-4' onClick={() => {
                    Utils.addAnalytics({
                        page: 'Lesson',
                        url: window.location.href,
                        title: `NS.Lesson.ShowAchievements`,
                        courseId: info.lesson.courseId,
                        lessonId: info.lesson.lessonId
                    });
                    Utils.showModal({
                        Content: () => <Achievements lesson={info} />
                    })
                }}>
                    <div className='item-hffg-text-right rounded shadow box-b hddg-7'>
                        <div className='big-dot-j3 rounded-left'>
                        </div>
                        <div className='gdgd-instrs hdg-mldh-f rounded-right'>
                            <h3 className='hfhd-jd'>{Handler.achievementsTitle??'Achievements'}</h3>
                        </div>
                    </div>
                    <div className='bchdjs-djfh itsmfhd-hfhf'>
                        <img className='hsg-icons0-dkdh' alt={Handler.achievementsTitle??'Achievements'} src={Assets.taskPage.achievements} />
                    </div>
                </div>
                <div className='line-hddg-7'></div>
                
                <div title={Handler.historyTitle??'History'} className='ptr circle-item shadow item-5' onClick={() => {
                    Utils.addAnalytics({
                        page: 'Lesson',
                        url: window.location.href,
                        title: `NS.Lesson.ShowHistory`,
                        courseId: info.lesson.courseId,
                        lessonId: info.lesson.lessonId
                    });
                    Handler.onHistoryClick({...info})
                }}>
                    <div className='item-hffg-text-right rounded shadow box-b hddg-8'>
                        <div className='big-dot-jj rounded-left'>
                        </div>
                        <div className='gdgd-instrs hdg-mldh rounded-right'>
                            <h3 className='hfhd-jd'>{Handler.historyTitle??'History'}</h3>
                        </div>
                    </div>
                    <div className='bcgdgd-dd itsmfhd-hfhf'>
                        <img className='hsg-icons0-dkdh' alt={Handler.historyTitle??'History'} src={Assets.taskPage.history} />
                    </div>
                </div>
                <div className='line-hddg-8'></div>
                
                <Handler.Alignments info={info}/>
                
                <div title={Handler.techTalkTitle??'Tech Talk'} className='ptr circle-item shadow item-7' onClick={() => {
                    Utils.addAnalytics({
                        page: 'Lesson',
                        url: window.location.href,
                        title: `NS.Lesson.ShowTechTalk`,
                        courseId: info.lesson.courseId,
                        lessonId: info.lesson.lessonId
                    });
                    Utils.showFullscreenModal({
                        Content: () => <TechTalk lesson={{...info}} />
                    });
                }}>
                    <div className='item-hffg-text rounded shadow box-b hddg-2'>
                        <div className='gdgd-instrs hdg-mldh rounded-left'>
                            <h3 className='hfhd-jd'>{Handler.techTalkTitle??'Tech Talk'}</h3>
                        </div>
                        <div className='big-dot-jf rounded-right'>
                        </div>
                    </div>
                    <div className='ggfsoeyd itsmfhd-hfhf'>
                        <img className='hsg-icons0-dkdh' alt={Handler.techTalkTitle??'Tech Talk'} src={Assets.taskPage.templates} />
                    </div>
                </div>
                <div className='line-hddg-2'></div>
                
                <div title={Handler.feedbackToolTitle??'Parent Feedback'} className='ptr circle-item shadow item-8' onClick={() => {
                    Utils.addAnalytics({
                        page: 'Lesson',
                        url: window.location.href,
                        title: `NS.Lesson.ShowParentFeedbackTool`,
                        courseId: info.lesson.courseId,
                        lessonId: info.lesson.lessonId
                    });
                    Handler.onFeedbackToolClick({...info});
                }}>
                    <div className='item-hffg-text rounded shadow box-b hddg-3'>
                        <div className='gdgd-instrs hdg-kljs rounded-left'>
                            <h3 className='hfhd-jd'>{Handler.feedbackToolTitle??'Parent Feedback'}</h3>
                        </div>
                        <div className='big-dot-kl rounded-right'>
                        </div>
                    </div>
                    <div className='bdh-eht0c-dd itsmfhd-hfhf'>
                        <img className='hsg-icons0-dkdh' alt={Handler.feedbackToolTitle??'Parent Feedback'} src={Assets.taskPage.parentFeedback} />
                    </div>
                </div>
                <div className='line-hddg-3'></div>
            </div>
        </div>
    )
}


export default TaskPageItemsHTML
