import React, {useEffect, useRef, useState} from 'react';
import CountryList from './CountryList';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Utils from './Utils';

/**
 * A List of countries
 * @param {fn} param0 The onChange handler
 * @returns 
 */
const CountriesMUIVersion = ({defaultCountry}) => {
    console.log('{CountriesMUIVersion}');
    const _defaultCountry = useRef(defaultCountry)
    const [input, setInput] = useState('');
    Utils.registrations.country = input;
    useEffect(() => {
        setInput({
            countryId: _defaultCountry.current.countryId,
            dialCode: _defaultCountry.current.dialCode
        })
    },[]);
    return(
        <div className='countries'>
            <FormControl fullWidth>
                <InputLabel id='country-select-label'>Country</InputLabel>
                <Select
                    labelId='country-select-label'
                    id='country-select'
                    value={input?JSON.stringify(input):''}
                    label='Country'
                    onChange={(e) => setInput(JSON.parse(e.target.value))}
                >
                    {
                    CountryList.map((country,i) => {
                        i++;
                        return (
                            <MenuItem key={country[i].Id} value={JSON.stringify({countryId: country[i].Id,dialCode: country[i].DialCode})}>{country[i].Name}</MenuItem>
                        )
                    })
                }
                </Select>
            </FormControl>
        </div>
    )
}

export default CountriesMUIVersion;