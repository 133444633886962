import React from 'react';
import Assets from '../../lib/Assets';

/**
 * Operation was ok
 * @returns 
 */
const OperationWasOk = ({ExtraContent}) => {
    console.log('{OperationWasOk}');
    return (
        <>
        <div className='msjs-kdkd-hdhd'>
            <img src={Assets.icons.statusOk} className='ndns-peejns'  alt=''/>
            <ExtraContent />
        </div>
        </>
    )
}

export default OperationWasOk;