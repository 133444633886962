import React, { useEffect, useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import { useDropzone } from 'react-dropzone';
import Utils from '../../lib/Utils';
import Texts from '../../lib/Texts';
import { Clear } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Assets from '../../lib/Assets';

/**
 * Profile picture upload form
 * @param {string} accept The files to accept 
 * @returns 
 */
const UploadProfilePciture = ({accept}) => {
    console.log('{UploadProfilePciture}');
    const [loading, setButtonLoading] = useState(false);
    const [_file, setInputFile] = useState({
        _preViewing: false,
        src: null,
        file: {}
    });
    StateUpdators.setInputFile = setInputFile;
    StateUpdators.setButtonLoading = setButtonLoading;
    const {acceptedFiles, fileRejections,getRootProps, getInputProps, isDragActive} = useDropzone({
        accept, 
        multiple: false,
    });
    useEffect(() => {
        Utils.readSelectedFile({
            file: acceptedFiles.length > 0 ? acceptedFiles[0]: false,
            fileStateUpdator: setInputFile
        });
    },[acceptedFiles]);
    
    return (
        <>
        <div className='drop-zone-jgh nbgd-d dropzone-div w-90 h-centred'>
            <div className='upl-jfhgfgf'>
                <div className='up-preview bdr-bl'>
                    {
                        _file.src &&
                        <img alt='Profile' className='profile-uploaded' src={_file.src} />
                    }
                </div>
                <div className='box-b'>
                    <h2 className='nfhf-nghgh'>{Texts.uploadHdr}</h2>
                    <p className='fhghg-jdhfgh'>{Texts.uploadSizes}</p>
                </div>
            </div>
            {
                _file._preViewing ?
                <>
                <div className='pre-viewing-nfhfg'>
                    <div className='shadow bdr-bl jdhdgfg'>
                        <img alt='Profile' className='profile-uploaded-mcjhf' src={_file.src} />
                    </div>
                </div>
                </>
                
                :

                <>
                <div className={`dropzone0j-nfhfgh bdr-bl rounded dnd-${isDragActive?'active shadow':''}`} {...getRootProps()}>
                    <input className='dropzone-input' {...getInputProps()} />
                    <div className='text-center'>
                        {
                            isDragActive ? 
                            <>
                            <p className={`dropzone-content dnd-${isDragActive?'active-text':''}`}>{Texts.dropToUpload}</p>
                            </> :
                            <>
                            <div className='dropzone-content'>
                                <p>{Texts.dnp}</p>
                            </div>
                            </>
                        }
                    </div>
                </div>
                {
                    fileRejections.length > 0 &&
                    <>
                    <div className='file-errors-jghgh animate__animated animate__fadeIn'>
                        <img alt='Error' className='files-error-jghgh' src={Assets.icons.error} />
                        <h3>An error occured:</h3>
                        <div className='error-itemsnfbf'>
                        {
                            fileRejections.map((error, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <p className='err-ofg'>{error.errors[0].message}</p>
                                    </React.Fragment>
                                )
                            })
                        }
                        </div>
                    </div>
                    </>
                }
                </>
            }
        </div>
        {
            _file.src &&
            <div className='gfhgs-jghgh-nfhf w-90 h-centred'>
                <div className='preview-tools'>
                    <Clear titleAccess='Clear Picture' className='ptr' onClick={() => {
                        if(!loading) {
                            setInputFile({
                                _preViewing: false,
                                src: null,
                            });
                        } else {
                            Utils.showSideFeedback({msg: Texts.uploadInProgress});
                        }
                    }} />
                </div>
                <div className='animate__animated animate__fadeIn jfhfh-jshgd-save'>
                    <LoadingButton className='force-round-btn ptr' 
                        variant='contained' 
                        color='primary' 
                        title='Save'
                        startIcon={<SaveIcon />}
                        loadingPosition='start'
                        loading={loading}
                        onClick={() => {
                            Utils.uploadUserProfilePicture({file:_file.file, fileData: _file.src});
                        }}
                    >
                        <span>Save</span>
                    </LoadingButton>
                </div>
            </div>
        }
        </>
    )
}

export default UploadProfilePciture;