import React, { useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import '../../css/ProgressBarStyles.css';

/**
 * Show a progress loader. It can be customized with `mainClass` optional class name, which gives access so that
 * some functions can get hold of the loader and show/hide it as needed.
 * The `doingWhatClass` gives access to the 'doing what' text to a specific function which uses the loader
 * props: 
 * `title` (string), - the title to show as the mounted div title
 * `show` (boolean) - whether to show the loader, 
 * `parentWidth` (number) - the with of the parent in which the loader is mounted, 
 * `width` (number) - with of the loader to use
 * `msg` (string) - the string to show as the 'please waitt...' message
 * @param {object} props 
 * @returns React Component
 */
const  PluggableLoader = (props) => {
    console.log('{PluggableLoader}');
    const [configs, setPluggableLoaderConfigs] = useState({
        show: props.show || false,
        msg: props.msg || '',
        title: props.title || ''
    });
    const _left = (props.parentWidth-props.width)/2.0;
    const _top = props.parentHeight?(props.parentHeight-props.width)/2.0:_left;
    StateUpdators.setPluggableLoaderConfigs = setPluggableLoaderConfigs;
    const styles = {
        width: `${props.width}px`,
        height: `${props.width}px`,
        left: `${_left}px`,
        top: `${_top}px`
    };
    return(
        <>
        {configs.show && 
            <div title={configs.title} style={props.inlineCSS?{...styles}:{}} id='progressBar-p' className='box-b ptr animate__animated animate__fadeIn rounded-small'>
                <div id='smartLoader' title={configs.title} className='pointer'>
                    <svg id='gfgfg-svg' viewBox='0 0 90 90' xmlns='http://www.w3.org/2000/svg'>
                        <circle id='c' fill='none' strokeWidth='8' style={{ stroke: '#1367aa'}} cx='45' cy='45' r='40' />
                    </svg>
                </div>
                <div title={configs.msg} className='ptr' id='doingWhat'>
                    <p><b>{configs.msg}</b></p>
                </div>
            </div>
        }
        </>
    )
}

export default PluggableLoader;