import React, { useEffect } from 'react';

/**
 * MoreInfo View
 * @returns 
 */
const MoreInfoView2 = (props) => {
    console.log('{MoreInfoView2}');
    useEffect(() => {

    },[]);
    return (
        <div className='mdhsjs-pwks'>
            <div className='mdhs-owpwp'>
                <h3>{props.title}</h3>
            </div>
            <div className='ksks-peiejns box-b'>
                <props.Content />
            </div>
        </div>
    )
}

export default MoreInfoView2;