import React, {useState} from 'react';
import CountryList from './CountryList';

/**
 * A List of countriesc
 * @param {fn} param0 The onChange handler
 * @returns 
 */
const Countries = ({onChange,defaultCountry}) => {
    console.log('{Countries}');
    const [input, setInput] = useState({
        countryId: defaultCountry.countryId,
        dialCode: defaultCountry.dialCode
    });
    return(
        <div className='countries'>
            <select onChange={(e) => {
                setInput(JSON.parse(e.currentTarget.value));
                onChange(JSON.parse(e.currentTarget.value));
            }} id='Countries' className='smart-roundy' value={JSON.stringify(input)}>
                {
                    CountryList.map((country,i) => {
                        i++;
                        return (
                            <option key={country[i].Id} value={JSON.stringify({countryId: country[i].Id,dialCode: country[i].DialCode})}>{country[i].Name}</option>
                        )
                    })
                }
            </select>
        </div>
    )
}

export default Countries;