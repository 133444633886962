import React, { useEffect, useState } from 'react';
import Texts from '../../lib/Texts';
import { Button } from '@mui/material';
import Utils from '../../lib/Utils';
import StateUpdators from '../../lib/StateUpdators';

/**
 * A link to the old curriculums
 * @returns 
 */
const LinkToOldCurriculums = () => {
    console.log('{LinkToOldCurriculums}')
    const [show, setShow] = useState(true)
    StateUpdators.setOldLinkShow = setShow
    useEffect(() => {
        //Utils.checkAccountIfToShowOldLink({stateUpdator: setShow})
    },[])
    return (
        <>
        {
            show ? 
            <div className='animate__animated animate__fadeIn ksjsj box-b rounded w-80 centred shadow'>
                <p>{Texts.ifOldCurrs}</p>
                <Button color='primary' variant='contained' className='force-round-btn ptr' title='Access Other Curriculums' onClick={() => {
                    Utils.addAnalytics({
                        page: 'Home',
                        url: window.location.href,
                        title: 'NS.Click.LinkToOldCurriculumsButton'
                    })
                    Utils.loginToOldAccount()
                }}>
                    Access Other Curriculums
                </Button>
            </div> : <></>
        }
        </>
    )
}
export default LinkToOldCurriculums