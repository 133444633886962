import React, { useEffect, useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import { useDropzone } from 'react-dropzone';
import Utils from '../../lib/Utils';
import Texts from '../../lib/Texts';
import { Clear } from '@mui/icons-material';
import Assets from '../../lib/Assets';

/**
 * Profile picture upload form
 * @param {string} accept The files to accept 
 * @returns 
 */
const FileUpload = ({title,accept}) => {
    console.log('{FileUpload}');
    const [loading, setButtonLoading] = useState(false);
    const [uploading, setUploading] = useState({
        state: false,
        progress: 0
    });
    StateUpdators.setUploading = setUploading;
    const [_file, setInputFile] = useState({
        _preViewing: false,
        src: null,
        file: {}
    });
    StateUpdators.setInputFile = setInputFile;
    StateUpdators.setButtonLoading = setButtonLoading;
    const {acceptedFiles, fileRejections,getRootProps, getInputProps, isDragActive} = useDropzone({
        accept, 
        //multiple: true,
        multiple: false,
        //maxFiles: 5,
    });
    Utils.fileToUpload = _file;
    useEffect(() => {
        Utils.readSelectedFile({
            file: acceptedFiles.length > 0 ? acceptedFiles[0]: false,
            fileStateUpdator: setInputFile
        });
    },[acceptedFiles]);
    
    return (
        <>
        <div className='drop-zone-jgh nbgd-d dropzone-div w-90 h-centred'>
            <div className='upl-jfhgfgf'>
                <div className='up-preview bdr-bl'>
                    {
                        _file.src &&
                        <img alt={title} className='profile-uploaded' src={_file.src} />
                    }
                </div>
                <div className='box-b'>
                    <h2 className='nfhf-nghgh'>{title}</h2>
                    <p className='fhghg-jdhfgh'>{Texts.uploadSizesFile}</p>
                </div>
            </div>
            {
                _file._preViewing ?
                <>
                <div className='pre-viewing-nfhfg'>
                    <div className='shadow bdr-bl jdhdgfg'>
                        <img alt={title} className='profile-uploaded-mcjhf' src={_file.src} />
                    </div>
                </div>
                </>
                
                :

                <>
                <div className={`dropzone0j-nfhfgh bdr-bl rounded dnd-${isDragActive?'active shadow':''}`} {...getRootProps()}>
                    <input className='dropzone-input' {...getInputProps()} />
                    <div className='text-center'>
                        {
                            isDragActive ? 
                            <>
                            <p className={`dropzone-content dnd-${isDragActive?'active-text':''}`}>{Texts.dropToUpload}</p>
                            </> :
                            <>
                            <div className='dropzone-content'>
                                <p>{Texts.dnpFile}</p>
                            </div>
                            </>
                        }
                    </div>
                </div>
                {
                    fileRejections.length > 0 &&
                    <>
                    <div className='file-errors-jghgh animate__animated animate__fadeIn'>
                        <img alt='Error' className='files-error-jghgh' src={Assets.icons.error} />
                        <h3>An error occured:</h3>
                        <div className='error-itemsnfbf'>
                        {
                            fileRejections.map((error, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <p className='err-ofg'>{error.errors[0].message}</p>
                                    </React.Fragment>
                                )
                            })
                        }
                        </div>
                    </div>
                    </>
                }
                </>
            }
        </div>
        {
            _file.src &&
            <div className='gfhgs-jghgh-nfhf w-90 h-centred shadow bdr-bl'>
                <div className='preview-tools'>
                    <Clear titleAccess='Clear File' className='ptr' onClick={() => {
                        if(!loading) {
                            setUploading({state: false, progress: 0});
                            setInputFile({
                                _preViewing: false,
                                src: null,
                            });
                        } else {
                            Utils.showSideFeedback({msg: Texts.uploadInProgress});
                        }
                    }} />
                </div>
                {
                    uploading.state &&
                    <>
                    <div className='user-fl-upload-progress box-b'>
                        <div className='shd-hfgf-pgrs' style={{
                            width: `${uploading.progress}%`
                        }}>
                        </div>
                        <span className='jdhdh-jghg'>{parseInt(uploading.progress)}%</span>
                    </div>
                    </>
                }
            </div>
        }
        </>
    )
}

export default FileUpload;