import axios from 'axios';
import BaseName from './BaseName';
import StateUpdators from './StateUpdators';
import { Button, LinearProgress } from '@mui/material';
import Assets from './Assets';
import Instructions from '../views/tools/_class_actions/Instructions';
import ElearnerQuizApp from '../views/tools/_quizzes/ElearnerQuizApp';
import ElearnerQuizResults from '../views/tools/_quizzes/ElearnerQuizResults';
import StudentCompleteProfileForm from '../views/tools/StudentCompleteProfileForm';
import Texts from './Texts';
import LessonQuizResults from '../views/tools/_quizzes/LessonQuizResults';
import RenderCurriculum from '../views/tools/RenderCurriculum';
import RenderCurriculums from '../views/tools/RenderCurriculums';
import UserGuidesView from '../views/tools/UserGuidesView';
import NoCurriculums from '../views/tools/NoCurriculums';
import React, { Suspense } from 'react';
import {ErrorFetchingDigitalBadge} from '../views/tools/ProgressBadge';
import {DisplayDigitalBadge} from '../views/tools/ProgressBadge';
import {ErrorActivatingDGCDigitalBadge} from '../views/tools/ActivateDigitalBadgeForm';
import {SuccessActivatingDGCDigitalBadge} from '../views/tools/ActivateDigitalBadgeForm';
import CustomImage from '../views/tools/CustomImage';
import ICDLCategories from '../views/tools/ICDLCategories';
import AllICDLCategories from '../views/tools/AllICDLCategories';
import DisplayDigitalBadgeAlbum from '../views/tools/DisplayDigitalBadgeAlbum';
import TechTalkContent from '../views/tools/TechTalkContent';
import NoTechTalkContentYet from '../views/tools/NoTechTalkContentYet';
import UpdateBasicInfoForm from '../views/tools/UpdateBasicInfoForm';
import Notifications from '../views/tools/Notifications';
import SettingsApp from '../views/tools/SettingsApp';
import EducatorDashboardActions from '../views/tools/EducatorDashboardActions';
import Separator from '../views/tools/Separator';
import EducatorDashboardSectionsContentRenderers from '../views/tools/EducatorDashboardSectionsContentRenderers';
import CurriculumExtraActivities from '../views/tools/CurriculumExtraActivities';
import StudentSettings from '../views/tools/StudentSettings';
import Theme from './Theme';
import SpecialAccountHandlers from '../views/tools/SpecialAccountHandlers';
import SmallLoader from '../views/tools/SmallLoader';
import CurriculumsNotAvailable from '../views/tools/CurriculumsNotAvailable';
import Forms from '../views/tools/Forms';
import LoginFormViewLoginPart from '../views/tools/LoginFormViewLoginPart';
import RegisterNewAccountValidateOTPView from '../views/tools/RegisterNewAccountValidateOTPView';
import OperationWasOk from '../views/tools/OperationWasOk';
import RenderCurriculumSubscriptions from '../views/tools/RenderCurriculumSubscriptions';
import PaymentInitializers from '../views/tools/PaymentInitializers';
import CurriculumSubscriptionReviewOrder from '../views/tools/CurriculumSubscriptionReviewOrder';
import MoreInfoView2 from '../views/tools/MoreInfoView2';
import C4KUserCategories from '../views/tools/C4KUserCategories';

/**
 * The Utils object/module
 */
const Utils = {
    returnURL: false,
    /**
     * Registration items
     */
    registrations: {
        checkedTermsCheckBox: false,
        /**
         * Current selected country during registration
         */
        country: {}
    },
    chosenOption: 'HomeSchooler',
    MAX_PWD: 16,
    MIN_PWD: 8,
    closed: false,
    FOUR_SECONDS: 4000,
    FIVE_SECONDS: 5000,
    TWO_SEC: 2000,
    _devMode: (window.location.hostname === 'localhost') || (window.location.pathname.startsWith('/ns_demo')),
    requestTimeout: 10000,//10 seconds
    currentLessonInfo: {},
    instructionsCurrentPage: 1,
    MIN_IMAGE_UPLOAD: 512,
    MAX_IMAGE_HEIGHT: 1000,
    lessonsNavMenu: {
        ready: false,
        menu: {}
    },
    contacts: {
        wa: 'https://wa.me/27773760078'
    },
    cellphones: {
        c4k: {
            number1: '+27 77 376 0078'
        }
    },
    emails: {
        c4k: {
            info: 'info@computers4kids.co.za'
        }
    },
    myAccountLink: `/${BaseName.name}/curriculum`,
    colOldLogin: 'https://classesonline.mobi/account_login.php',
    colOldAccount: 'https://classesonline.mobi/my_account.php',
    c4kURL: 'https://computers4kids.co.za',
    elearnerURL: 'https://e-learner.mobi/',
    aboutC4KURL: 'https://computers4kids.co.za/about-ict/',
    DSCDBAssetsURL: 'https://computers4kids.co.za/assets/curriculum/DSC/icons/digital_badges/',
    CRCDBAssetsURL: 'https://computers4kids.co.za/assets/curriculum/CRC/icons/digital_badges/',
    /**
     * File to upload on the feedback tool
     */
    fileToUpload: {},
    /**
     * The C4K User Portal configs
     */
    portal : {
        loginURL: 'https://portal.computers4kids.co.za/auth',
    },
    //loginURL: 'http://localhost:3001/auth',
    loginURL: 'https://portal.computers4kids.co.za/auth',
    //loginURL: './auth',
    Colors: {
        COLBKG: '#3b354a'
    },
    assetsURL: 'https://computers4kids.co.za/assets/curriculum/',
    c4kAssetsURL: 'https://computers4kids.co.za/assets/',
    _assetsURL: 'https://computers4kids.co.za/assets/curriculums/',
    quizAssetsURL: 'https://computers4kids.co.za/assets/quiz/',
    api: {
        mainURL: 'https://api.computers4kids.co.za',
        Nervous: {
            url: 'https://api.computers4kids.co.za/serve/',
            headers: {
                apiToken: 'liveInternal_mHEWkQ6OIML6VydCd7FzJEPKlVhfU56lx70wxteiHhnHGrD8AzAlTCDQVzGcIMgd'
            }
        }
    },
    cache: {
        content: {}
    },
    animatedTextOnRegPage: [
        'Join us to have access to our premier Digital Skills Curriculum...',
        'Our Digital Skills Curriculum covers 21st Century ICT skills...',
        '...whilst integrating exciting classroom content such as STEM, arts and more!'
    ],
    /**
     * Check the current user if they need to see
     * @param {object} info The info object
     */
    checkAccountIfToShowOldLink: async (info) => {
        console.log('[Utisl.checkAccountIfToShowOldLink]')
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'checkAccountIfToShowOldLink',
                data: {
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
                if(results.data.shouldSeeOldLink) {
                    info.stateUpdator(true)
                }
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Fetch web content
     * @param {object} info
     */
    fetchWebContent: async (info) => {
        console.log('[Utils.fetchWebContent]: info=',info)
        Utils.showProgress();
    },
    /**
     * Reset the reg reviews
     */
    resetRegView: () => {
        console.log('[Utils.resetRegView]');
        StateUpdators.setHeader1('Your awesome journey starts here... let\'s quickly get you on board!')
        StateUpdators.setContent({
            C: () =>
                <>
                <C4KUserCategories />
                <div className='cont-btn-jghg'>
                <Button className='force-round-btn' variant='contained' color='primary' onClick={() => {
                    const FormView = Forms.UserRegistrations[Utils.chosenOption]??Forms.InvalidOption;
                    StateUpdators.setContent({
                        C: () => <FormView />
                    })
                }}>
                    Continue
                </Button>
            </div>
                </>
        })
    },
    /**
     * Check if a given email exists
     * @param {object} info The info object
     */
    _checkExistingEmail: async (info) => {
        console.log('[Utils.checkExistingEmail]');
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'checkExistingEmail',
                data: {
                    ...info,
                    publicRequest: true,
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            console.log('R=',results);
            if(!results.data.status) {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
            return results.data;
        } catch (error) {
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Check if a given email exists
     * @param {object} info The info object
     */
    checkExistingEmail: async (info) => {
        console.log('[Utils.checkExistingEmail]');
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'checkExistingEmail',
                data: {
                    publicRequest: true,
                    ...info
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
               if(results.data.exists) {
                    StateUpdators.setErrorOnHomeSchoolerForm({
                        firstname: false,
                        lastname: false,
                        email: true,
                        password: false,
                        password2: false,
                        msg: Texts.emailExists
                    });
                    Utils.showFeedbackError({msg: Texts._emailExists});
                } else {
                    StateUpdators.setErrorOnHomeSchoolerForm({
                        firstname: false,
                        lastname: false,
                        email: false,
                        password: false,
                        password2: false,
                        msg: ''
                    });
                }
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
        /**
     * Check that an institution does not exist
     * @param {object} info The info object
     */
    checkExistingInstitution: async (info) => {
        console.log('[Utils.checkExistingInstitution]');
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'checkExistingInstitution',
                data: {
                    publicRequest: true,
                    institutionName: info.institutionName
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            if(!results.data.status) {
                Utils.handleError({...results.data, shouldShow: true})
            }
            return results.data;
        } catch (error) {
            Utils.handleError({error: 'fatalError', msg: String(error.message)});
            return {
                status: false,
                exists: true
            }
        }
    },
    /**
     * Cancel a payment
     * @param {object} info The info object
     */
    cancelPayment: async (info) => {
        console.log('[Utils.cancelPayment]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'cancelPayment',
                data: {
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
               Utils.showFeedbackError({msg: results.data.message});
               StateUpdators.setCancelled({
                    cancelling: false,
                    cancelled: true,
                    error: false,
               });
            } else {
                StateUpdators.setCancelled({
                    cancelling: false,
                    cancelled: true,
                    error: results.data.message
                });
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Set the enable auto-renew option for a given user
     * @param {object} info The info object
     */
    setSubscriptionAutoRenew: async (info) => {
        console.log('[utils.setSubscriptionAutoRenew]');
        //console.log('[utils.setSubscriptionAutoRenew]: info=',info);
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'setSubscriptionAutoRenew',
                data: {
                  ...info,
                  accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
               if(info.showFeedback??false) {
                Utils.showFeedbackSuccess({msg: results.data.message});
               }
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)});
        }
    },
    /**
     * Show more information
     * @param {object} info The info object
     */
    showMoreInfo: (info) => {
        console.log('[utils.showMoreInfo]');
        Utils.showModal2({
            Content: () => <MoreInfoView2 title={Texts.titles[info.content]} Content={() =>
            <>
            <p>{Texts[info.content]}</p>
            </>} />
        });
    },
    /**
     * Verify a payment
     * @param {object} info The info object
     */
    verifyPayment: async (info) => {
        console.log('[Utils.verifyPayment]');
        console.log('[Utils.verifyPayment]: info=',info);
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'verifyPayment',
                data: {
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
                StateUpdators.setSuccessOrderInfo({
                    fetched: true,
                    info: results.data
                });
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Begin user payment transaction process
     * @param {object} info The info object
     */
    beginPaymentTransaction: async (info) => {
        console.log('[Utils.beginPaymentTransaction]');
        //console.log('[Utils.beginPaymentTransaction]: info=',info);
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'beginPaymentTransaction',
                data: {
                    ...info,
                    testMode: false,
                    deployURL: `${window.location.origin}/${BaseName.name}/`,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            if(results.data.status) {
                Utils.goTo({url: results.data.redirectTo});
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Send a raw XMLHttpRequest
     * @param {string} url The url to send
     * @param {string} method The request method
     * @param {object} headers The headers
     * @param {object} data The data to send
     */
    rawRequest: (method,url,headers = {},data = {}) => {
        console.log('[Utils.rawRequest]');
        //console.log('[Utils.rawRequest]: url=',url, 'method=',method,' headers=',headers, 'data=',data);
        const xhr = new XMLHttpRequest();
        xhr.open(method,url,true);
        Object.entries(headers).forEach(header => {
            xhr.setRequestHeader(header[0],header[1]);
        });
        xhr.onreadystatechange = () => {
            console.log('xhr=',xhr)
            if(xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                console.log(xhr.response);
            } else {
                console.log('Response: ',xhr.response);
            }
        }
        let _data = '';
        Object.entries(data).forEach(entry => {
            if(data.hasOwnProperty(entry[0])) {
                _data += `${entry[0]}=${entry[1]}&`;
            }
        });
        _data = _data.substr(-1);
        //console.log('data=',_data);
       xhr.send(_data);
    },
    /**
     * Fetch a given subscription plan information
     * @param {string} token The token string
     */
    fetchSubscriptionPlanInfo: async ({token}) => {
        console.log('[Utils.fetchSubscriptionPlanInfo]');
        token = JSON.parse(Utils.base64Decode(token));
        //console.log('token=',token)
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchSubscriptionPlanInfo',
                data: {
                    planId: token.planId,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
               StateUpdators.setPlanInfoContent({
                C: () => <CurriculumSubscriptionReviewOrder data={results.data} />
               })
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Fetch a given subscription plan information
     * @param {string} token The token string
     */
    fetchSubscriptionPlan: async ({token}) => {
        console.log('[Utils.fetchSubscriptionPlan]');
        //console.log('[Utils.fetchSubscriptionPlan]: token=',token);
        token = JSON.parse(Utils.base64Decode(token));
        const PaymentInit = PaymentInitializers[token.service]??PaymentInitializers.DefaultInitializer;
        StateUpdators.setPaymentInitHandler({Content: () => <PaymentInit />})
    },
    /**
     * Fetch subscriptionn plans
     */
    fetchCurriculumSubscriptionPlans: async () => {
        console.log('[Utils.fetchCurriculumSubscriptionPlans]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchCurriculumSubscriptionPlans',
                data: {
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
               StateUpdators.setCurriculumPlans({
                Content: () => <RenderCurriculumSubscriptions plans={results.data.plans} />
               })
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Validate a user who is registering
     * @param {object} info The info object with the challengeId and the otpCode to validate
     */
    validateRegisterAccountCode: async (info) => {
        console.log('[Utils.validateRegisterAccountCode]');
        StateUpdators.setHeader('Activating your account...');
        Utils.showProgress();
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'activaterNewUserAccount',
                data: {
                    ...info,
                    publicRequest: true
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
                StateUpdators.setHeader('Your account is ready!');
               StateUpdators.setContent({
                C: () => <OperationWasOk ExtraContent={() =>
                <>
                <p>Your account is ready, we are loging you in. Just a sec...</p>
                <LinearProgress />
                </>} />
               });
               Utils._loginActiveUser();
            } else {
                StateUpdators.setValidationPIN('')
                StateUpdators.setValidatingAction(false)
                StateUpdators.setHeader('Let\'s activate your account');
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
                StateUpdators.setValidating({
                    doing: true,
                    done: false,
                })
            }
        } catch (error) {
            StateUpdators.setValidationPIN('')
            StateUpdators.setValidatingAction(false)
            StateUpdators.setValidating({
                doing: true,
                done: false,
            })
            StateUpdators.setHeader('Let\'s activate your account');
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Login when the user has registered ana activated their account.
     */
    _loginActiveUser: async () => {
        console.log('[Utils._loginActiveUser]');
        const info = Utils.getItemFromLocalCache('registerSess');
        await Utils.authenticate({...info});
    },
    /**
     * Register a new user
     * @param {object} info The `info` object
     */
    registerNewUser: async (info) => {
        console.log('[Utils.registerNewUser]: info=',info);
        Utils.showProgress();
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'registerNewUser',
                data: {
                    ...info,
                    publicRequest: true
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
               StateUpdators.setContent({
                C: () => <RegisterNewAccountValidateOTPView challengeId={results.data.challengeId} />
               });
               Utils.saveMultipleItemsToLocalCache({key: 'registerSess', items: [
                {
                    key: 'username',
                    value: info.email
                },
                {
                    key: 'password',
                    value: info.password
                }
               ]})
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Get an item from the local cache given its `key`.
     * @param {string} key The `key` string, the key of the value to fetch
     * @return {boolean} False when a key cannot be found
     */
    getItemFromLocalCache: (key) => {
        console.log('[Utils.getItemFromLocalCache]');
        let localCache = Utils.getLocalCache();
        if(localCache) {
            return localCache[key]??false;
        }
        return false;
    },
    /**
     * Save an item (key,value pair) to the local cache.
     * @param {array} info The `info` array with the `key` as the key for the object to store, and the `key`:`value` objects as elements in the `items` array to save in the local C4KCache cache.
     * For example call is as `saveMultipleItemsToLocalCache({key: 'The Key', items: [{key: 'itemKey', value: 'ItemValue'}]}) `
     */
    saveMultipleItemsToLocalCache: (info) => {
        console.log('[Utils.saveMultipleItemsToLocalCache]');
        let localCache = Utils.getLocalCache();
        let _item = {};
        if(localCache) {
            info.items.forEach(el => {
                _item[el.key] = el.value;
            })
        } else {
            localCache = {};
            info.items.forEach(el => {
                _item[el.key] = el.value;
            })
        }
        localCache[info.key] = _item;
        localStorage.setItem('C4KCache', JSON.stringify(localCache));
    },
    /**
     * Save an item (key,value pair) to the local cache.
     * @param {object} info The `info` object with the `key` and `value` props to save in the local cache
     */
    saveItemToLocalCache: (info) => {
        console.log('[Utils.saveItemToLocalCache]');
        let localCache = Utils.getLocalCache();
        if(localCache) {
            localCache[info.key] = info.value;
        } else {
            localCache = {};
            localCache[info.key] = info.value;
        }
        localStorage.setItem('C4KCache', JSON.stringify(localCache));
    },
    /**
     * Get the local cache
     * @return {boolean} False when the local cache is not set
     */
    getLocalCache: () => {
        let localCache = localStorage.getItem('C4KCache');
        if(localCache) return JSON.parse(localCache);
        return false;
    },
    /**
     * Login to the old account and take the user there. If login is not successful, let the user login themeselves.
     */
    loginToOldAccount: async () => {
        console.log('[Utils.loginToOldAccount]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'loginToOldAccount',
                data: {
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status && results.data.oldLoginURL) {
                Utils.showFeedbackSuccess({msg: Texts.allowPops});
                Utils.goTo({url: results.data.oldLoginURL, inNewWindow: true});
            } else {
                results.data.message = Texts.errors.failedToLoginToOld;
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
                //Utils.goTo({url: Utils.colOldLogin, inNewWindow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            console.log('Error: ',error);
            Utils.handleError({message: error.message, shouldShow: true});
            //Utils.goTo({url: Utils.colOldLogin, inNewWindow: true});
        }
    },
    /**
     * Attempt to fix missing curriculums
     */
    fixMissingCurriculums: async () => {
        console.log('[Utils.fixMissingCurriculums]');
        Utils.showProgress({msg: 'Checking...'});
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fixMissingCurriculums',
                data: {
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
               if(results.data.fixed) {
                    setTimeout(() => {
                        window.location.reload();
                    },Utils.TWO_SEC);
               }
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Save the new password
     * @param {object} info The info object
     */
    saveNewPassword: async (info) => {
        console.log('[Utils.saveNewPassword]');
        //console.log('[Utils.saveNewPassword]: info=',info);
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'saveNewPassword',
                data: {
                    ...info,
                    publicRequest: true
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            if(results.data.status) {
                Utils.showFeedbackSuccess({msg: 'Success!'});
                StateUpdators.setLoginFormViewLoginPart({
                    C: () => <LoginFormViewLoginPart />
                });
            } else {
                StateUpdators.setLoginFormViewLoginPart({
                    C: () => <Forms.LoginFormResetPasswordEnterNewPassword challengeId={results.data.challengeId} />
                });
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Validate the otp code supplied by the user
     * @param {object} info The info object with the `challengeId` and the `code` to validate
     */
    validatePasswordResetCode: async (info) => {
        console.log('[Utils.validatePasswordResetCode]');
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'validatePasswordResetCode',
                data: {
                    ...info,
                    publicRequest: true
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            if(results.data.status) {
                if(results.data.status && results.data.validated) {
                    Utils.showFeedbackSuccess({msg: 'Success!'});
                    StateUpdators.setLoginFormViewLoginPart({
                        C: () => <Forms.LoginFormResetPasswordEnterNewPassword challengeId={results.data.challengeId} />
                    });
                } else {
                    StateUpdators.setLoginFormViewLoginPart({
                        C: () => <Forms.LoginFormResetPasswordOTPChallenge challengeId={results.data.challengeId} />
                    });
                    Utils.checkResultsForErrors({...results.data, shouldShow: true});
                }
            } else {
                StateUpdators.setLoginFormViewLoginPart({
                    C: () => <Forms.LoginFormResetPasswordOTPChallenge challengeId={results.data.challengeId} />
                });
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Begin password reset challenge
     * @param {object} info The info object
     */
    beginPasswordResetChallenge: async (info) => {
        console.log('[Utils.beginPasswordResetChallenge]:')
        console.log('[Utils.beginPasswordResetChallenge]: info=',info)
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'beginPasswordResetChallenge',
                data: {
                    email: info.username,
                    publicRequest: true
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            console.log('R=',results);
            Utils.hideProgress();
            StateUpdators.setLoggingIn(false);
            if(results.data.status) {
                StateUpdators.setLoginFormViewLoginPart({
                    C: () => <Forms.LoginFormResetPasswordOTPChallenge challengeId={results.data.challengeId} />
                });
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Check if accessToken is active
     */
    isActiveAccessToken: async () => {
        console.log('[Utils.isActiveAccessToken]');
    },
    /**
     * Check if a user is logged in
     */
    isLoggedIn: () => {
        console.log('[Utils.isLoggedIn]');
        const loginPrefs = Utils.getLoginPrefs();
        return typeof loginPrefs.loggedIn !== 'undefined' && loginPrefs.loggedIn;

    },
    /**
     * Check the username if it exists
     * @param {object} info The info object with the `username` prop (string)
     */
    checkUsername: async (info) => {
        console.log('[Utils.checkUsername]: info=',info);
        if(!info.username) return;
    },
    /**
     * Get C4K login preferences
     */
    getLoginPrefs: () => {
        console.log('[Utils.getLoginPrefs]');
        let c4kLoginPrefs = localStorage.getItem('C4KLoginPrefs');
        if(c4kLoginPrefs) {
            c4kLoginPrefs = JSON.parse(c4kLoginPrefs);
            return c4kLoginPrefs;
        } else {
            return {};
        }
    },
    /**
     * Remove a given C4K login preferences
     * @param {object} info The info object with the `key` prop (string) of the item to remove
     */
    removeLoginPrefsItem: (info) => {
        console.log('[Utils.removeLoginPrefsItem]');
        let c4kLoginPrefs = localStorage.getItem('C4KLoginPrefs');
        if(c4kLoginPrefs) {
            c4kLoginPrefs = JSON.parse(c4kLoginPrefs);
            if(typeof c4kLoginPrefs[info.key] !== 'undefined') {
                delete c4kLoginPrefs[info.key];
                localStorage.setItem('C4KLoginPrefs', JSON.stringify(c4kLoginPrefs));
            }
        }
    },
    /**
     * Set login preferences
     * @param {object} info The info object with the props: `key` - the key to set the value, `value` The value to set for the preference.
     */
    setLoginPrefs: (info) => {
        console.log('[Utils.setLoginPrefs]');
        let c4kLoginPrefs = localStorage.getItem('C4KLoginPrefs');
        if(c4kLoginPrefs) {
            c4kLoginPrefs = JSON.parse(c4kLoginPrefs);
            c4kLoginPrefs[info.key] = info.value;
        } else {
            c4kLoginPrefs = {};
            c4kLoginPrefs[info.key] = info.value;
        }
        localStorage.setItem('C4KLoginPrefs', JSON.stringify(c4kLoginPrefs))
    },
    /**
     * Show the login form popup
     */
    showLoginForm: () => {
        console.log('[Utils.showLoginForm]');
        Utils.closed = false;
        StateUpdators.setLoginFormDialogState(true);
    },
    /**
     * Switch images for animation
     * @param {object} info The info object
     */
    switchSectionImage: (info) => {
        console.log('[Utils.switchSectionImage]');
        const arrSrcLen = info.arraySource.length;
        info.currentIndex++;
        if(info.currentIndex >= arrSrcLen) info.currentIndex = 0;
        if(arrSrcLen > info.currentIndex) {
            info.updator(info.currentIndex);
            StateUpdators.setFadeMode('animate__fadeIn');
        }
    },
    /**
     * Send the feedback
     * @param {object} info The info object
     */
    sendFeedbackCOC: async (info) => {
        console.log('[Utils.sendFeedbackCOC]');
        Utils.showProgress();
        const fileUploaded = await Utils.uploadUserFile(Utils.fileToUpload);
        //console.log('fileUploaded=',fileUploaded);
        const curriculum = Utils.getCachedCurriculumInfo();
        if(typeof fileUploaded !== 'undefined' && fileUploaded) {
            const accessToken = Utils.getAccessToken().token;
            const payload = {
                endpoint: 'UserRequests',
                payload: {
                    action: 'sendFeedbackCOC',
                    data: {
                        ...info,
                        curriculum: curriculum,
                        handler: curriculum.extra.shortName,
                        learnerWorkAttachment: fileUploaded,
                        accessToken: accessToken
                    }
                }
            };
            //console.log('P=',payload);
            //return;
            try {
                const results = (await axios.post(Utils.api.Nervous.url,{
                    data: payload
                },{
                    headers: Utils.api.Nervous.headers
                })).data;
                //console.log('resullts=',results);
                Utils.hideProgress();
                if(results.data.status) {
                Utils.showFeedbackSuccess({msg: Texts.feedbackSent});
                Utils.hideModal();
                } else {
                    Utils.checkResultsForErrors({...results.data, shouldShow: true});
                }
            } catch (error) {
                Utils.hideProgress();
                Utils.handleError({error: 'fatalError', msg: String(error.message)})
            }
        } else {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: Texts.errorUploadingFile});
        }
    },
    /**
     * Show QuickHelpContent
     * @param {object} info The info object
     */
    showQuickHelpContentPopup: async (info) => {
        console.log('[Utils.showQuickHelpContentPopup]');
        StateUpdators.setQuickHelpPopup({
            show: true,
            info: info
        });
    },
    /**
     * Validate the PreTest code
     * @param {object} info The info object
     */
    validatePreTestCode: async (info) => {
        console.log('[Utils.validatePreTestCode]: info',info);
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'validatePreTestCode',
                data: {
                    publicRequest: true,
                    accessToken: accessToken,
                    ...info
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            if(results.data.status && results.data.validated) {
                Utils.showFeedbackSuccess({msg: 'Success!'});
                Utils.cachedUserInfoOnPretest(JSON.stringify({
                    ...results.data,
                }));
                StateUpdators.setShowQuickInfoCollectForm({show: false});
                StateUpdators.setQuizPageContent({
                    Content: () => (<ElearnerQuizApp />)
                })
            } else {
                const OTPForm = () => () => <Forms.PreTestValidateEmail candidateId={results.data.candidateId} />
                StateUpdators.setQuickInfoCollectFormContent(OTPForm);
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Go to the help page
     */
    helpPage: () => {
        console.log('[Utils.helpPage]');
        window.location = `/${BaseName.name}/help`;
    },
    /**
     * Fetch curriculums for PINS vie
     */
    fetchCurriculumsForPINSView: async () => {
        console.log('[Utils.fetchCurriculumsForPINSView]');
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchCurriculumsForPINSView',
                data: {
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
               if(results.data.curriculums.length > 0) {
                    const CurrsAvail = React.lazy(() => import('../views/tools/CurriculumsAvailable'));
                    const CurriculumsAvailable = () =>
                    <>
                    <Suspense callback={<SmallLoader />}>
                        <CurrsAvail curriculums={results.data.curriculums} />
                    </Suspense>
                    </>
                    StateUpdators.setPINSContent({
                        C: () => <CurriculumsAvailable />
                    })
                } else {
                    StateUpdators.setPINSContent({
                        C: () => <CurriculumsNotAvailable />
                    })
                    StateUpdators.setPINSContent2({C: () => <></>})
                }
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Save access country settings
     * @param {object} info The info object
     */
    saveAccessCountry: async (info) => {
        console.log('[Utils.saveAccessCountry]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'saveAccessCountrySettings',
                data: {
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
                Utils.hideModal2();
                Utils.showFeedbackSuccess({msg: Texts.successSaveAccessCtr});
                StateUpdators.setCurrentAccessCountry({
                    data: results.data,
                    C: () => <SpecialAccountHandlers.CurrentCountryView data={results.data} />
                })
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Fetch settings for a given special account
     * @param {object} info The info object with the `handler` prop (string);
     */
    fetchSpecialAccountSettingsInfo: async (info) => {
        console.log('[Utils.fetchSpecialAccountSettingsInfo]');
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchSpecialAccountSettingsInfo',
                data: {
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            if(results.data.status) {
                StateUpdators.setCurrentAccessCountry({
                    data: results.data,
                    C: () => <SpecialAccountHandlers.CurrentCountryView data={results.data} />
                })

            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Check if is special account
     * @param {object} info The info object
     */
    checkIfNewLMSSpecialAccount: async (info = {}) => {
        console.log('[Utils.checkIfNewLMSSpecialAccount]');
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'checkIfNewLMSSpecialAccount',
                data: {
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            ////console.log('R=',results);
            if(results.data.status) {
                if(results.data.isSpecialAccount) {
                    if(typeof info.resultsCallback === 'function') {
                        const SpecialAccountSettingsApp = SpecialAccountHandlers[results.data.specialAccount.configs.frontend.UIComponent]??SpecialAccountHandlers.DefaultSpecialAccountHandler;
                        info.resultsCallback({
                            C: () => <SpecialAccountSettingsApp withNoAboutContent={true} data={results.data} />
                        })
                    } else {
                        const StudentSettingsApp = SpecialAccountHandlers[results.data.specialAccount.configs.frontend.UIComponent]??SpecialAccountHandlers.DefaultSpecialAccountHandler;
                        StateUpdators.setStudentSettingsContent({
                            C: () => <StudentSettingsApp data={results.data} />
                        })
                    }
                } else {
                    StateUpdators.setStudentSettingsContent({
                        C: () =>
                        <>
                        <div className={`${Theme.content.mainPage.classes} jdhs-jfkd`}>
                            <h2>Settings</h2>
                            <p>{Texts.noSettings}</p>
                            <img alt='No settings' className='ngs-kdh rounded' src={Assets.misc.stillWorkingOnThis} />
                        </div>
                        </>
                    })
                }
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Add a student group
     * @param {object} info The info object with the `name` prop (string)
     */
    addStudentGroup: async (info) => {
        console.log('[Utils.addStudentGroup]');
        Utils.showProgress({msg: 'Please wait...'});
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'addStudentGroup',
                data: {
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            if(results.data.status) {
                Utils.showFeedbackSuccess({msg: Texts.successAddStdGrp});
                Utils.hideModal2();
                StateUpdators.setManageStudentGroupsInfo({
                    fetched: true,
                    ...results.data
                });
            } else {
                Utils.handleError({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Fetch digital badge PINS
     * @param {object} configs The info obejct
     */
    fetchDigitalPINS: async (configs) => {
        console.log('[Utils.fetchDigitalPINS]');
        Utils.showProgress({msg: 'Please wait...'});
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchDigitalBadgePINS',
                data: {
                    accessToken: accessToken,
                    curriculumId: configs.curriculumId
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            if(results.data.status) {
                configs.dataSetter({album: results.data.album, fetched: true});
            } else {
                Utils.handleError({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Fetch educator dashboard action content
     * @param {object} info The info object with the `section` prop.
     */
    fetchEducatorDashboardContent: async (info) => {
        console.log('[Utils.fetchEducatorDashboardContent]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchEducatorDashboardContent',
                data: {
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('RR=',results);
            Utils.hidePluggableLoader();
            if(results.data.status) {
                if(results.data.renderer??false) {
                    const Renderer = EducatorDashboardSectionsContentRenderers[results.data.renderer??'']??EducatorDashboardSectionsContentRenderers.DefaultRenderer;
                    StateUpdators.setEducatorDashboardActionsContent({
                        C: () => <Renderer content={results.data.content} />
                    })
                } else {
                    StateUpdators.setEducatorDashboardActionsContent({
                    C: () =>
                    <>
                    <Separator styles={{height: '40px'}} />
                    <div
                        className={`animate__animated animate__fadeIn centred jhsg-jfh dash-cont-${info.section}`}
                        dangerouslySetInnerHTML={{__html: results.data.content}}
                    >
                    </div>
                    </>
                    })
                }
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hidePluggableLoader();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },

    /**
     * Set the current
     * @param {object} info The info object
     */
    setEducatorDashboardActiveAction: (info) => {
        console.log('[Utils.setEducatorDashboardActiveAction]');
        //console.log('info=',info);
        for(const i of document.getElementsByClassName('nds-kfjdhd')) {
            i.classList.remove('active-ed-dash-action');
        }
        document.getElementById(info.itemId??'no-id').classList.add('active-ed-dash-action');
        const ActionContent = EducatorDashboardActions[info.name]??EducatorDashboardActions.StillWorkingOnthis;
        StateUpdators.setEducatorDashboardAction({
            itemId: info.itemId,
            header: `&raquo; ${info.header}`,
            Content: () => info.extraRenders ? <ActionContent extraRenders={info.extraRenders} /> : <ActionContent />
        })
    },
        /**
     * Respond to the input press
     * @param {object} info The event object
     */
    respondKeyPress: (info) => {
        console.log('[Utils.respondKeyPress]');
        if(info.e.code === 'Enter') {
            Utils.search({...info});
        }
    },
    /**
     *  Perform search stuff
     * @param {object} info The the info object
     */
    search: async (info) => {
        console.log('[Utils.search]');
        if(typeof Utils.searchFunctions[info.section] !== 'undefined') {
            StateUpdators.setSearching(true);
            Utils.searchFunctions[info.section]({searchQuery: info.e ? info.e.currentTarget.value : info.searchTerm});
        } else {
            Utils.notImplementedYetSideFeedback();
        }
    },
    /**
     * Show a message that agiven function is not implemented yet
     */
    notImplementedYetSideFeedback: () => {
        console.log('[Utils.notImplementedYetSideFeedback]');
        Utils.showSideFeedback({msg: 'This functionality is not implemented yet'});
    },
    /**
     * Saerch functions
     */
    searchFunctions: {
        /**
         * Search licensees
         * @param {object} info The info object with the search query
         */
        lessons: async (info) => {
            console.log('[Utils.searchFunctions.lessons]');
            if(info.searchQuery) {
                const accessToken = Utils.getAccessToken().token;
                const payload = {
                    endpoint: 'UserRequests',
                    payload: {
                        action: 'searchLessons',
                        data: {
                            searchTerm: info.searchQuery,
                            accessToken: accessToken
                        }
                    }
                }
                try {
                    Utils.showProgress({msg: 'Searching...'});
                    const results = (await axios.post(Utils.api.Nervous.url, {
                        data: payload
                    }, {
                        timeout: Utils.requestTimeout,
                        headers: Utils.api.Nervous.headers
                    })).data;
                    //console.log('R=',results);
                    Utils.hideProgress();
                    StateUpdators.setSearching(false);
                    if(results.data.status) {
                        const _lessons = Object.values(results.data._lessons);
                        StateUpdators.setSearchResultsFound(_lessons.length);
                        StateUpdators.setLessonsOnSearch({
                            list: _lessons,
                            searched: true,
                            searchTerm: info.searchQuery
                        })
                    } else {
                        if(results.data.error === 'notFoundSearch') {
                            StateUpdators.setSearchResultsFound(0);
                            Utils.showFeedbackError({msg: `Nothing found for '${info.searchQuery}'`});
                            StateUpdators.setLessonsOnSearch({
                                list: [],
                                searched: true,
                                searchTerm: info.searchQuery
                            })
                        } else {
                            Utils.checkResultsForErrors({...results.data, shouldShow: true});
                        }
                    }
                } catch (error) {
                    Utils.hideProgress();
                    StateUpdators.setSearching(false);
                    Utils.handleError({error: 'fatalError', msg: String(error.message)})
                }
            } else {
                Utils.showSideFeedback({msg: Texts.pleaseInput});
            }
        }
    },
    /**
     * Show the activies menu
     * @param {object} info The info object
     */
    showActivitiesMenu: (info) => {
        console.log('[Utils.showActivitiesMenu]: info=',info);
        Utils.showModal2({
            Content: () => <CurriculumExtraActivities />
        })
    },
    /**
     * Fetch students
     */
    fetchInstitutionStudents: async () => {
        console.log('[Utils.fetchStudents]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchInstitutionStudents',
                data: {
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            ////console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
                StateUpdators.setStudentsData({
                    data: results.data.students
                })
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Remove students from a selected group
     * @param {object} info The info object
     */
    removeSelectedStudentsToGroup: async (info) => {
        console.log('[Utils.removeSelectedStudentsToGroup]: info=',info);
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'removeSelectedStudentsFromGroup',
                data: {
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            ////console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
                Utils.showFeedbackSuccess({msg: Texts.successRemoveStdsFromGrp});
                StateUpdators.setManageStudentGroupsInfo({
                    fetched: true,
                    ...results.data
                });
                StateUpdators.setManageStudentGroupInput({groupId: info.groupId});
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Add students to a selected group
     * @param {object} info The info object
     */
    addSelectedStudentsToGroup: async (info) => {
        console.log('[Utils.addSelectedStudentsToGroup]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'addSelectedStudentsToGroup',
                data: {
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            ////console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
                Utils.showFeedbackSuccess({msg: Texts.successAddStdsToGrp});
                StateUpdators.setManageStudentGroupsInfo({
                    fetched: true,
                    ...results.data
                });
                StateUpdators.setManageStudentGroupInput({groupId: info.groupId});
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Fetch info to allow for managing student info
     */
    fetchManageStudentGroupsInfo: async () => {
        console.log('[Utils.fetchManageStudentGroupsInfo]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchManageStudentGroupsInfo',
                data: {
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            ////console.log('R=',results);
            if(results.data.status) {
                StateUpdators.setManageStudentGroupsInfo({
                    fetched: true,
                    ...results.data
               });
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Show a notice for the student if they are not aligned to any group
     * @param {object} token The token object
     */
    showNoStudentGroupNotice: ({token}) => {
        console.log('[Utils.showNoStudentGroupNotice]');
        const TWO_SEC = 2000;
        console.log('extra=',token);
        if(token.extra.shortName === 'DSC') {
            return setTimeout(() => {
                console.log('SHow notice...');
                Utils.showModal({
                    Content: () =>
                    <div className='jdhdh-kfjfh'>
                        <h3>You are not assigned any group/class.</h3>
                        <img alt='Warning' src={Assets.icons.error} className='mndbd-ff error-icon' />
                        <p>{Texts.noStudentGroup}</p>
                    </div>
                });
            },TWO_SEC);
        }
    },
    /**
     * Save new char traits settings
     * @param {object} info The info object
     */
    saveCharTraitsSettings: async (info) => {
        console.log('[Utils.saveCharTraitsSettings]: info=',info);
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'saveCharTraitsSettings',
                data: {
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            if(results.data.status) {
               Utils.showFeedbackSuccess({msg: Texts.successSaveCharTraitsSettings});
               Utils.hideModal2();
               StateUpdators.setSettingsPageContent({
                    C: () => <SettingsApp info={{...results.data}} />
                });
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Fetch settings
     */
    fetchSettingsInfo: async () => {
        console.log('[Utils.fetchSettingsInfo]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchUserSettingsInfo',
                data: {
                    accessToken: accessToken
                }
            }
        };
        //console.log('payload: ',payload)
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            ////console.log('R=',results);
            if(results.data.status) {
                switch(results.data.userType) {
                    case 'Student':
                        StateUpdators.setSettingsPageContent({
                            C: () => <StudentSettings data={results.data} />
                        });
                        break;

                    default:
                        StateUpdators.setSettingsPageContent({
                            C: () => <SettingsApp info={{...results.data}} />
                        });
                        break;
                }
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Take user to the settings page
     */
    settings: () => {
        window.location = `/${BaseName.name}/settings`;
    },
    /**
     * Fetch exam info
     * @param {object} info The info object
     */
    fetchExamInfo: async (info) => {
        console.log('[Utils.fetchExamInfo]: info=',info);
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchExamInfo',
                data: {
                    publicRequest: true,
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        console.log('P=',payload);
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
               //do staff with the results
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Capitalize the element props
     * @param {object} info The info object
     */
    capitalizeElementProps: (info = {}) => {
        console.log('[Utils.capitalizeElementProps]');
        const newInfo = {};
        Object.entries(info).forEach(entry => {
            newInfo[`${entry[0][0].toLocaleUpperCase()}${entry[0].substr(1,entry[0].length)}`] = entry[1];
        });
        return newInfo;
    },
    /**
     * Save new user info
     * @param {object} info The info object
     */
    saveUserAccountInfo: async (info) => {
        console.log('[Utils.saveUserAccountInfo]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'updateUserInfo',
                data: {
                    newUserInfo: {...Utils.capitalizeElementProps(info), Gender: info.gender.name},
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            if(results.data.status) {
               Utils.hideModal2();
               Utils.showFeedbackSuccess({msg: Texts.successUpdateUsrInfo});
               StateUpdators.setUserProfileInfo({
                ...Utils.getCachedUserProfile(),
                ...info,
                gender: info.gender.name
               })
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Show user notifications
     */
    showUserNotifications: async () => {
        Utils.showModal2({
            Content: () => <Notifications />
        });
    },
    /**
     * Show the update basic info form
     * @param {objct} info The info object
     */
    showUpdateBasicInfoForm: async (info = {}) => {
        console.log('[Utils.showUpdateBasicInfoForm]');
        Utils.showModal2({
            Content: () => <UpdateBasicInfoForm info={{...info}} />
        })
    },
    /**
     * Fetch user info, full info
     */
    fetchUserInfo: async (info = {}) => {
        console.log('[Utils.fetchUserInfo]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchUserInfo',
                data: {
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            if(results.data.status) {
                Utils.cacheUserProfile(results.data);
               StateUpdators.setUserProfileInfo({
                    ...results.data,
                    userType: results.data.user,
                    iconTitle: results.data.userAvatar?`${results.data.firstname} ${results.data.lastname}`:'Avater not available',
                    icon: results.data.userAvatar?results.data.userAvatar:Assets.icons.avatarPlaceHolder
                });
                if(info.completeAccount??false) {
                    Utils.showUpdateBasicInfoForm({...info});
                }
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Cache user profile info
     * @param {object} info The info obejct with the user info
     */
    cacheUserProfile: (info = {}) => {
        console.log('[Utils.cacheUserProfile]');
        localStorage.setItem('C4KUserProfile',JSON.stringify(info));
    },
    /**
     * Gate the cached user profile info
     * @param {object} info The info obejct with the user info
     * @return {object|boolean} The profile info object else false
     */
    getCachedUserProfile: (info = {}) => {
        console.log('[Utils.cacheUserProfile]');
        const profile = localStorage.getItem('C4KUserProfile');
        if(profile) {
            return JSON.parse(profile);
        }
        return false;
    },
    /**
     * Take the usrer to their account
     */
    myAccount: () => {
        console.log('[Utils.myAccount]');
        window.location = `/${BaseName.name}/me`;
    },
    /**
     * Log user out
     */
    logout: () => {
        console.log('[Utils.logout]');
        window.location = `/${BaseName.name}/logout`;
    },
    /**
     * Take user to the digital badges
     */
    digitalBadges: () => {
        console.log('[Utils.digitalBadges]');
        window.location = `/${BaseName.name}/dbs`;
    },
    /**
     * Handle the account menu click
     * @param {object} e The event object
     */
    handleMenuClick: (e) => {
        console.log('[Utils.handleMenuClick]: e=',e);
    },
    /**
     * Fetch tech talk content
     * @param {object} info The info object
     */
    fetchTeckTalk: async (info) => {
        console.log('[Utils.fetchTeckTalk]');
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchTechTalk',
                data: {
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            if(results.data.status) {
                if(Object.keys(results.data.definitions).length < 1) {
                    StateUpdators.setTechTalkContent({
                        C: () => <NoTechTalkContentYet />
                    });
                } else {
                    StateUpdators.setTechTalkContent({
                        C: () => <TechTalkContent info={results.data}/>
                    });
                }
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Send the feedback
     * @param {object} info The info object
     */
    sendFeedback: async (info) => {
        console.log('[Utils.sendFeedback]');
        Utils.showProgress();
        const fileUploaded = await Utils.uploadUserFile(Utils.fileToUpload);
        const curriculum = Utils.getCachedCurriculumInfo();
        if(typeof fileUploaded !== 'undefined' && fileUploaded) {
            const accessToken = Utils.getAccessToken().token;
            const payload = {
                endpoint: 'UserRequests',
                payload: {
                    action: 'sendParentFeedback',
                    data: {
                        ...info,
                        curriculum: curriculum,
                        handler: curriculum.extra.shortName,
                        learnerWorkAttachment: fileUploaded,
                        accessToken: accessToken
                    }
                }
            };
            console.log('P=',payload);
            //return;
            try {
                const results = (await axios.post(Utils.api.Nervous.url,{
                    data: payload
                },{
                    headers: Utils.api.Nervous.headers
                })).data;
                //console.log('resullts=',results);
                Utils.hideProgress();
                if(results.data.status) {
                Utils.showFeedbackSuccess({msg: Texts.feedbackSent});
                Utils.hideModal();
                } else {
                    Utils.checkResultsForErrors({...results.data, shouldShow: true});
                }
            } catch (error) {
                Utils.hideProgress();
                Utils.handleError({error: 'fatalError', msg: String(error.message)})
            }
        } else {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: Texts.errorUploadingFile});
        }
    },
    /**
     * Fetch the digital badges album
     */
    fetchDigitalBadgesAlbum: async () => {
        console.log('[Utils.fetchDigitalBadgesAlbum]');
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchDigitalBadgesAlbum',
                data: {
                    curriculumId: Utils.getCachedCurriculumInfo().id,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            if(results.data.status) {
               StateUpdators.setDBAlbumContent({
                    C: () => <DisplayDigitalBadgeAlbum data={results.data} />
                })
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * View all ICDL categories
     */
    showAllICDLCategories: () => {
        console.log('[Utils.showAllICDLCategories]');
        //Utils.hideModal();
        Utils.showFullscreenModal({
            Content: () => <AllICDLCategories />
        });
    },
    /**
     * Show DSC progress in ICDL category completion
     * @param {object} info The info oobject
     */
    showDSCCatsProgress: (info) => {
        console.log('[Utils.showDSCCatsProgress]');
        Utils.showModal({
            Content: () => <ICDLCategories info={info} />
        })
    },
    /**
     * Show the lessons menu
     * @param {object} info The info object
     */
    showLessonsNavMenu: (info) => {
        console.log('[Utils.showLessonsNavMenu]');
        const cacheCurriculumInfo = Utils.getCachedCurriculumInfo();
        const regex = new RegExp(`${BaseName.name}/lesson`);
        if(regex.test(window.location.href)) {
            const token = Utils.getCourseToken();
            if(token) {
                window.location = `/${BaseName.name}/course/${token}`;
            }
        } else {
            window.location = `/${BaseName.name}/curriculum/${cacheCurriculumInfo.id}`;
        }
    },
    /**
     * Close the closeLessonTaskPageModal
     */
    closeLessonTaskPageModal: () => {
        console.log('[Utils.closeLessonTaskPageModal]');
        console.log('[Utils.miniMize]');
        StateUpdators.setLessonTaskPageModal({
            show: false,
            Content: () => <></>,
            exitMode: 'animate__zoomOut'
        });
        Utils.hideMaximizeView();
    },
    /**
     * On close, close the minimise window
     */
    hideMaximizeView: () => {
        console.log('[Utils.hideMaximizeView]');
        StateUpdators.setMinimizedView({
            show: false,
            title: ''
        });
    },
    /**
     * Minimixe a view
     * @param {object} info The info object
     */
    miniMize: (info) => {
        console.log('[Utils.miniMize]');
        StateUpdators.setLessonTaskPageModal({
            show: false,
            Content: () => <></>,
            exitMode: 'animate__zoomOut'
        });
        StateUpdators.setMinimizedView({
            show: true,
            title: info.title
        });
    },
    /**
     * Show a zoomed-in image of the coutcome image
     * @param {object} info The info object
     */
    showZoomedLessonOutcome: (info) => {
        console.log('[Utils.showZoomedLessonOutcome]');
        Utils.showModal({
            Content: () => (
                <>
                <div className='gdhf-jghgh-image'>
                    <h3>{info.title}</h3>
                    <CustomImage className={'hsgs-hfgfg ptr'} src={info.image} title={info.title} />
                </div>
                </>
            )
        })
    },
    /**
     * Validate a given digital badge
     * @param {object} info The info object
     */
    validateDigitalBadge: async (info) => {
        console.log('[Utils.validateDigitalBadge]: info=',info);
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'activateDGCDigitalBadge',
                data: {
                    courseId: info.courseId,
                    lessonId: info.lessonId,
                    badgeId: info.badgeId,
                    pin: parseInt(info.pin),
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            if(results.data.status) {
               Utils.showFeedbackSuccess({msg: Texts.successActivatePIN});
               StateUpdators.setActivePINStatus(true);
               StateUpdators.setActivePINStatus2(true);
               StateUpdators.setValidating({
                    doing: false,
                    done: true
                });
                StateUpdators.setValidationResults({
                    C: () => (
                    <>
                    <SuccessActivatingDGCDigitalBadge info={results.data} />
                    </>)
                });
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: false});
                StateUpdators.setValidating({
                    doing: false,
                    done: true
                });
                StateUpdators.setValidationResults({
                    C: () => (
                    <>
                    <ErrorActivatingDGCDigitalBadge info={results.data} />
                    <Button variant='contained' onClick={() => {
                        StateUpdators.setPIN('');
                        StateUpdators.setValidating({doing: true, done: false, retry: true});
                    }}>
                        Try Again
                    </Button>
                    </>)
                });
            }
        } catch (error) {
            Utils.handleError({error: 'fatalError', msg: String(error.message)});
        }
    },
    /**
     * Fetch current lesson digital badge for a given student
     * @param {object} info The info object
     */
    fetchDiditalBadge: async (info) => {
        console.log('[Utils.fetchDiditalBadge]');
        if(info.fetched??false) return;
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchDGCDigitalBadge',
                data: {
                    lessonId: info.lessonId,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('info=',info)
            //console.log('r=',results)
            if(results.data.status) {
                StateUpdators.setLessonsCompleted(results.data.ICDLProgress.lessonsCompleted);
                StateUpdators.setDigitalBadgeC({
                    C: () => <DisplayDigitalBadge info={results.data} />
                });
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: false});
                StateUpdators.setDigitalBadgeC({
                    C: () => <ErrorFetchingDigitalBadge />
                });
            }
        } catch (error) {
            Utils.handleError({error: 'fatalError', msg: String(error.message)});
            StateUpdators.setDigitalBadgeC({
                C: () => <ErrorFetchingDigitalBadge />
            });
        }
    },
    /**
     * Set the default current viewing guide docs
     */
    setDefaultViewingUserGuideDocs: () => {
        console.log('[Utils.setDefaultViewingUserGuideDocs]');
        document.getElementsByClassName('first-title-0')[0].classList.add('active-docs-vdudhdgf');
    },
    /**
     * Remove active viewing left indicator
     */
    removeActiveLeftDocsViewing: () => {
        for(const el of document.getElementsByClassName('active-docs-vdudhdgf')) {
            el.classList.remove('active-docs-vdudhdgf');
        }
    },
    /**
     * Return `dev_mode` if in dev mode (on local host and on .../ns_demo deployment) other wise return `prod_mode`.
     * @return {string} devMode Either dev_mode or prod_mode based on environment
     */
    devMode: () => {
        return Utils._devMode ? 'dev_mode' : 'prod_mode'
    },
    /**
     * Add analytics
     * @param {object} info The info object
     */
    addAnalytics: async(info) => {
        console.log('[Utils.addAnalytics]');
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'Analytics',
            payload: {
                action: 'nsAdd',
                data: {
                    ...info,
                    devMode: Utils.devMode(),
                    accessToken: accessToken
                }
            }
        };
        try {
            await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })
        } catch (error) {
            console.log('Error: ',error);
        }
    },
    /**
     * Fetch user guides/docs
     * @param {object} info The info object
     */
    fetchGuideDocs: async (info) => {
        console.log('[Utils.fetchGuideDocs]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchGuideDocs',
                data: {
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            if(results.data.status) {
               StateUpdators.setGuidesContent({
                Content: () => (<UserGuidesView data={results.data} />)
               })
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Fetch curriculum catalog. For user
     */
    fetchCurriculums: async () => {
        console.log('[Utils.fetchCurriculums]');
        Utils.showProgress();
        const accessToken = Utils.getAccessToken().token;
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchCurriculums',
                data: {
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            //console.log('R=',results);
            if(results.data.status) {
                if((typeof results.data.hasAccess !== 'undefined') && !results.data.hasAccess) {
                    window.location = `/${BaseName.name}/access_closed`
                } else {
                    StateUpdators.setCurriculum({
                        Content: () => (<RenderCurriculums content={results.data} />)
                    })
                }
            } else {
                if(results.data.error && results.data.error === 'noSubscriptions') {
                    StateUpdators.setCurriculum({
                        Content: () => (<NoCurriculums />)
                    })
                } else {
                    Utils.checkResultsForErrors({...results.data, shouldShow: true});
                }
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message||error.msg)})
        }
    },
    /**
     * Get the current curriculum id
     */
    getCurrentCurriculum: () => {
        console.log('[Utils.getCurrentCurriculum]');
        try {
           return JSON.parse(localStorage.getItem('curriculum'));
        } catch (error) {
            Utils.handleError({error: 'fatalError', msg: String(error.message)});
        }
    },
    /**
     * Get the current lesson being accessed's URL. It is stored on the localStorage
     * @return {string} The current lesson URL
     */
    getCurrentLessonURL: () => {
        console.log('[Utils.getCurrentLessonURL]');
        const currLessURL = localStorage.getItem('currentLessonURL');
        if (currLessURL) return currLessURL;
        return '';
    },
    /**
     * Clear the on window event
     */
    clearOnWindowCloseEvent: () => {
        console.log('[Utils.clearOnWindowCloseEvent]');
        window.onbeforeunload = null;
    },
    /**
     * Ask the user before they close the window
     */
    setOnWindowCloseEvent: () => {
        console.log('[Utils.setOnWindowCloseEvent]');
        window.onbeforeunload = (e) => {
            e.preventDefault();
            return 'If you close or reload this window, all your answers will be lost. Are you sure you want to close/reload this page?';
        }
    },
    /**
     * Cache the lesson title
     * @param {object} info The info object
     */
    cacheLesson: (info) => {
        console.log('[Utils.cacheLesson]');
        localStorage.setItem('lesson',JSON.stringify({title: info.title}));
        localStorage.setItem('currentLessonURL',window.location.href);
    },
    /**
     * Cache the course title
     * @param {object} info The info object
     */
    cacheCourse: (info) => {
        console.log('[Utils.cacheCourse]');
        localStorage.setItem('course',JSON.stringify({title: info.title}));
        if(info.token) {
            Utils.storeCourseToken({token: info.token});
        }
    },
    /**
     * Store the course token so we can navigate to the course's lesson listing
     * @param {object} token The token object
     */
    storeCourseToken: ({token}) => {
        console.log('[Utils.storeCourseToken]');
        localStorage.setItem('c4kCourseToken',JSON.stringify(token));
    },
    /**
     * Store the course token so we can navigate to the course's lesson listing
     * @return {string|boolean} The The token string or false when not available
     */
    getCourseToken: () => {
        console.log('[Utils.getCourseToken]');
        let token = localStorage.getItem('c4kCourseToken');
        if(token) {
            return Utils.base64Encode(token);
        }
        return false;
    },
    /**
     * Save candidate info
     * @param {object} info The info object
     */
    saveCandidateInfo: async (info) => {
        console.log('[Utils.saveCandidateInfo]');
        const accessToken = Utils.getAccessToken().token;
        Utils.showProgress();
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'saveCandidateInfo',
                data: {
                    ...info,
                    accessToken: accessToken
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            if(results.data.status) {
                Utils.showFeedbackSuccess({msg: Texts.successSaveCandInfo});
                Utils.hideModal();
                Utils.fetchCandidateDetails({accessToken: accessToken});
            } else {
                StateUpdators.setSaveButtonState({disabled: false});
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            StateUpdators.setSaveButtonState({disabled: false});
            Utils.hideProgress();
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Upload a user profile picture
     * @param {object} info The info object
     */
    uploadUserProfilePicture: async (info) => {
        console.log('[Utils.uploadUserProfilePicture]');
        if(info.file.size) {
            const image  = new Image();
            image.onload = () => {
                if(image.height > Utils.MAX_IMAGE_HEIGHT) {
                    Utils.showFeedbackError({msg: Texts.fileTooBig});
                    return;
                }
                if(image.height < Utils.MIN_IMAGE_UPLOAD) {
                    Utils.showFeedbackError({msg: Texts.fileTooSmall});
                    return;
                }
                Utils._uploadUserProfilePicture(info);
            }
            image.src = URL.createObjectURL(info.file);
        } else {
            Utils.showFeedbackError({msg: Texts.invalidFile});
        }
    },
    /**
     *  Upload a user profile picture
     * @param {object} info The info object
     */
    _uploadUserProfilePicture: async (info) => {
        console.log('[Utils._uploadUserProfilePicture]');
        StateUpdators.setButtonLoading(true);
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'uploadUserProfilePicture',
                data: {
                    ...info,
                    accessToken: Utils.getAccessToken().token
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            if(results.data.status) {
                Utils.showFeedbackSuccess({msg: results.data.message});
                Utils.fetchCandidateDetails({accessToken: Utils.getAccessToken().token});
                Utils.hideModal2();
            } else {
                StateUpdators.setButtonLoading(false);
                StateUpdators.setInputFile({
                _preViewing: false,
                src: null
                })
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            StateUpdators.setButtonLoading(false);
            Utils.handleError({error: 'fatalError', msg: String(error.message)})
        }
    },
    /**
     * Handle upload of images to the server for th4 C4K DMS.
     * @param {object} file The file to upload
     */
    uploadUserFile: (file) => {
        console.log('[Utils.uploadUserFile]');
        file = file.file;
        //console.log('__file__',file);
        return new Promise((resolve,reject) => {
            const xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            xhr.open('POST', `${Utils.api.mainURL}/upload_user_files.php`);
            xhr.upload.onprogress = (e) => {
                //progress(e.loaded/e.total*100);
                //console.log('Progress: ',(e.loaded/e.total*100));
                StateUpdators.setUploading({
                    state: true,
                    progress: (e.loaded/e.total*100)
                })
            }
            xhr.onload = () => {
                if(xhr.status === 403) {
                    reject({message: 'HTTP Error: ' + xhr.status, remove: true });
                    return;
                }
                if(xhr.status < 200 || xhr.status >= 300) {
                    reject({message: 'HTTP Error: ' + xhr.status});
                    return;
                }
                const json = JSON.parse(xhr.responseText);
                if(!json || typeof json.location != 'string') {
                    reject({message: 'Invalid JSON: ' + xhr.responseText});
                    return;
                }
                resolve(json.location);
            }
            xhr.onerror = () => {
                reject({message: 'Image upload failed due to a XHR Transport error. Code: ' + xhr.status});
            }
            const formData = new FormData();
            formData.append('file', file, file.name);
            //console.log('FormData: ',formData);
            xhr.send(formData);
        });
    },
    /**
     * Read a selected file for upload
     * @param {object|bool} file The file object. False if not a file
     * @param {fn} fileStateUpdator The file state updator for the file
     * @returns
     */
    readSelectedFile: async ({file,fileStateUpdator}) => {
        console.log('Utils.readSelectedFile');
        if(!file) return false;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            fileStateUpdator({
                _preViewing: true,
                src: e.target.result,
                file: file
            });
        }
    },
    /**
     * Fetch user details
     * @param {object} info The info object
     */
    fetchCandidateDetails: async (info) => {
        console.log('[Utils.fetchCandidateDetails]');
        Utils.showProgress();
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchCandidateDetails',
                data: {
                    ...info
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            if(results.data.status) {
                const userAvatar = results.data.userAvatar ? results.data.userAvatar : Assets.icons.userAvatar;
                if(!results.data.completeProfile) {
                    Utils.showModal({Content: () => (<StudentCompleteProfileForm shouldUpdateAvatar={!results.data.userAvatar} userAvatar={userAvatar} />)});
                } else {
                    StateUpdators.setCandidateDetails({
                        ...results.data,
                        avatar: userAvatar,
                        avatarTitle: results.data.userAvatar ? results.data.firstname : 'User Icon Not Available'
                    });
                    StateUpdators.setCanShowQuiz(true);
                }
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            Utils.hideProgress();
            console.log('error: ',error);
        }
    },
    /**
     * Show a modal with content
     * @param {object} info The info object with the Content prop, which a React component to render in the modal
     */
    showModal: (info) => {
        console.log('[Utils.showModal]');
        StateUpdators.setActionModal({
            show: true,
            Content: () => (<info.Content />)
        });
    },
    /**
     * Show a full screen modal with content
     * @param {object} info The info object with the Content prop, which a React component to render in the modal
     */
    showLessonTaskPageModal: (info) => {
        console.log('[Utils.showModal]');
        StateUpdators.setLessonTaskPageModal({
            onClose: () => {
                if(info.onClose) {
                    info.onClose();
                }
            },
            show: true,
            Content: () => (<info.Content />)
        });
    },
    /**
     * Show a full screen modal with content
     * @param {object} info The info object with the Content prop, which a React component to render in the modal
     */
    showFullscreenModal: (info) => {
        console.log('[Utils.showModal]: info=',info);
        StateUpdators.setFullscreenModal({
            onClose: () => {
                if(info.onClose) {
                    info.onClose();
                }
            },
            show: true,
            Content: () => (<info.Content />)
        });
    },
    /**
     * Show a modal with content, ontop of the current modal
     * @param {object} info The `info` object with the `Content` prop, which is a React component to render in the modal
     */
    showModal2: (info) => {
        console.log('[Utils.showModal]');
        StateUpdators.setActionModal2({
            show: true,
            Content: () => (<info.Content />)
        });
    },
    /**
     * Close modal 2
     */
    hideModal2: () => {
        console.log('[Utils.closeModal2]');
        StateUpdators.setActionModal2({
            show: false,
            Content: () => (<></>)
        });
    },
    /**
     * Close the full screen modal
     */
    hideFullScreenModal: () => {
        console.log('[Utils.hideFullScreenModal]');
        StateUpdators.setFullscreenModal({
            show: false,
            Content: () => (<></>)
        });
    },
    /**
     * Go to course lessons listing
     * @param {object} info The info object
     */
    goToCourseLessons: (info) => {
        console.log('[Utils.goToCourseLessons]');
        if(info.course.subCourseTitle??false) {
            Utils.goToCourse({course: {
                    courseId: info.token.courseId,
                    categoryId: undefined,
                    hasSubSubCourses: undefined,
                    courseTitle: info.token.courseTitle,
                },
                curriculum: info.token
            });
        }
    },
    /**
     * Go to curriculum courses
     */
    goToCurriculumCourses: () => {
        window.location = `/${BaseName.name}/curriculum/${Utils.getCachedCurriculumInfo().id}`;
    },
    /**
     * Close modal
     */
    hideModal: () => {
        console.log('[Utils.closeModal2]');
        StateUpdators.setActionModal({
            show: false,
            Content: () => (<></>)
        });
    },
    /**
     * Cache curriculum info
     * @param {object} info The info object to save/cache
     */
    cacheCurriculumInfo: (info) => {
        console.log('[Utils.cacheCurriculumInfo]');
        localStorage.setItem('curriculum', JSON.stringify(info));
    },
    /**
     * Cache current lesson info
     * @param {object} info The info object to save/cache
     */
    cacheLessonInfo: (info) => {
        console.log('[Utils.cacheLessonInfo]');
        localStorage.setItem('lessonInfo', JSON.stringify(info));
    },
    /**
     * Get cached curriculum info
     */
    getCachedCurriculumInfo: () => {
        console.log('[Utils.getCachedCurriculumInfo]');
        let curriculum = localStorage.getItem('curriculum');
        if(curriculum) {
            curriculum = JSON.parse(curriculum);
            let course = localStorage.getItem('course');
            if(course) {
                course = JSON.parse(course);
                curriculum.course = course.title;
            }
            let lesson = localStorage.getItem('lesson');
            if(lesson) {
                lesson = JSON.parse(lesson);
                curriculum.lesson = lesson.title;
            }
            let lessonInfo = localStorage.getItem('lessonInfo');
            if(lessonInfo) {
                lessonInfo = JSON.parse(lessonInfo);
                curriculum.lessonInfo = lessonInfo;
            }
            return curriculum;
        }
        return false;
    },
    /**
     * Fetch student achievements progress data
     * @param {object} info The info object
     */
    fetchProgressData: async (info) => {
        console.log('[Utils.fetchProgressData]');
        //console.log('info=',info)
        try {
            Utils.showProgress();
            let results = (await axios.post(Utils.api.Nervous.url, { data: {
                endpoint: 'UserRequests',
                payload: {
                    action: 'fetchProgressData',
                    data: {
                        accessToken: Utils.getAccessToken().token,
                        ...info
                    }
                }
            } }, { headers: Utils.api.Nervous.headers })).data;
            Utils.hideProgress();
            console.log('results: ',results);
            if(results.data.status) {
               StateUpdators.setStudentProgressData({
                hasData: results.data.hasData,
                data: results.data
               });
            } else {
               Utils.checkResultsForErrors(results.data);
            }
        } catch (error) {
            Utils.handleError(error);
        }
    },
    /**
     * Clear the quiz session so that a different user can take the quiz on the same pc
     */
    clearQuizSessionData: () => {
        console.log('[clearQuizSessionData]');
        localStorage.removeItem('C4KPreTestCache');
        StateUpdators.setSessionCleared(true);
    },
    checkPastResults: async () => {
        console.log('[Utils.checkPastResults]');
        const userInfo = Utils.getCachedUserInfoOnPretest();
        if(userInfo) {
            try {
                let results = (await axios.post(Utils.api.Nervous.url, { data: {
                    endpoint: 'UserRequests',
                    payload: {
                        action: 'checkPreTestPastResults',
                        data: {
                            publicRequest: true,
                            candidateId: userInfo.candidateId
                        }
                    }
                } }, { headers: Utils.api.Nervous.headers })).data;
                if(results.data.status) {
                    StateUpdators.setViewPastResultsButton(true);
                }
            } catch (error) {
                console.log('Error: ',error.message);
            }
        }
    },
    /**
     * Quiz submitters
     */
    quizSubmitters: {
        LessonQuiz: {
            /**
             * Submit the lesson quiz for marking
             * @param {object} info The info object
             */
            submit: async (info) => {
                console.log('[Utils.quizSubmitters.LessonQuiz.submit]');
                const timeTaken = parseFloat((Date.now()-info.startTimestamp)/(1000*60)).toFixed(2);
                const payload = {
                    endpoint: 'UserRequests',
                    payload: {
                        action: 'markLessonQuiz',
                        data: {
                            ...info,
                            timeTaken: timeTaken,
                            accessToken: Utils.getAccessToken().token
                        }
                    }
                };
                Utils.showProgress();
                try {
                    let results = (await axios.post(Utils.api.Nervous.url, { data: payload }, { headers: Utils.api.Nervous.headers })).data;
                    Utils.hideProgress();
                    if(results.data.status) {
                        StateUpdators.setQuizPageContent({
                            Content: () => <LessonQuizResults results={results.data} />
                        });
                    } else {
                        Utils.checkResultsForErrors({...results.data});
                    }
                } catch (error) {
                    Utils.hideProgress();
                    Utils.handleError(error);
                }
            },
        },
        ELearnerPretestQuiz: {
            /**
             * Submit the PreTest quiz
             * @param {object} info The info object
             */
            submit: async (info) => {
                console.log('[Utils.quizSubmitters.ELearnerPretestQuiz.submit]');
                const timeTaken = parseFloat((Date.now()-info.startTimestamp)/(1000*60)).toFixed(2);
                Utils.showProgress();
                const userInfo = Utils.getCachedUserInfoOnPretest();
                try {
                    let results = (await axios.post(Utils.api.Nervous.url, { data: {
                        endpoint: 'UserRequests',
                        payload: {
                            action: 'markPretTestQuiz',
                            data: {
                                publicRequest: true,
                                ...info,
                                timeTaken: timeTaken,
                                candidateId: userInfo.candidateId
                            }
                        }
                    } }, { headers: Utils.api.Nervous.headers })).data;
                    Utils.hideProgress();
                    if(results.data.status) {
                        StateUpdators.setQuizPageContent({
                            Content: () => <ElearnerQuizResults results={results.data} />
                        });
                    } else {
                        Utils.checkResultsForErrors({...results.data});
                    }
                } catch (error) {
                    Utils.hideProgress();
                    Utils.handleError(error);
                }
            },
        }
    },
    /**
     * Submit quizz paper for marking
     * @param {object} info The info object
     */
    submitQuizForMarking: async (info) => {
        console.log('[Utils.submitQuizForMarking]');
        Utils.quizSubmitters[info.quizType].submit(info);
    },
    /**
     * Returs current year in YYYY
     * @return {string} The date
     */
    getYear: () => {
        console.log('[Utils.getYear]');
        return new Date().getFullYear();
    },
    /**
     * Returs today's date in the format: YYYY-MM-DD
     * @return {string} The date
     */
    getDate: () => {
        console.log('[Utils.getDate]');
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return `${year}-${month}-${day}`;
    },
    /**
     * Returs current time in the format: hh-mm-ss
     * @return {string} The time
     */
    getTime: () => {
        console.log('[Utils.getTime]');
        return Date().split(' ')[4];
    },
    /**
    * Retuns time stamp in the format yyyy-MM-dd hh:mm:ss
    * @return {string} The timestamp
    */
    getTimeStamp: () => {
        console.log('[Utils.getTimeStamp]');
        return `${Utils.getDate()} ${Utils.getTime()}`;
    },
    /**
     * Pain an answered question
     * @param {object} info The info object
     */
    paintAnsweredQuestion: (info) => {
        console.log('[Utils.paintAnsweredQuestion]');
        const qMarker = document.getElementsByClassName(`answ-${info.q}`)[0];
        qMarker.classList.add('qs-numsh-nfhf-filled');
        qMarker.title = 'Answered (Click to go to question)';
    },
    /**
     * Scroll to agiven question
     * @param {object} info The info object with the question number as a prop q
     */
    scrollToQuestion: (info) => {
        document.getElementById(`q-s-${info.q}`).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    },
    /**
     * Determine the receommended certification path based on pre test results/score
     * @param {object} info The info object with the score
     */
    getCertRecommendation: (info) => {
        console.log('[Utils.getCertRecommendation]');
        if(info.score >= 80) return 'ICDL Certification';
        return 'e-Learner Certification';
    },
    setTrackQuestionsClickableOnMobileDevices: () => {
        if(window.screen.width <= 414) {
            StateUpdators.setScrollFunctionsOnMobileDevices({
                onClick: () => {
                    const questionNums = document.getElementsByClassName('jdgf-jsgdkfhd')[0];
                    if(questionNums.style.display === 'block') {
                        questionNums.style.display = 'none';
                    } else {
                        questionNums.style.display = 'block';
                    }
                }
            })
        }
    },
    /**
     * Fetch quiz questions
     * @param {object} info The info object
     */
    fetchQuizQuestions: (info) => {
        console.log('[Utils.fetchQuizQuestions]');
        Utils.quizTypes[info.quizType].fetch(info);
    },
    /**
     * Fetch quiz questions for different quiz types
     */
    quizTypes: {
        LessonQuiz: {
            /**
             * Fetch the Lesson Quiz questions
             * @param {object} info The info object
             */
            fetch: async (info) => {
                console.log('[Utils.quizTypes.LessonQuiz.fetch]');
                const accessToken = Utils.getAccessToken().token;
                const payload = {
                    endpoint: 'UserRequests',
                    payload: {
                        action: 'fetchLessonQuizQuestions',
                        data: {
                            courseId: info.token.courseId,
                            lessonId: info.token.lessonId,
                            accessToken: accessToken
                        }
                    }
                };
                try {
                    const results = (await axios.post(Utils.api.Nervous.url,{
                        data: payload
                    },{
                        headers: Utils.api.Nervous.headers
                    })).data;
                    if(results.data.status) {
                        StateUpdators.setActiveQuizInfo({
                            questions: results.data.questions,
                            quizTitle: results.data.quizTitle,
                            duration: results.data.duration
                        });
                    } else {
                        StateUpdators.setActiveQuizInfo({
                            ...results.data,
                            questions: [],
                            quizTitle: '',
                            duration: '',
                            error: results.data.message,
                            _error: results.data.error
                        });
                        if((['lessonAlreadyComplete','alreadyTakenPretest'].indexOf(results.data.error) < 0)) {
                            Utils.checkResultsForErrors({...results.data});
                        }
                    }
                } catch (error) {
                    Utils.handleError({error: 'fatalError', msg: String(error.message)})
                }
            }
        },
        /**
         * The PreTest quiz
         */
        ELearnerPretestQuiz: {
            /**
             * Fetch questions for the pretest
             * @param {object} info The info object
             */
            fetch: async (info) => {
                console.log('[Utils.quizTypes.ELearnerPretestQuiz.fetch]');
                const userInfo = Utils.getCachedUserInfoOnPretest();
                try {
                    let results = (await axios.post(Utils.api.Nervous.url, { data: {
                        endpoint: 'UserRequests',
                        payload: {
                            action: 'fetchQuizQuestions',
                            data: {
                                publicRequest: true,
                                ...info,
                                candidateId: userInfo.candidateId
                            }
                        }
                    } }, { headers: Utils.api.Nervous.headers })).data;
                    if(results.data.status) {
                    StateUpdators.setActiveQuizInfo({
                        questions: results.data.questions,
                        quizTitle: results.data.quizTitle,
                        duration: results.data.duration
                    });
                    } else {
                        StateUpdators.setActiveQuizInfo({
                            ...results.data,
                            questions: [],
                            quizTitle: '',
                            duration: '',
                            error: results.data.message
                        });
                        if(!(results.data.error === 'alreadyTakenPretest')) {
                            Utils.checkResultsForErrors({...results.data});
                        }
                    }
                } catch (error) {
                    Utils.handleError(error);
                }
            }
        }
    },
    /**
     * Initialize the pretest test
     * @param {object} info The info object
     */
    initiatePreTest: async (info) => {
        console.log('[Utils.initiatePreTest]');
        Utils.showProgress();
        try {
            let results = (await axios.post(Utils.api.Nervous.url, { data: {
                endpoint: 'UserRequests',
                payload: {
                    action: 'initiateELPretest',
                    data: {
                        publicRequest: true,
                        ...info,
                        email: info.email.trim().toLocaleLowerCase()
                    }
                }
            } }, { headers: Utils.api.Nervous.headers })).data;
            Utils.hideProgress();
            if(results.data.status && results.data.challengeStarted) {
                const OTPForm = () => () => <Forms.PreTestValidateEmail candidateId={results.data.candidateId} />
                StateUpdators.setQuickInfoCollectFormContent(OTPForm);
            } else {
                Utils.checkResultsForErrors(results.data);
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError(error);
        }
    },
    /**
     * Set the document/page title. A full new title
     * @param {object} info The info object
     */
    setPageTitleFull: (info) => {
        document.title = info.title;
    },
    /**
     * Save the candiate info local storage.
     * @param {string} info The access info
     */
    cachedUserInfoOnPretest: (info) => {
        localStorage.setItem('C4KPreTestCache',JSON.stringify({info:info}));
    },
    /**
     * Fetch about quiz content
     */
    fetchAboutC4KQuizes: async () => {
        console.log('[Utils.fetchAboutC4KQuizes]');
        StateUpdators.setAboutContent({
            text: [
                `<h1>Welcome to Computers 4 Kids' Certification Centre</h1>`
            ]
        })
    },
    /**
     * Login user
     * @param {object} info The info object
     */
    loginUser: async (info) => {
        console.log('[Utils.loginUser]');
        //console.log('[Utils.loginUser]: info=',info);
        if(info.showProgress??false) {
            Utils.showProgress();
        }
        try {
            const payload = {
                endpoint: 'Auth',
                payload: {
                    action: 'login',
                    data: {
                        ...info,
                        userId: parseInt(info.userId??0)
                    }
                }
            };
            if(typeof info.setPlatform === 'undefined') {
                payload.payload.data.platform = 'ns';
            }
            //console.log('Payload: ',payload);
            let results = (await axios.post(Utils.api.Nervous.url, { data: payload }, { headers: Utils.api.Nervous.headers, timeout: Utils.requestTimeout})).data;
            Utils.hideProgress();
            if(typeof info.hideLoggingInState === 'function') {
                info.hideLoggingInState(false);
            }
            //console.log('R=',results);
            //return
            if(results.data.status && results.data.accessToken) {
                Utils.setLoginPrefs({key: 'username', value: info.username});
                Utils.setLoginPrefs({key: 'loggedIn', value: true});
                Utils.showFeedbackSuccess({msg: 'Success!'});
                setTimeout(() => {
                    Utils.cacheAccessToken(results.data.accessToken);
                    if(info.returnUrl && info.returnUrl !== '_') {
                        info.returnUrl = Utils.base64Decode(info.returnUrl);
                        window.location = info.returnUrl;
                    } else if (typeof info.destURL !== 'undefined') {
                        window.location = info.destURL;
                    } else if (Utils.returnURL) {
                        window.location = Utils.returnURL;
                    } else {
                        window.location = `${window.location.protocol}//${window.location.host}/${BaseName.name}/curriculum/${info.curriculumId}`;
                    }
                },2000);
            } else {
                if(results.data.error === 'doesNotExist') {
                    results.data.message = Texts.noAccount
                }
                if(typeof info.setValidUsername === 'function') {
                    info.setValidUsername(false);
                }
                Utils.checkResultsForErrors(results.data);
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError(error);
            if(typeof info.hideLoggingInState === 'function') {
                info.hideLoggingInState(false);
            }
            if(typeof info.setLoginResults === 'function') {
                info.setLoginResults({
                    state: true,
                    msg: error.message || error.errorMessage
                });
            }
        }
    },
    /**
     * Authenticate a user with the C4K Auth API
     * @param {object} info The info object with the returnurl prop if available
     * @returns void
     */
    authenticate: async (info = {returnUrl: false}) => {
        console.log('[Utils.authenticate]');
        Utils.showProgress();
        try {
            let results = (await axios.post(Utils.api.Nervous.url, { data: {
                endpoint: 'Auth',
                payload: {
                    action: 'login',
                    data: {
                        username: info.username,
                        password: info.password
                    }
                }
            } }, { headers: Utils.api.Nervous.headers })).data;
            Utils.hideProgress();
            if(!results.data.status) {
                StateUpdators.setError({
                    show: true,
                    msg: results.data.message || results.data.errorMessage,
                    extraData: {
                        ...info,
                        where: window.location.href
                    }
                });
            } else if (results.data.accessToken) {
                Utils.cacheAccessToken(results.data.accessToken);
                if(info.returnUrl && info.returnUrl !== '_') {
                    info.returnUrl = Utils.base64Decode(info.returnUrl);
                    if(info.extraConfigs) {
                        const token = {
                            returnTo: info.extraConfigs.returnTo,
                            accessToken: results.data.accessToken
                        }
                        window.location = `${info.returnUrl}/${Utils.base64Encode(JSON.stringify(token))}`;
                    } else {
                        window.location = info.returnUrl;
                    }
                } else {
                    window.location = `/${BaseName.name}/curriculum`;
                }
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError(error);
        }
    },
    /**
     * Log out a user
     */
    logoutUser: async () => {
        console.log('[Utils.logoutUser]');
        Utils.showProgress();
        try {
            const payload = {
                endpoint: 'Auth',
                payload: {
                    action: 'logout',
                    data: {
                        accessToken: Utils.getAccessToken().token,
                    }
                }
            };
            let results = (await axios.post(Utils.api.Nervous.url, { data: payload }, { headers: Utils.api.Nervous.headers })).data;
            Utils.hideProgress();
            if(results.data.status) {
                Utils.clearConfigsCache();
                Utils.addAnalytics({
                    page: 'Logout',
                    url: window.location.href,
                    title: 'NS.LogoutPageVisit'
                });
            } else {
                Utils.checkResultsForErrors(results.data);
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError(error);
        }
    },
    /**
     * Save the access token to the local storage.
     * @param {string} token The access token
     */
    cacheAccessToken: (token) => {
        localStorage.setItem('C4KAccessToken',JSON.stringify({token:token}));
    },
    /**
     * Clear configs cache. Among others: clear the C4KAccessToken data
     */
    clearConfigsCache: () => {
        localStorage.removeItem('C4KAccessToken');
        localStorage.removeItem('c4kLMSPage');
        //localStorage.removeItem('curriculum');
        localStorage.removeItem('course');
        localStorage.removeItem('lesson');
        localStorage.removeItem('c4kCourseToken');
        Utils.removeLoginPrefsItem({key: 'loggedIn'});
    },
    /**
     * Get the current access token
     */
    getAccessToken: () => {
        const tokenStr = localStorage.getItem('C4KAccessToken');
        if(tokenStr) {
            return Utils.parseAccessToken(JSON.parse(tokenStr).token);
        }
        return false;
    },
    /**
     * Parse the access token
     * @param {string} tokenStr The toke to parse
     */
    parseAccessToken: (tokenStr) => {
        tokenStr = typeof tokenStr === 'string' && tokenStr.trim().length > 0 ? tokenStr : false;
        return tokenStr ? {
            portalId: tokenStr.split('.')[0],
            token: tokenStr.split('.')[1]
        } : {
            portalId: 600,
            token: ''
        };
    },
    /**
     * Trim the intro text for longer words
     * @param {object} info The info object
     */
    trimIntro: (info) => {
        console.log('[Utils.trimIntro]');
        const textArr = info.intro.split(' ');
        const shortIntro = [];
        const wordLength = 7;
        for(let i = 0; i < wordLength; i++) {
            shortIntro.push(textArr[i]);
        }
        return `${shortIntro.join(' ')}...`;
    },
    setActiveClassAction: (info) => {
        console.log('[Utils.setActiveClassAction]');
        for (const element of document.getElementsByClassName('nav-item-bcfs')) {
            element.classList.remove('nav-item-active');
        }
        info.e.currentTarget.classList.add('nav-item-active');
    },
    /**
     * Set the default cactiv class content
     * @param {object} info The info object
     */
    setDefaultActiveClassAction: (info) => {
        console.log('[Utils.setDefaultActiveClassAction]');
        document.getElementsByClassName('nav-item-instructions')[0].classList.add('nav-item-active');
        StateUpdators.setClassActionContent({
            title: `${info.content.lesson.number} - ${info.content.lesson.lessonTitle}`,
            Content: () => (<Instructions lesson={info.content.lesson} />)
        });
    },
    /**
     * Normalize colors
     */
    normalizeColors: () => {
        console.log('[Utils.normalizeColors]');
        document.getElementById('root').style.background = Utils.Colors.COLBKG;
        document.body.style.background = Utils.Colors.COLBKG
    },
    completeAccount: () => {
        console.log('[Utils.completeAccount]');
        window.location = `/${BaseName.name}/me/completeAccount`
    },
    /**
     * Show the pop up for user to complete their account.
     */
    showCompleteAccountPopupUp: () => {
        console.log('[Utils.showCompleteAccountPopupUp]');
        Utils.showModal2({
            Content: () => (
            <>
            <div className='jshsh-jhdgd'>
                <h3>{Texts.headsUp}</h3>
                <img alt='Heads up' src={Assets.icons.error} className='jshsh-jghgk' />
                <div className='ksjs-psosos w-80 centred bix-b'>
                    <p>{Texts.yourAccIncompl}</p>
                </div>
                <Button variant='contained' color='primary' title='Complete my account' className='ptr force-round-btn' onClick={() => {
                    Utils.completeAccount();
                }}>
                    Complete My Account
                </Button>
            </div>
            </>)
        });
    },
    /**
     * Fetch user details
     * @param {object} info The info object
     */
    fetchUserDetails: async (info = {}) => {
        console.log('[Utils.fetchUserDetails]');
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchUserDetails',
                data: {
                    accessToken: Utils.getAccessToken().token,
                    ...info
                }
            }
        };
        //console.log('P=',payload);
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            //console.log('RRR=',results);
            if(results.data.status) {
                if((info.showPopupIfAccountIncomplete??false) && (!results.data.completeProfile) &&
                (results.data.shouldCompleteProfile??false)) {
                    Utils.showCompleteAccountPopupUp();
                }
                StateUpdators.setUser({
                    ...results.data,
                    firstname: results.data.firstname,
                    lastname: results.data.lastname,
                    username: `${results.data.username}`,
                    icon: results.data.userAvatar ? results.data.userAvatar : Assets.icons.avatarPlaceHolder,
                    iconTitle: results.data.userAvatar ? results.data.firstname : 'User Icon Not Available',
                });
                Utils.cacheUserProfile(results.data);
                if(info.useSetUserDetails??false) {
                    StateUpdators.setUserDetails({
                        firstname: results.data.firstname,
                        lastname: results.data.lastname,
                        username: `${results.data.username}`,
                        icon: results.data.userAvatar ? results.data.userAvatar : Assets.icons.userAvatar,
                        iconTitle: results.data.userAvatar ? results.data.firstname : 'User Icon Not Available'
                    });
                }
                if(info.showEarnedDigitalbadges??false) {
                    StateUpdators.setUserEarnedDigitalbadges({
                        completed: results.data.progress ? results.data.progress.completed : 0,
                        total: results.data.progress ? results.data.progress.total : 0
                    });
                }
            } else {
                Utils.checkResultsForErrors({...results.data, shouldShow: true});
            }
        } catch (error) {
            console.log('error: ',error);
        }
    },
    /**
     * Try to retrieve stored user info during a pretest
     */
    getCachedUserInfoOnPretest: () => {
        const userInfo = localStorage.getItem('C4KPreTestCache');
        if(userInfo) {
            return JSON.parse(JSON.parse(userInfo).info);
        }
        return false;
    },
    /**
     * Check if an email is valid
     * @param {string} input The email to validate
     * @returns
     */
    isValidEmail: (input) => {
        let rexpr = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'g');
        return input.match(rexpr);
    },
    /**
     * Go to a given page
     * @param {object} info The info object with the `url` (string) - the locatio to go to, with an optional `inNewWindow` optional (boolean) option
     */
    goTo: (info = {inNewWindow: false}) => {
        if(info.inNewWindow) {
            window.open(info.url)
        } else {
            window.location = info.url;
        }
    },
    /**
     * Go to the home page
     */
    accountHome: () => {
        window.location = `/${BaseName.name}/curriculum`;
    },
    /**
     * Go to the home page
     */
    goToHomePage: () => {
        window.open('https://computers4kids.co.za');
    },
    /**
     * Go to the login page
     * @param {object} info The info object. It must have the loginURL prop for wheere to forward for the login UI, and the returnURL object which
     * must have the following props:
     * - origin - The origin of the login request.
     * - returnTo - The URL to return to once login is successful.
     * - accessToken - The access token for the current session
     */
    goToLogin: (info) => {
        //info.returnURL.returnTo = Utils.base64Encode(info.returnURL);
        const token = Utils.base64Encode(JSON.stringify(info.returnURL));
        window.location = `${info.loginURL}/${token}`;
    },
    /**
     * Go to the signup page
     */
    goToSignUp: () => {
        window.location = `${BaseName.name}/signup`;
    },
    /**
     * Set web page title
     * @param {string} title The page title
     */
    setPageTitle: (title) => {
        console.log('[Utils.setPageTitle]');
        document.title = `Computers 4 Kids | ${title}`;
    },
    /**
     * Fetch a lesson
     * @param {object} info Th info object
     */
    fetchLesson: async (info) => {
        console.log('[Utils.fetchLesson]');
        Utils.showProgress();
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchLesson',
                data: {
                    ...info,
                    accessToken: Utils.getAccessToken().token
                }
            }
        };
        //let _results;
        try {
            const results /*= _results*/ = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            //_results = {};
            console.log('R=',results);
            Utils.hideProgress();
            if(results.data.status) {
                if((typeof results.data.hasAccess !== 'undefined') && !results.data.hasAccess) {
                    window.location = `/${BaseName.name}/access_closed`
                } else {
                    StateUpdators.setLesson({
                        ready: true,
                        content: results.data.content
                    });
                    StateUpdators.setLessonHeaderInfo({
                        curriculumTitle: results.data.content.curriculumTitle,
                        courseTitle: results.data.content.courseTitle,
                        subCourseTitle: results.data.content.subCourseTitle??''
                    });
                }
            } else {
                Utils.checkResultsForErrors(results.data);
            }
        } catch (error) {
            Utils.hideProgress();
            /*
            console.log('__ERROR__:',error);
            console.log('__RESULTS_:',_results);

             * Sometimes there is an error on the server when trying to rename some files and the app generates a warning.
             * This is not a real error, therefore ignore it with the following regex

            const regex = new RegExp(/rename/);
            if(regex.test(_results.data)) {
                //reload page
                console.log('test passed: reload')
                //window.location.reload();
            } else {
                console.log('Regex test failed...');
                Utils.handleError(error);
            }
            */
            Utils.handleError(error);
        }
    },
    /**
     * Fetch a course
     * @param {object} info The info object with the userId, accessToken and the courseId to fetch
     */
    fetchCourse: async (info) => {
        console.log('[Utils.fetchCourse]');
        Utils.showProgress();
        if(typeof info.categoryId === 'undefined') {
            delete info.categoryId;
        }
        if(typeof info.hasSubSubCourses === 'undefined') {
            delete info.hasSubSubCourses;
        }
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchCourses',
                data: {
                    ...info,
                    accessToken: Utils.getAccessToken().token
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
           Utils.hideProgress();
           //console.log('R=',results);
            if(results.data.status) {
                if((typeof results.data.hasAccess !== 'undefined') && !results.data.hasAccess) {
                    window.location = `/${BaseName.name}/access_closed`
                } else {
                    StateUpdators.setCourse({
                        ready: true,
                        content: results.data.content
                    });
                    if(results.data.content.curriculumTitle && results.data.content.courseTitle) {
                        console.log('Set course header full...');
                        StateUpdators.setCourseHeaderInfo({
                            title: results.data.content.curriculumTitle,
                            courseTitle: results.data.content.courseTitle,
                            subCourseTitle: results.data.content.subCourseTitle ?? ''
                        });
                    }
                }
            } else {
                Utils.checkResultsForErrors(results.data);
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError(error);
        }
    },
    /**
     * Show the digital student progress badge
     * @param {object} info The info object
     */
    showStudentProgresBadge: (info) => {
        console.log('[Utils.showStudentProgresBadge]');
        const shouldShowBadges = ['DSC','CRC'];
        try {
            const ProgressBadgeView  = React.lazy(() => import(`../views/tools/student_progress/${info.token.extra.UI.Component}Badge`));
            const _info = {
                lessonId: info.lessonId
            };
            StateUpdators.setProgressBadgeCongtent({C: () => <ProgressBadgeView info={_info} />, showBadge: shouldShowBadges.indexOf(info.token.extra.shortName) > -1});
        } catch (error) {
           console.log('Error: ',error);
        }
    },
    /**
     * Show student progress
     * @param {object} info The info object
     */
    showStudentProgress: (info) => {
        console.log('[Utils.showStudentProgress]');
        try {
            const ProgressView = React.lazy(() => import(`../views/tools/student_progress/${info.token.extra.UI.Component}`));
            const _info = {
                lessonsCompleted: 0
            };
            StateUpdators.setStudentProgress({
                show: true,
                Content: () => (<ProgressView info={_info} />)
            });
        } catch (error) {
            console.log('Error: ',error);
        }
    },
    /**
     * Fetch a curriculum
     * @param {object} info The info object with the userId, accessToken and the curriculumId to fetch
     */
    fetchCurriculumCourses: async (info) => {
        console.log('[Utils.fetchCurriculumCourses]:');
        Utils.showProgress();
        const payload = {
            endpoint: 'UserRequests',
            payload: {
                action: 'fetchCurriculumCourses',
                data: {
                    ...info,
                    accessToken: Utils.getAccessToken().token
                }
            }
        };
        try {
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                timeout: Utils.requestTimeout,
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            if(results.data.status) {
                if((typeof results.data.hasAccess !== 'undefined') && !results.data.hasAccess) {
                    window.location = `/${BaseName.name}/access_closed`
                } else {
                    StateUpdators.setCurriculum({
                        Content: () => (<RenderCurriculum content={results.data.content} />)
                    });
                }
            } else {
                Utils.checkResultsForErrors(results.data);
            }
        } catch (error) {
            Utils.hideProgress();
            Utils.handleError(error);
        }
    },
    /**
     * Show the side feedback popup with a message, then hid it after 4 seconds
     * @param {object} info The info object with the `msg` or `message` prop
     */
    showFeedbackSuccess: (info) => {
        console.log('[Utils.showFeedbackSuccess]');
        StateUpdators.setRightSidePopup({
            show: true,
            msg: info.msg || info.message,
            textColor: 'green',
            showMode: 'animate__fadeInRight'
        });
    },
    /**
     * Show the side feedback popup with a message (error message), then hide it after 4 seconds
     * @param {object} info The `info` object with the `msg` or `message` prop
     */
    showFeedbackError: (info) => {
        console.log('[Utils.showFeedbackError]');
        StateUpdators.setRightSidePopup({
            show: true,
            msg: info.msg || info.message,
            textColor: 'red',
            showMode: 'animate__fadeInRight'
        });
    },
    /**
     * Show the side feedback popup with a message, then hid it after 4 seconds
     * @param {object} info The info object with the msg or messages prop
     */
    showSideFeedback: (info) => {
        console.log('[Utils.showSideFeedback]');
        StateUpdators.setSidefeedback({
            show: true,
            feedback: info.msg || info.message
        });
    },
    /**
     * She the progress
     * @param {object} info The info object, with an optional `msg` prop to dislay
     */
    showProgress: (info = {msg: 'Please wait...'}) => {
        console.log('[Utils.showProgress]');
        StateUpdators.setLoaderConfigs({
            show: true,
            msg: info.msg
        });
    },
    /**
     * Hide the progress
     */
    hideProgress: () => {
        StateUpdators.setLoaderConfigs({show: false});
    },
    /**
     * Hide the pluggable loader
     */
    hidePluggableLoader: () => {
        StateUpdators.setPluggableLoaderConfigs({
            show: false,
            msg: '',
            title: ''
        })
    },
    /**
     * Go to a given course
     * @param {object} info The info object with the course info
     */
    goToCourse: (info) => {
        console.log('[Utils.goToCourse]');
        //analytics here
        let token = {
            courseId: info.course.courseId,
            categoryId: info.course.categoryId ? info.course.categoryId : undefined,
            hasSubSubCourses: info.course.hasSubSubCourses ? info.course.hasSubSubCourses : undefined,
            courseTitle: info.course.courseTitle,
            curriculumTitle: info.curriculum.title,
            extra: info.curriculum.extra
        };
        window.location = `/${BaseName.name}/course/${Utils.base64Encode(JSON.stringify(token))}`;
    },
    /**
     * Go to a given lesson
     * @param {object} info The info object with the lesson info and the course token object
     */
    goToLesson: (info) => {
        console.log('[Utils.goToCourse]');
        //analytics here
        let token = { ...info};
        if(info.inNewWindow??false) {
            window.open(`/${BaseName.name}/lesson/${Utils.base64Encode(JSON.stringify(token))}`);
        } else {
            window.location = `/${BaseName.name}/lesson/${Utils.base64Encode(JSON.stringify(token))}`;
        }
    },
    /**
     * Encode a string to base 64
     * @param {string} str The string to encode to the binary 64 encoding
     * @returns The encoded string
     */
    base64Encode: (str) => {
        return btoa(encodeURIComponent(str));
    },
    /**
     * Decode a string from base 64 to normal string
     * @param {string} str The string to decode from base64 to utf8
     * @returns The encoded string
     */
    base64Decode: (str) => {
        return decodeURIComponent(window.atob(str));
    },
    /**
     * Show/expand more information
     * @param {object} info The info object
     */
    showMoreCourseInto: (info) => {
        console.log('[Utils.showMoreCourseInto]');
        StateUpdators.setMoreInfoViewInfo({
            show: true,
            text: info.intro,
            title: info.title,
            color: info.color
        })
    },
    /**
     * Check REST call results for errors
     * @param {object} results The results object
     */
    checkResultsForErrors: async (results = {shouldShow: true}) => {
        console.log('[Utils.checkResultsForErrors]');
        console.log('[Utils.checkResultsForErrors]: results=',results);
        Utils.hideProgress();
        if (results.error && ['sessionExpired','tokenDoesNotExist'].indexOf(results.error) > -1) {
            Utils.clearConfigsCache();
            Utils.returnURL = window.location.href;
            StateUpdators.setError({
                show: true,
                msg: 'Your session has expired. Please login again.',
                extraData: {
                    ...results
                },
                extraRenders: () => {
                    return (
                        <>
                        <Button title='Login again' onClick={() => {
                            Utils.goTo({url: `/${BaseName.name}/rl`})
                        }} variant='contained'>Login Again</Button>
                        </>
                    )
                }
            });
        } else {
            Utils.returnURL = false;
            if(typeof results.shouldShow === 'undefined') {
                StateUpdators.setError({
                    show: true,
                    msg: results.errorMessage || results.message,
                    extraData: {
                        ...results
                    }
                });
            } else {
                if(results.shouldShow) {
                    StateUpdators.setError({
                        show: true,
                        msg: results.errorMessage || results.message,
                        extraData: {
                            ...results
                        }
                    });
                }
            }
        }
        console.log('Utils.returnURL=',Utils.returnURL);
    },
    /**
     * Take user to the login page
     * @param {object} info The info object with an optional return URL
     */
    goToLoginPage: (info = {returnUrl: false}) => {
        window.location = `${window.location.protocol}//${window.location.host}${BaseName.name}login${info.returnUrl?`/${info.returnUrl}`:''}`;
    },
    /**
     * Send an error report
     * @param {object} info The info object with error details
     */
    reportIssue: async (info) => {
        console.log('[Utils.reportIssue]');
        Utils.showProgress({msg: 'Reporting...'});
        try {
            const payload = {
                endpoint: 'Support',
                payload: {
                    action: 'reportLoginIssue',
                    data: {
                        info: info,
                    }
                }
            };
            const results = (await axios.post(Utils.api.Nervous.url,{
                data: payload
            },{
                headers: Utils.api.Nervous.headers
            })).data;
            Utils.hideProgress();
            if(results.data.status) {
                StateUpdators.setErrorInputForm({show:false});
                StateUpdators.setError({show: false});
                StateUpdators.setErrorReportSent({
                    sent: true,
                    View: () => {
                        return (
                            <>
                            <div className='nvnvh-fjfhf'>
                                <img title='Status Ok' alt='Status Ok' className='status-ok-usb ptr' src={Assets.icons.statusOk} />
                                <p className='success-ticket-open'>We have received your report. We will contact you shortly.</p>
                                <p className=''>Your support ticket number is #{results.data.ticketId}</p>
                                <p className=''>You can track it <a target='__blank' href={results.data.ticketTrackLink}>here&raquo;</a></p>
                            </div>
                            </>
                        )
                    }
                })
            } else {
                StateUpdators.setSubmitButtonDisabled(false);
                StateUpdators.setErrorInputForm(false);
                StateUpdators.setError({
                    show: true,
                    msg: results.data.message || results.data.errorMessage,
                    extraData: {
                        error: results.data.error,
                        requestData: payload
                    }
                });
            }
        } catch (error) {
            StateUpdators.setSubmitButtonDisabled(false);
            Utils.hideProgress();
            StateUpdators.setError({
                show: true,
                msg: error.message,
                extraData: {}
            });
        }
    },
    /**
     * Show general error
     * @param {object} error The error object with the `msg`|`errorMessage`|`message`|`error` props, which all must be strings.
     * If of the props are missing, the error object will be converted to a string.
     */
    handleError: (error = {}) => {
        console.log('[Utils.handleError]: error=',error);
        let errorMsg = error.message || error.msg || error.errorMessage || JSON.stringify(error);
        const rgx = new RegExp(/timeout/);
        if(rgx.test(errorMsg)) {
            errorMsg = `The connection timed out after ${Utils.requestTimeout/1000} seconds. Please check your nextwork connectivity and try again in a few moments.`;
        }
        StateUpdators.setError({
            show: true,
            msg: errorMsg
        });
    },
}

export default Utils;

//By default, when in development, use the staging environment
if(Utils._devMode) {
    Utils.api.Nervous.url = 'https://staging.computers4kids.co.za/serve/'
}
console.log('Using API Server At: ',Utils.api.Nervous.url);
