import React from 'react';
import Assets from '../../lib/Assets';
import CloseIcon from './CloseIcon';

/**
 * Popup title handle
 * @param {object} props The props object with the title prop and the onClose method
 */
const PopupHandleTitle = (props) => {
    console.log('{PopupHandleTitle}');
    return (
        <>
        <div className={`${!props.showMinimizeButton?'instructions-jfhdh':'instructions-jfhdh-jshs'} w-30 centred rounded shadow box-b`}>
            <div className='big-dot-red ptr rounded-left' onClick={() => {
                if(props.onLeftIconClick) {
                    props.onLeftIconClick();
                }
            }}>
                <CloseIcon iconColor={'white'} className='ptr hhdgd-jghg' />
            </div>
            <div className={`gdgd-instrs dfds-dd hdhgd-fhfhg ${props.showMinimizeButton?'rounded-right':''}`}>
                <h2 className='hhfk-mdkd'>{props.title}</h2>
            </div>
            <div className={`${!props.showMinimizeButton?'big-dot-jkdh':'no-show-hfhfg'} ptr rounded-right' title='Minimize`} onClick={() => {
                if(props.onRightIconClick) {
                    props.onRightIconClick();
                }
            }}>
                <span><img alt='Minimize' className='hfgf-mindjsz-j' src={Assets.icons.minimize} /></span>
            </div>
        </div>
        </>
    )
}

export default PopupHandleTitle;