import React from 'react';

/**
 * The GuidesTopicsView
 * @returns 
 */
const GuidesTopicsView = () => {
    console.log('{GuidesTopicsView}');
    return (
        <div className='mdhs-ooiej'>
        </div>
    )
}

export default GuidesTopicsView;