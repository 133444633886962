import React, { useState } from 'react';
import Texts from '../../lib/Texts';
import { InputLabel, TextField,Select, MenuItem, FormControl, Button } from '@mui/material';
import Utils from '../../lib/Utils';

/**
 * Update basic info form
 * @param {object} info The info object
 */
const UpdateBasicInfoForm = ({info}) => {
    console.log('{UpdateBasicInfoForm}');
    const [input, setInput] = useState({
        firstname: '',
        lastname: '',
        othernames: '',
        dob: '',
        gender: {
            name: 'Male',
            id: 1
        }
    });
    const [errors, setErrors] = useState({
        firstname: false,
        lastname: false,
        othernames: false,
        dob: false
    });
    return (
        <>
        <div className='hdh-ksfhfj-edg'>
            <h3>{info.completeAccount?Texts.completeAccount:Texts.updateBasicInfo}</h3>
            <p>{Texts.guveInfo}</p>
            <p className='mfhf'>{Texts.reqFields}</p>
            <div className='ndhdhd-input-dggd'>
                <div className='jdh-fjkfh w-80 centred'>
                    <TextField value={input.firstname} error={errors.firstname} fullWidth id='outlined-basic' label='Firstname:' variant='outlined' required onChange={(e) => {
                        setInput({
                            ...input,
                            firstname: e.target.value
                        })
                        if(e.target.value && e.target.value.length > 1) {
                            setErrors({...errors, firstname: false});
                        }
                    }}/>
                </div>
                <div className='jdh-fjkfh w-80 centred'>
                    <TextField value={input.lastname} error={errors.lastname} fullWidth id='outlined-basic' label='Lastname:' variant='outlined' required onChange={(e) => {
                        setInput({
                            ...input,
                            lastname: e.target.value
                        });
                        if(e.target.value && e.target.value.length > 1) {
                            setErrors({...errors, lastname: false});
                        }
                    }}/>
                </div>
                <div className='jdh-fjkfh w-80 centred'>
                    <TextField value={input.othernames} error={errors.othernames} fullWidth id='outlined-basic' label='Othernames:' variant='outlined' onChange={(e) => {
                        setInput({
                            ...input,
                            othernames: e.target.value
                        })
                    }}/>
                </div>
                <div className='jdh-fjkfh w-80 centred'>
                    <FormControl fullWidth>
                        <InputLabel>Gender:</InputLabel>
                        <Select
                            labelId='course-select-jdhd'
                            id='course-select-jhss'
                            value={JSON.stringify(input.gender)}
                            label={'Gender:'}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    gender: JSON.parse(e.target.value)
                                })
                            }}
                        >
                            <MenuItem value={JSON.stringify({name: 'Male', id: 1})} >Male</MenuItem>
                            <MenuItem value={JSON.stringify({name: 'Female', id: 2})} >Female</MenuItem>
                            <MenuItem value={JSON.stringify({name: 'Other', id: 3})} >Other</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='jdh-fjkfh w-80 centred nhs-kfl-s'>
                    <Button variant='contained' color='primary' title='Save new info' className='ptr force-round-btn' onClick={() => {
                        if(!(input.firstname)) {
                            setErrors({
                                ...errors,
                                firstname: true
                            });
                            return;
                        }
                        if(!(input.lastname)) {
                            setErrors({
                                ...errors,
                                lastname: true
                            });
                            return;
                        }
                        Utils.saveUserAccountInfo(input);
                    }}>
                        Save
                    </Button>
                </div>
            </div>
        </div>
        </>
    )
}

export default UpdateBasicInfoForm;