import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Utils from '../../../lib/Utils';
import QuestionImage from '../QuestionImage';
import AnsweredQuestionsTrack from '../AnsweredQuestionsTrack';
import StateUpdators from '../../../lib/StateUpdators';
import QuizTimer from '../QuizTimer';
import ConfirmQuestionsPaperSubmission from '../ConfirmQuestionsPaperSubmission';
import QuizStartBriefInfo from '../QuizStartBriefInfo';

/**
 * DisplayA given Quiz/test
 * @param {number} lessonId The lessonId of the quiz
 * @param {number} courseId The courseId of the quiz
 * @param {array} questions The questions to render
 * @param {string} quizTitle The quiz title
 * @param {number} duration The duration of the quiz
 * @param {object} extraConfigs Extra configs for the quiz, such as onPaperSubmit and onDurationTimeOut
 * @returns 
 */
const DisplayQuiz = ({lessonId,courseId,questions,quizTitle,duration,extraConfigs,quizType}) => {
    const [_meta, setMetaInfo] = useState({
        start: false
    });
    const [_answers, setAnswers] = useState({});
    const [_answeredQs, setAsnweredQuestions] = useState(Object.keys(questions));
    const [disabled, setButtonStatus] = useState(false);
    const [startTime, setStartTime] = useState(Utils.getTimeStamp());
    const [startTimestamp, setStartTimestamp] = useState(Date.now());
    StateUpdators.setAsnweredQuestions = setAsnweredQuestions;
    StateUpdators.setStartTime = setStartTime;
    StateUpdators.setStartTimestamp = setStartTimestamp;
    useEffect(() => {
        Utils.setOnWindowCloseEvent();
    },[]);
    return (
        <>
        {
            _meta.start ?
            <>
            <AnsweredQuestionsTrack answeredQs={_answeredQs}/>
            <div className='bcbfg rounded w-80 centred bdr-blue shadow animate__animated animate__fadeIn'>
                <div className='exam-hdr rounded-top'>
                    {
                        extraConfigs && extraConfigs.RenderExtraInfo &&
                        <div>
                            <extraConfigs.RenderExtraInfo />
                        </div>
                    }
                    <h2 id='bcgfg-hh'>Quiz In Progress: {quizTitle}, Questions: <b className='ptr' title='The number of questions for this test'>{questions.length}</b>, Duration: <b className='ptr' title='Recommended duration for this test'>{duration}</b> minutes, Time Elapsed: <b className='ptr' title='Elapsed time since you began the test'><QuizTimer duration={duration} /></b></h2>
                </div>
                <div className='exams-mfjfhg'>
                    {
                        questions.map((question,i) => {
                            i++;
                            const choices = question.choices.split(',');
                            return (
                                <div id={`q-s-${i}`} className='question-mffhfgh w-80 centred bdr-bl rounded' key={i}>
                                    <div className='question-text'>
                                        <p id={`question-${question.questionId}`}>{i}. {question.text}</p>
                                    </div>
                                    <div className='quesjfhfh-answers'>
                                        <div className='choices-mfjhfh'>
                                            {
                                                choices.map((choice,key) => {
                                                    return (
                                                        <div className='choice-jfjfh container' key={key}>
                                                            <input type='radio' name={`answer-for-q-${i}`} onChange={(e) => {
                                                               const currentAnswer = {};
                                                               currentAnswer[`answer_${question.questionId}`] = {
                                                                answer: parseInt(e.currentTarget.value),
                                                                questionId: question.questionId
                                                               };
                                                               const answeredQs = {};
                                                               answeredQs[i] = i;
                                                               setAnswers({
                                                                ..._answers,
                                                                ...currentAnswer
                                                               });
                                                               Utils.paintAnsweredQuestion({q: i});

                                                            }} value={key} className='input-answe-hjfhfg'/>
                                                            <span className='checkmark'></span>
                                                            <span className='choice-text-val'>{choice}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='hdgdgq-image'>
                                            <QuestionImage src={`${Utils.quizAssetsURL}${question.image}`} qNum={i} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='submit-nfhfg-jhdh'>
                    <Button disabled={disabled} variant='contained' color='primary' className='force-round-btn' onClick={() => {
                        StateUpdators.setActionModal({
                            show: true,
                            Content: () => <ConfirmQuestionsPaperSubmission
                            answers={Object.keys(_answers).length}
                            questions={questions.length}
                            onConfirm={() => {
                                setButtonStatus(true);
                                Utils.submitQuizForMarking({
                                    lessonId: lessonId,
                                    courseId: courseId,
                                    answers: _answers,
                                    startTimestamp: startTimestamp,
                                    startTime: startTime,
                                    endTime: Utils.getTimeStamp(),
                                    quizTitle: quizTitle,
                                    quizType: quizType
                                });
                            }} />
                        })
                    }}>
                        Submit
                    </Button>
                </div>
            </div>
            </> 
            :
            <>
            <QuizStartBriefInfo duration={duration} quizTitle={quizTitle} questions={questions.length}  extraConfigs={extraConfigs} onStart={() => {
                setMetaInfo({start: true});
            }} />
            </>
        }
        </>
    )
}

export default DisplayQuiz;