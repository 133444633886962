import React from 'react';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

/**
 * Current lesson info
 * @param {object} data The info object 
 */
const CurrentLessonInfo = ({data}) => {
    console.log('{CurrentLessonInfo}');
    //console.log('{CurrentLessonInfo}: data=',data);
    return (
        <>
        <div className='jshsh text-centre'>
            <h3>Current Lesson Info</h3>
        </div>
        <div className='jhsh-lsjsj'>
            <JsonView data={data} shouldExpandNode={allExpanded} style={defaultStyles} />
        </div>
        </>
    )
}

export default CurrentLessonInfo;