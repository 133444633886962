//import BaseName from "./BaseName";
import BaseName from "./BaseName";
import Utils from "./Utils";

/**
 * Home page ections
 */
const HomePageSectionButtons = [
  {
    title: 'Create your account',
    header: 'Registration',
    verb: 'Create Account',
    icons: {
        link: 'yellowButton',
        main: 'accountCreate'
    },
    /**
     * When clicked, got to the signup page
     */
    onClick: () => {
        Utils.addAnalytics({
            page: 'Home',
            url: window.location.href,
            title: 'NS.RegisterPage'
        });
        Utils.goTo({url: `/${BaseName.name}/signup`})
    }
  },
  {
    title: "Login to your account",
    header: "Your Account",
    verb: "Login",
    icons: {
      link: "yellowButton",
      main: "account",
    },
    /**
     * When clicked, show the login form
     */
    onClick: () => {
      Utils.addAnalytics({
        page: "Home",
        url: window.location.href,
        title: "NS.LoginForm",
      });
      if (!Utils.isLoggedIn()) {
        Utils.showLoginForm();
      } else {
        window.location = Utils.myAccountLink;
      }
    },
  },
  {
    title: "Access Minecraft demo lessons",
    header: "Minecraft",
    verb: "Demo Lessons",
    icons: {
      link: "yellowButton",
      main: "minecraft",
    },
    /**
     * When clicked, go this link
     */
    onClick: () => {
      Utils.addAnalytics({
        page: "Home",
        url: window.location.href,
        title: "NS.NavigationTo.DemoLessons",
      });
      Utils.goTo({
        url: "https://computers4kids.co.za/minecraft-demos/",
        inNewWindow: true,
      });
    },
  },
  {
    title: "Try out the bussiness opportunity",
    header: "Licensing",
    verb: "Business Opportunity",
    icons: {
      link: "yellowButton",
      main: "businessOp",
    },
    /**
     * When clicked, go this link
     */
    onClick: () => {
      Utils.addAnalytics({
        page: "Home",
        url: window.location.href,
        title: "NS.NavigationTo.BusinessOpportunity",
      });
      Utils.goTo({
        url: "https://computers4kids.co.za/business-opportunity/",
        inNewWindow: true,
      });
    },
  },
];

export default HomePageSectionButtons;
