import React, { useRef,useState } from 'react';
import Assets from '../../lib/Assets';
import CheckIcon from '@mui/icons-material/Check';
import Utils from '../../lib/Utils';

const C4KUserCategories = () => {
    console.log('{C4KUserCategories}');
    const refs = {
        1: useRef(),
        2: useRef(),
        3: useRef()
    }
    const [_chosen, setChosenOption] = useState('HomeSchooler');
    const _userOptions = {
        HomeSchooler: 'An Individual',
        School: 'A School',
        Organization: 'An Organisation',
        Licensee: 'An Entrepreneur'
    };
    const allFalse = {1:false,2:false,3:false};
    const [chosen, setChosen] = useState({
        1: true,
        2: false,
        3: false
    });
    Utils.chosenOption = _chosen;
    //console.log('Utils.chosenOption=',Utils.chosenOption);
    return (
        <div className='user-cates'>
            <div ref={refs[1]} className='user-opt-cate brd-whity shadow pointer selected-usr-opt rounded' onClick={(e) => {
                for (const ref of Object.values(refs)) {
                    ref.current.classList.remove('selected-usr-opt');
                }
                setChosen(allFalse)
                e.currentTarget.classList.add('selected-usr-opt');
                setChosen({...allFalse,1:true})
                setChosenOption('HomeSchooler')
            }}>
                <div className='u-cat-hdr u-cat-hscooler rounded-top'>
                    <h4>{_userOptions.HomeSchooler}</h4>
                </div>
                <div className='u-cat-licensee-img'>
                    <img src={Assets.userOptions.homeschooler} title='Home schooler (Picture by pixabay)' alt='Home schooler' />
                </div>
                <div className='u-cat-text'>
                    <p>Perfect for you if you are a tech savvy individual or home schooler; we have you...</p>
                </div>
                <div title='Choose' className='u-cat-choose-btn content-centre rounded-bottom pointer'>
                    {
                        chosen[1] ?
                        <>
                        <CheckIcon sx={{color: 'white'}} />
                        </>:
                        <h4>Choose</h4>
                    }
                </div>
            </div>
            <div ref={refs[2]} className='user-opt-cate brd-whity shadow pointer rounded' onClick={(e) => {
                for (const ref of Object.values(refs)) {
                    ref.current.classList.remove('selected-usr-opt');
                }
                setChosen(allFalse)
                e.currentTarget.classList.add('selected-usr-opt');
                setChosen({...allFalse,2:true})
                setChosenOption('School')

            }}>
                <div className='u-cat-hdr u-cat-school rounded-top'>
                <h4>{_userOptions.School}</h4>
                </div>
                <div className='u-cat-licensee-img'>
                    <img src={Assets.userOptions.school} title='School' alt='School' />
                </div>
                <div className='u-cat-text'>
                    <p>If you are an IT coordinator or IT teacher looking for content to implement 21st century digital skills in your school, we have a solution for you!</p>
                </div>
                <div title='Choose' className='u-cat-choose-btn content-centre rounded-bottom pointer'>
                    {
                        chosen[2] ?
                        <>
                        <CheckIcon sx={{color: 'white'}} />
                        </>:
                        <h4>Choose</h4>
                    }
                </div>
            </div>
            <div ref={refs[3]} className='user-opt-cate brd-whity shadow pointer rounded' onClick={(e) => {
                for (const ref of Object.values(refs)) {
                    ref.current.classList.remove('selected-usr-opt');
                }
                setChosen(allFalse)
                e.currentTarget.classList.add('selected-usr-opt');
                setChosen({...allFalse,3:true});
                setChosenOption('Licensee')

            }}>
                <div className='u-cat-hdr u-cat-licensee rounded-top'>
                <h4>{_userOptions.Licensee}</h4>
                </div>
                <div className='u-cat-licensee-img'>
                    <img src={Assets.userOptions.licensee} title='Licensee' alt='Licensee' />
                </div>
                <div className='u-cat-text'>
                    <p>Leverage our 30+ years of digital content creation and IP and run your own business by joining us as a Licensee here!</p>
                </div>
                <div title='Choose' className='u-cat-choose-btn content-centre rounded-bottom pointer'>
                    {
                        chosen[3] ?
                        <>
                        <CheckIcon sx={{color: 'white'}} />
                        </>:
                        <h4>Choose</h4>
                    }
                </div>
            </div>
        </div>
    )
}

export default C4KUserCategories;