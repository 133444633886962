import React, { useState } from 'react';
import Assets from '../../lib/Assets';
import Utils from '../../lib/Utils';
import StateUpdators from '../../lib/StateUpdators';
import '../../css/search.css';
import SmallLoader from './SmallLoader';
import Separator from './Separator';

/**
 * Search tool
 * @param {string} section The section on which the search tool is used. It helps set the search handler
 * @param {fn} onFocus The onFocus function to call
 * @param {fn} resultsMounter The results mounter function if given, to use to mount/update the required component to update.
 * @param {object} existingResults Existing results of some component state.
 * @returns 
 */
const Search = ({section,onFocus = () => {}, resultsMounter = () => {}, existingResults = {}}) => {
    console.log('{Search}');
    const [foundResults, setSearchResultsFound] = useState(false);
    StateUpdators.setSearchResultsFound = setSearchResultsFound;
    const _section = {
        lessons: 'lessons'
    };
    const [searchTerm, setSearchTerm] = useState('');
    const [searching, setSearching] = useState(false);
    StateUpdators.setSearching = setSearching;
    return (
        <div id='lc-sch-h'>
            <div id='search-input-sect' className='centred' title={`Search ${_section[section]}`}>
                <input type='text' onChange={(e) => {
                    setSearchTerm(e.target.value);
                }} value={searchTerm} placeholder={`Search ${_section[section]}`} id='search-input' onKeyDown={(e) => {
                    Utils.respondKeyPress({e: e, section: section, resultsMounter: resultsMounter, existingResults: existingResults});
                }}
                    onFocus={onFocus}/>
                <img alt='Search' title={`Search ${section}`} id='search-icon-search' src={Assets.icons.searchIconBlack} onClick={() => {
                    Utils.search({searchTerm: searchTerm,section: section, resultsMounter: resultsMounter, existingResults: existingResults});
                }}/>
            </div>
            {
                typeof foundResults === 'number' && foundResults >= 0 ?
                <span className='found-resjfhfg'>{foundResults} Results Found</span> : <></>
            }
            {
                searching &&
                <>
                <Separator styles={{height: '50px'}} />
                <SmallLoader customClasses='kjs-kdhdh-kes' title='Searching...' />
                <p>Looking up '<b>{searchTerm}</b>'...</p>
                </>
            }
        </div>
    )
}

export default Search;