/**
 * Themes for consistency
 */
const Theme = {
    /**
     * Applied to content
     */
    content: {
        /**
         * Hooks for the main page
         */
        mainPage: {
            classes: 'hddg-info-hdhdg w-80 centered box-b rounded shadow mnhd0jdh animate__animated animate__fadeIn'
        },
        animated: {
            fadeIn: 'animate__animated animate__fadeIn'
        }
    }
}

export default Theme;