import React, { useEffect, useState } from 'react';
import LoadingImage from './LoadingImage';

/**
 * {`${Utils.quizAssetsURL}${question.image}`}
 * Display a question image
 * @param {string} param0 The src prop of the image
 * @returns 
 */
const QuestionImage = ({src,qNum}) => {
    const [_image, setQuestionImage] = useState({
        src: false,
        styles: {}
    });
    useEffect(() => {
        const _img = new Image();
        _img.src = src;
        _img.onload = () => {
            if(_img.width >= 300) {
                setQuestionImage({
                    src: src,
                    styles: {
                        height: '100%'
                    }
                });
            } else {
                if(_img.width) {
                    setQuestionImage({
                        src: src,
                        styles: {
                            width: '100%'
                        }
                    });
                } else {
                    console.log('Error: unable to load image',_img);
                }
            }
        }
    },[_image.src,src]);
    return (
        <>
        {
            _image.src ? 
            <>
            <img onMouseEnter={(e) => {
               e.currentTarget.classList.add('qs-img-zoom');
            }} onMouseLeave={(e) => {
                e.currentTarget.classList.remove('qs-img-zoom');
            }} className='ptr qs-image-mhfgfhf' src={_image.src} style={{..._image.styles}} alt={`Question Asset ${qNum}`} title={`Question Asset ${qNum}`} />
            </>
            :
            <>
            <LoadingImage />
            </>
        }
        </>
    )
}

export default QuestionImage;