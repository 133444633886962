import React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import PaceAlignment from './PaceAlignment';
import CambridgeProgram from './CambridgeProgram';

/**
 * PACE alignment popup
 * @param {object} The info object 
 * @returns 
 */
const MultipleAlignments = ({info}) => {
    console.log('{MultipleAlignments}');
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        event = {};
        setValue(newValue);
    };
    return (
        <>
        <div className='ndhdgdhd'>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label='Alignments'>
                        <Tab label={'PACE'} value='1' />
                        <Tab label='Cambridge' value='2' />
                    </TabList>
                </Box>
                <TabPanel value='1'>
                    <div className='jshs-jlooshs animate__animated animate__fadeIn'>
                        <PaceAlignment info={info._paceAligned.info} />
                    </div>
                </TabPanel>
                <TabPanel value='2'>
                    <div className='jshs-jlooshs animate__animated animate__fadeIn'>
                        <CambridgeProgram info={info._cambridgeProg.info} />
                    </div>
                </TabPanel>
            </TabContext>
        </div>
        </>
    )
}

export default MultipleAlignments;