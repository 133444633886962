import { ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import Utils from '../../lib/Utils';
import StateUpdators from '../../lib/StateUpdators';

/**
 * Track answered questions
 * @returns 
 */
const AnsweredQuestionsTrack = ({answeredQs}) => {
    console.log('{AnsweredQuestionsTrack}');
    const [_scrollFunctions, setScrollFunctionsOnMobileDevices] = useState({
        onClick: ''
    });
    StateUpdators.setScrollFunctionsOnMobileDevices = setScrollFunctionsOnMobileDevices;
    useEffect(() => {
        Utils.setTrackQuestionsClickableOnMobileDevices();
    },[]);
    return (
        <>
        <div className='answered-questions shadow bkg-white rounded'>
            <div className='bcggd-track rounded-top'>
                <p title='Track of questions you have answered' className='ptr'>Answered Questions</p>
            </div>
            <div className='jdgf-jsgdkfhd'>
            {
                Object.keys(answeredQs).map((qs,_key) => {
                    _key++;
                    qs++;
                return (
                    <span onClick={() => {
                        Utils.scrollToQuestion({q: qs});
                    }} key={_key} title='Not answered (Click to go to question)' className={`ptr qs-numsh-nfhf rounded-min shadow answ-${_key}`}>{qs}</span>
                )
                }) 
            }
            </div>
            <ExpandMore onClick={() => {
                _scrollFunctions.onClick();
            }} titleAccess='Scroll for more questions' className='ptr hook-on-mobile-devices' />
        </div>
        </>
    )
}

export default AnsweredQuestionsTrack;