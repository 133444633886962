import React, { Suspense, useEffect, useState } from 'react';
import StateUpdators from '../../../lib/StateUpdators';
import Utils from '../../../lib/Utils';
import ShowProgressData from '../ShowProgressData';
import LoadingComponent from '../LoadingComponent';

/**
 * Achievements view
 * @param {object} props The props object
 * @returns 
 */
const Achievements = ({lesson}) => {
    console.log('{Achievements}');
    const [progress, setStudentProgressData] = useState({
        hasData: false,
        data: {}
    });
    const curriculum = Utils.getCachedCurriculumInfo();
    const View = React.lazy(() => new Promise((resolve,reject) => {
        import(`../achievements/${curriculum.extra.shortName}`).then(result => {
            resolve(result.default ? result: {default: result})
        })
        .catch(error => {
            console.log('Error: ',error.message);
            reject(error);
        })
    }));
    StateUpdators.setStudentProgressData = setStudentProgressData;
    useEffect(() => {
        Utils.fetchProgressData({curriculum: curriculum.extra.shortName, courseId: lesson.lesson.courseId, lessonId: lesson.lesson.lessonId});
        Utils.addAnalytics({
            page: 'Achievements',
            url: window.location.href,
            title: 'NS.Achievements',
            courseId: lesson.lesson.courseId, 
            lessonId: lesson.lesson.lessonId
        });
    },[lesson.lesson.courseId,lesson.lesson.lessonId,curriculum.extra.shortName]);
    return (
        <>
        <div className='animate__animated animate__fadeIn'>
            {
                progress.hasData ?
                <ShowProgressData lesson={lesson} progress={progress} /> :
                <Suspense fallback={<LoadingComponent />}>
                    <View lesson={lesson.lesson} />
                </Suspense>
            }
        </div>
        </>
    )
}

export default Achievements;