import React, { useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import CloseIcon from './CloseIcon';
import { Dialog } from '@mui/material';

/**
 * Full screen Modal
 * @returns 
 */
const FullScreenModal = () => {
    console.log('{FullScreenModal}');
    const [Modal, setFullscreenModal] = useState({
        show: false,
        Content: () => (<></>),
        exitMode: false
    });
    StateUpdators.setFullscreenModal = setFullscreenModal;
    return (
        <>
        <Dialog
            fullScreen
            open={Modal.show}
            className='fgfg-werdc-jfhfh'
        >
        {
            Modal.show &&
            <div className={`hgsg-full animate__animated ${Modal.exitMode?`${Modal.exitMode}`:'animate__fadeIn'} rounded bkg-white w-100 modal-2-full shadow`}>
               <CloseIcon onClose={() => {
                    setFullscreenModal({show: false});
                    if(Modal.onClose??false) {
                        Modal.onClose();
                    }
                }} />
                <Modal.Content />
            </div>
        }
        </Dialog>
        </>
    )
}

export default FullScreenModal;