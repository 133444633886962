import React, { useEffect, useRef, useState } from 'react';
import Assets from '../../lib/Assets';
import StateUpdators from '../../lib/StateUpdators';
import Utils from '../../lib/Utils';
import RenderAnimatedImage from './RenderAnimatedImage';
import Texts from '../../lib/Texts';
import HomePageSectionButtons from '../../lib/HomePageSectionButtons';
import COLFooter from './COLFooter';
import LoginForm from './LoginForm';
import { useParams } from 'react-router-dom';
import '../../css/mui_overrides.css';

/**
 * About Classesonline
 * @returns 
 */
const SectionOneAboutCOL = () => {
    console.log('{SectionOneAboutCOL}');
    const [image, setImage] = useState(0);
    StateUpdators.setImage = setImage;
    const reLogin = useParams().rl;
    const _reLogin = useRef(reLogin);
    useEffect(() => {
        if(_reLogin.current) {
            StateUpdators.setLoginFormDialogState(true);
        }
        const intervalId = setInterval(() => {
            Utils.switchSectionImage({
                currentIndex: image, 
                updator: setImage,
                arraySource: Assets.sections.children
            });
        },Utils.FIVE_SECONDS);
        return () => clearInterval(intervalId)
    },[image]);
    return (
        <>
        <div className='ndhshmahdj-ldjks box-b'>
            <div className='ndhs-pwls'>
                <RenderAnimatedImage index={image} />
            </div>
            <div className='nbs-psl box-b'>
                <div className='mnshs-kfjd'>
                {
                    Texts.aboutCol.map((para,i) => <p key={i} dangerouslySetInnerHTML={{__html: para}}></p>)
                }
                <div className='ndbdhe rounded ptr' title='Learn more'>
                    <h3><a rel='noreferrer' target='__blank' href={Utils.aboutC4KURL}>Learn more</a></h3>
                </div>
                </div>
            </div>
        </div>
        <div className='jshsh-ldjksj w-90 centred'>
            {
                HomePageSectionButtons.map((section,i) => (
                    <div key={i} className='mjdd-ldod box-b content-centre rounded shadow ptr' title={section.title} onClick={() => {
                        section.onClick();
                    }}>
                        <div className='mss-ldkd'>
                            <div className='mjd-ldksk'>
                                <div className='msjsj content-centre'>
                                    <img alt='' className='mss-pwks-w rounded' src={Assets.sections.illustrate[section.icons.main]} />
                                </div>
                            </div>
                            <div className='mchdjs-w'>
                                <div className='nshs-pws'>
                                    <div className='mcnd content-centre'>
                                        <h3 className='mgn-0'>{section.header}</h3>
                                    </div>
                                    <div className='mdhshs-ss'>
                                        <p className='nhd-verb'>{section.verb}</p>
                                    </div>
                                </div>
                                <div className='mcnd content-centre ptr' title={section.title}>
                                    <img className='nhd-pw-wm' src={Assets.icons[section.icons.link]} alt={section.title} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
        <COLFooter />
        <LoginForm reLogin={_reLogin.current} />
        </>
    )
}

export default SectionOneAboutCOL;