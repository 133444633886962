import React, { useEffect, useState } from 'react';
import Separator from '../Separator';
import Utils from '../../../lib/Utils';
import { DataGrid } from '@mui/x-data-grid';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import MUIDataGridCellImage from '../MUIDataGridCellImage';

/**
 * The Digital Skills Curriculum PINS View 
 * @returns 
 */
const DSCPINSView = () => {
    console.log('{DSCPINSView}')
    const [data, setDBPINSData] = useState({
        album: [{
            id: 255,
            course: 'Volume R',
            courseCategory: null,
            badgeId: 255,
            pin: 7764,
            lessonNumber: 1,
            lastUpdated: '2023-10-11 13:40:37',
            lessonId: 279,
            lessonTitle: 'Shape',
            courseId: 7,
            courseCategoryId: null,
            badgeIcon: 'l7_1.png'
        }],
        fetched: false
    });
    /**
     * Data grid columns
     */
    const columns = [
        { headerClassName: 'hgsg-jhdgd-ljs',field: 'id', headerName: 'BadgeId', width: 80 },
        { headerClassName: 'hgsg-jhdgd-ljs',field: 'lessonNumber', headerName: 'Lesson #', width: 80, },
        { headerClassName: 'hgsg-jhdgd-ljs',field: 'lessonTitle', headerName: 'Lesson', width: 200, },
        { headerClassName: 'hgsg-jhdgd-ljs',field: 'pin', headerName: 'PIN', width: 80, renderCell: (params) => <><b>{params.row.pin}</b></> },
        { headerClassName: 'hgsg-jhdgd-ljs',field: 'icon', headerName: 'Badge Icon', width: 200, renderCell: (params) => <MUIDataGridCellImage srcBase={Utils.DSCDBAssetsURL} params={params} /> }
    ];
    const courses = {};
    Object.values(data.album).forEach(entry => {
        if(entry.courseCategory) {
            courses[`${entry.course}.${entry.courseCategory}`] = {
                courseId: entry.courseId,
                name: `${entry.course} > ${entry.courseCategory}`,
                courseCategoryId: entry.courseCategoryId,
            }
        } else {
            courses[entry.course] = {
                courseId: entry.courseId,
                name: entry.course,
                courseCategoryId: entry.courseCategoryId,
            }
        }
    });
    const firstCourse = Object.values(courses)[0]??{courseId: 7, name: 'Volume R', courseCategoryId: null};
    const [course, setCourseOnDBVAlbum] = useState({
        courseId: firstCourse.courseId,
        name: firstCourse.name,
        courseCategoryId: firstCourse.courseCategoryId
    });
    useEffect(() => {
        if(!data.fetched) {
            Utils.fetchDigitalPINS({dataSetter: setDBPINSData, curriculumId: 102});
        }
    },[data.fetched]);
    return (
        <>
        <div className='jfhdgd-kfkfj w-40 centered'>
            <FormControl fullWidth>
                <InputLabel id='digitial-pins-select-label-v'>Volume</InputLabel>
                <Select
                    labelId='digitial-pins-select-label-v'
                    id='demo-simple-select'
                    value={JSON.stringify(course)}
                    label={course.name}
                    onChange={(e) => {
                        setCourseOnDBVAlbum(JSON.parse(e.target.value));
                    }}
                >
                    {
                        Object.values(courses).map((el,i) => {
                            return (
                                <MenuItem value={JSON.stringify(el)} key={i}>{el.name}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </div>
        <Separator style={{height: '20px'}} />
        <div id='list-table-no-fjf' style={{ height: 400}} className='w-50 centred shadow padd-btm-30 animate__animated animate__fadeIn'>
            <DataGrid 
                rows={data.album.filter(entry => {
                    if(entry.courseCategory) {
                        return `${entry.course} > ${entry.courseCategory}` === course.name;
                    }
                    if(entry.courseCategoryId) {
                        return entry.courseCategoryId = course.courseCategoryId;
                    }
                    return entry.course === course.name;
                })}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        }
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 30 },
                    },
                }}
                pageSizeOptions={[30, 60]}
                checkboxSelection
            />
        </div>
        </>
    )
}

export default DSCPINSView;