import { Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';

/**
 * The GuidesSearchView
 * @returns 
 */
const GuidesSearchView = () => {
    console.log('{GuidesSearchView}');
    const [searchTerm, setSearchTerm] = useState('');
    console.log('searchTerm=',searchTerm);
    return (
        <div className='jdhs-oiep'>
            <div className='hsgs0die-e w-80 centred'>
                <h3 className='spt-hdr-txt-color2 spt-hdr-txt-no-mgn'>{'Search for a topic'}</h3>
                <p className='spt-text'>Type to search for topics</p>
                <TextField
                    size='small'
                    placeholder='Search topics...'
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value)
                    }}
                    sx={{
                        borderRadius: 18,
                        color: '#8d9aad'
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'><Search /></InputAdornment>
                    }}
                />
            </div>
        </div>
    )
}

export default GuidesSearchView;