import React from 'react';
import Assets from '../../lib/Assets';
import Utils from '../../lib/Utils';

const ClassLogos = (props) => {
    console.log('{ClassLogos}');
    const styles = props.styles ?? {};
    return (
        <>
        <div style={{...styles}} className='logos-class h-centred'>
            <div>
                <img onClick={() => {
                    Utils.goTo({url: 'https://computers4kids.co.za/', inNewWindow: true});
                }} className='jghgh-c4k-logo ptr' src={Assets.c4kLogo} title='Computers 4 Kids' alt='Computers 4 Kids' />
            </div>
            <div>
                <img onClick={() => {
                    Utils.goTo({url: 'https://classesonline.mobi/', inNewWindow: true});
                }} className='jghgh-col-logo ptr' src={Assets.colLogo} title='Classesonline' alt='Classesonline' />
            </div>
            <div className='curr-logo-kfjhgh'>
                {
                    props.curriculum && 
                    <img className='hfgg-logo-class-curr ptr' src={Assets.icons[props.curriculum.shortName]} alt={props.curriculum.shortName} title={props.curriculum.title} />
                }
            </div>
        </div>
        </>
    )
}

export default ClassLogos;