import React from 'react';
import Assets from '../../lib/Assets';
import Utils from '../../lib/Utils';
import BaseName from '../../lib/BaseName';

const AbsoluteCOLLogo = (props) => {
    console.log('{AbsoluteCOLLogo}');
    const styles = typeof props.styles !== 'undefined' ? props.styles : {};
    return (
        <>
        <img style={{
            ...styles
            }} src={Assets.colLogo2} alt='Classesonline Logo' title='Classesonline Logo | Learn online, anytime, anywhere' className='c4k-logo c4k-ab-logo ptr' onClick={() => {
                Utils.goTo({inNewWindow: false, url: `/${BaseName.name}`});
            }} />
        {
            props.otherLogos && props.otherLogos.length > 0 &&
            <>
            {
                props.otherLogos.map((logo,i) => {
                    return (
                        <img key={i} style={{
                            ...styles
                        }} src={Assets[logo.name]} alt={logo.alt||''} title={logo.title} className={logo.classNames} onClick={() => {
                            Utils.goTo({inNewWindow: true, url: logo.url});
                        }} />
                    )
                })
            }
            </>
        }
        </>
    )
}

export default AbsoluteCOLLogo;