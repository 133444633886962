import React, { useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import CloseIcon from './CloseIcon';
import { Dialog } from '@mui/material';

/**
 * Action Modal
 * @returns 
 */
const ActionModal = () => {
    console.log('{ActionModal}');
    const [Modal, setActionModal] = useState({
        show: false,
        Content: () => (<></>)
    });
    StateUpdators.setActionModal = setActionModal;
    return (
        <>
        <Dialog
            open={Modal.show}
        >
        {
            Modal.show &&
            <div className='animate__animated rounded animate__fadeIn rounded bkg-white w-50 modal shadow'>
               <CloseIcon onClose={() => {
                    setActionModal({show: false});
                }} />
                <Modal.Content />
            </div>
        }
        </Dialog>
        </>
    )
}

export default ActionModal;