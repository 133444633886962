import React,{useState,useEffect, useRef} from 'react';
import StateUpdators from '../lib/StateUpdators';
import Utils from '../lib/Utils';
import Error from './tools/Error';
import Loader from './tools/Loader';
import ActionModal2 from './tools/ActionModal2';
import ReportError from './tools/ReportError';
import RightSidePopin from './tools/RightSidePopin';
import AccountHeader from './tools/AccountHeader';
import SmallLoader from './tools/SmallLoader';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import BaseName from '../lib/BaseName';
import Assets from '../lib/Assets';


/**
 * 
 * @returns 
 */
const SubscriptionCancelled = () => {
    console.log('{SubscriptionCancelled}');
    const [cancelled, setCancelled] = useState({
        cancelling: true,
        cancelled: false,
        error: ''
    });
    let token = useParams().token;
    if(token) {
        token = JSON.parse(Utils.base64Decode(token));
    }
    const _cancelled = useRef(null);
    StateUpdators.setCancelled = setCancelled;
    useEffect(() => {
        if(!_cancelled.current) {
            _cancelled.current = true;
            Utils.cancelPayment({orderId: token.orderId});
        }
        Utils.addAnalytics({
            page: 'Subscriptions',
            url: window.location.href,
            title: 'NS.Subscriptions.SubscriptionCancelled'
        });
    },[token.orderId]);
    return (
        <>
        <AccountHeader showPopupIfAccountIncomplete={true} />
        <div className='w-80 h-centred bkg-w rounded nchfh-kfhf shadow box-b animate__animated animate__fadeIn'>
            {
                cancelled.cancelling ?
                <>
                <p>Cancelling your order...</p>
                <SmallLoader customClasses={'small-loader'} />
                </>:
                <div>
                    {
                        cancelled.error ? 
                        <>
                        <div>
                            <img alt='' className='error-50' src={Assets.icons.error} />
                            <p className='error'>{cancelled.error}</p>
                        </div>
                        </>:
                        <div>
                            <img alt='' className='error-50' src={Assets.icons.warn} />
                            <p className='warn'>The payment/order was cancelled.</p>
                        </div>
                    }
                    <Button title='Go back to your account' className='ptr' onClick={() => {
                        window.location = `/${BaseName.name}/curriculum`;
                    }}>
                        Go To Home
                    </Button>
                </div>
            }
        </div>
        <Error />
        <RightSidePopin />
        <ReportError />
        <Loader />
        <ActionModal2 />
        </>
    )
}

export default SubscriptionCancelled;