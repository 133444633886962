import React, { useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import { Dialog } from '@mui/material';

/**
 * Action Modal
 * @returns 
 */
const LessonTaskPageModal = () => {
    console.log('{LessonTaskPageModal}');
    const [Modal, setLessonTaskPageModal] = useState({
        show: false,
        Content: () => (<></>),
        exitMode: false
    });
    StateUpdators.setLessonTaskPageModal = setLessonTaskPageModal;
    return (
        <>
        <Dialog
            fullScreen
            open={Modal.show}
            className='fgfg-werdc-jfhfh'
        >
        {
            Modal.show &&
            <div className={`hgsg-full animate__animated ${Modal.exitMode?`${Modal.exitMode}`:'animate__fadeIn'} rounded bkg-white w-100 modal-2-full shadow`}>
                <Modal.Content />
            </div>
        }
        </Dialog>
        </>
    )
}

export default LessonTaskPageModal;