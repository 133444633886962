import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import StateUpdators from '../lib/StateUpdators';
import Loader from './tools/Loader';
import RenderLessonNewLook from './tools/RenderLessonNewLook';
import Utils from '../lib/Utils';
import Error from './tools/Error';
import ActionModal2 from './tools/ActionModal2';
import ReportError from './tools/ReportError';


/**
 * Displays a lesson/class
 * @returns 
 */
const Lesson = () => {
    console.log('{Lesson}');
    const params = useParams();
    const token = JSON.parse(Utils.base64Decode(params.token));
    const [lesson, setLesson] = useState({
        ready: false,
        content: {}
    });
    StateUpdators.setLesson = setLesson;
    useEffect(() => {
        Utils.fetchLesson({
            lessonId: token.lessonId,
            courseId: token.token.courseId
        });
        Utils.addAnalytics({
            page: 'Lesson',
            lessonId: token.lessonId,
            url: window.location.href,
            title: 'NS.LessonPageVisit'
        });
    },[token.lessonId,token.token.courseId]);
    return (
        <>
        {
            lesson.ready ? <RenderLessonNewLook content={lesson.content} /> : <Loader />
        }
        <Error />
        <ReportError />
        <Loader />
        <ActionModal2 />
        </>
    )
}

export default Lesson;