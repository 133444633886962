import React, { useEffect, useState } from 'react';

/**
 * Score Animation
 * @param {number} param0 The score animation
 * @returns 
 */
const ScoreAnimation = ({score}) => {
    //console.log('{ScoreAnimation}');
    const [_score, setScore] = useState(0);
    useEffect(() => {
        const animId = setInterval(() => {
            if(_score < score) {
                setScore(_score => _score+1);
            } else {
                clearInterval(animId);
            }
        },30);
        return () => clearInterval(animId);
    });
    return (
        <>
        <div className='nhdgd-score shadow'>
            <span>{_score}%</span>
            <div className='hffg-hsgs' style={{
                height: `${_score}px`
            }}></div>
        </div>
        </>
    )
}

export default ScoreAnimation;