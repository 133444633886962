import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Signup from './Signup';
import Login from './Login';
import BaseName from '../lib/BaseName';
import Curriculum from './Curriculum';
import Subscribe from './Subscribe';
import SubscriptionSuccessful from './SubscriptionSuccessful';
import SubscriptionCancelled from './SubscriptionCancelled';
import Course from './Course';
import PathNotFound from './tools/PathNotFound';
import Support from './Support';
import Lesson from './Lesson';
import Logout from './Logout';
import SetupUserAccess from './SetupUserAccess';
import Quiz from './Quiz';
import QuizClearSession from './QuizClearSession';
import UserLoggedIn from './UserLoggedIn';
import store from '../lib/StateStore';
import { Provider } from 'react-redux';
import MyAccount from './MyAccount';
import DigitalBadgePage from './tools/DigitalBadgePage';
import Settings from './Settings';
import ManageStudents from './ManageStudents';
import EducatorDashboard from './EducatorDashboard';
import HelpDocs from './HelpDocs';
import ExpiredSubscription from './ExpiredSubscription';
import OperationWasOk from './tools/OperationWasOk';
import Payments from './Payments';
import WebContent from './WebContent';
import StudentAccessClosed from './StudentAccessClosed'

/**
 * The C4K App
 * @returns React Comp
 */
const C4KApp = () => {
    console.log('{C4KApp}');
    return (
        <Provider store={store}>
            <BrowserRouter basename={BaseName.name}>
                <Routes>
                    <Route path='/:rl?' element={<Home />} />
                    <Route path='logout' element={<Logout />} />
                    <Route path='me/:completeAccount?' element={<MyAccount />} />
                    <Route path='settings' element={<Settings />} />
                    <Route path='students/:groups?' element={<ManageStudents />} />
                    <Route path='dbs' element={<DigitalBadgePage />} />
                    <Route path='edudash' element={<EducatorDashboard />} />
                    <Route path='register/:token?' element={<Signup />} />
                    <Route path='createAccount/:token?' element={<Signup />} />
                    <Route path='signup/:token?' element={<Signup />} />
                    <Route path='login/:returnURL?' element={<Login />} />
                    <Route path='curriculum/:curriculumId?' element={<Curriculum />} />
                    <Route path='subscribe/:token?' element={<Subscribe />} />
                    <Route path='payment_successful/:token?' element={<SubscriptionSuccessful />} />
                    <Route path='payment_cancelled/:token?' element={<SubscriptionCancelled />} />
                    <Route path='pay/:token?' element={<Payments />} />
                    <Route path='course/:token' element={<Course />} />
                    <Route path='lesson/:token' element={<Lesson />} />
                    <Route path='support/:token?' element={<Support />} />
                    <Route path='access/:token?' element={<SetupUserAccess />} />
                    <Route path='loggedIn/:token?' element={<UserLoggedIn />} />
                    <Route path='quiz/:token?' element={<Quiz />} />
                    <Route path='quiz-logout' element={<QuizClearSession />} />
                    <Route path='help' element={<HelpDocs />} />
                    <Route path='expired-subscription/:token?' element={<ExpiredSubscription />} />
                    <Route path='content/:page?' element={<WebContent />} />
                    <Route path='access_closed' element={<StudentAccessClosed />} />
                    <Route path='code' element={<OperationWasOk />} />
                    <Route path='*' element={<PathNotFound />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    )
}

export default C4KApp;