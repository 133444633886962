import React,{useState} from 'react';
import { Button } from '@mui/material';
import Utils from '../../lib/Utils';
import UserAvatar from './UserAvatar';
import { Save } from '@mui/icons-material';
import StateUpdators from '../../lib/StateUpdators';

/**
 * Student profile completion form
 * @returns 
 */
const StudentCompleteProfileForm = ({shouldUpdateAvatar,userAvatar}) => {
    console.log('{StudentCompleteProfileForm}');
    const [_input, setInput] = useState({
        firstname: '',
        lastname: '',
        othernames: '',
        email: '',
        userAvatar: userAvatar
    });
    const [saveButton, setSaveButtonState] = useState({
        disabled: false
    });
    StateUpdators.setSaveButtonState = setSaveButtonState;
    console.log('_input=',_input);
    return (
        <>
        <div className='hhdg-sjdg-studentldjd'>
            <div className='gsgdg-mdhdg w-90 h-centred'>
                <div className='profile-pic'>
                    <UserAvatar shouldUpdateAvatar={shouldUpdateAvatar} src={_input.userAvatar} />
                </div>
                <div>
                    <h3>Your profile is incomplete.</h3>
                    <p>Please complete your profile first.</p>
                </div>
            </div>
            <p className='fildjfg-jfhf'>Fields marked <b className='asteroicmjdhd'>*</b> are required</p>
            <div className='input-formhdhdg-s w-90 centred ndgdg-jhfgf-input'>
                        <div className='hoverrable-input'>
                            <p className='bhdh-f-input-label'>Firstname:<b className='asteroicmjdhd'>*</b></p>
                            <input type='text' placeholder='Your firstname' className='input-bcbb' value={_input.firstname} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    firstname: e.currentTarget.value
                                })
                            }} />
                        </div>
                        <div className='hoverrable-input'>
                            <p className='bhdh-f-input-label'>Lastname:<b className='asteroicmjdhd'>*</b></p>
                            <input type='text' placeholder='Your lastname' className='input-bcbb' value={_input.lastname} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    lastname: e.currentTarget.value
                                })
                            }} />
                        </div>
                        <div className='hoverrable-input'>
                            <p className='bhdh-f-input-label'>Othernames: (optional)</p>
                            <input type='text' placeholder='e.g. initials or middle names' className='input-bcbb' value={_input.othernames} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    othernames: e.currentTarget.value
                                })
                            }} />
                        </div>
                        <div className='hoverrable-input'>
                            <p className='bhdh-f-input-label'>Email: (optional)</p>
                            <input type='text' placeholder='Your email' className='input-bcbb' value={_input.email} onChange={(e) => {
                                setInput({
                                    ..._input,
                                    email: e.currentTarget.value
                                })
                            }} />
                        </div>

                        <div className='btn-begin jjfhfhf w-80 centred'>
                            <Button disabled={saveButton.disabled} title='Continue' variant='contained' className='force-round-btn' onClick={() => {
                                if(!_input.firstname) {
                                    Utils.showFeedbackError({msg: 'Plase enter your firstname'});
                                    return;
                                }
                                if(!_input.lastname) {
                                    Utils.showFeedbackError({msg: 'Plase enter your lastname'});
                                    return;
                                }
                                if(_input.email && !Utils.isValidEmail(_input.email)) {
                                    Utils.showFeedbackError({msg: 'Plase enter a valid email address'});
                                    return;
                                }
                                setSaveButtonState({
                                    disabled: true
                                })
                                console.log(_input);
                                Utils.saveCandidateInfo(_input);
                            }}>
                                <Save />
                                <span className='btn-text-with-icon'>save</span>
                            </Button>
                        </div>
                    </div>
        </div>
        </>
    )
}

export default StudentCompleteProfileForm;