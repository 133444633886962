import React from 'react';
import Assets from '../../lib/Assets';
import Texts from '../../lib/Texts';

/**
 * C4K Logo View
 * @returns 
 */
const C4KLogo = () => {
    console.log('{C4KLogo}');

    return (
        <div className='kjs-ldoe content-centre'>
            <div className='jdhs-iroes'>
                <div>
                    <img className='nhd-oiei ptr' title={Texts.company} src={Assets.c4kLogo} alt='Computers 4 Kids logo' />
                </div>
                <div className='content-centre ptr' title={Texts.company}>
                    <h3 className='spt-hdr-txt-color spt-hdr-txt-no-mgn jdhd-dps'>{Texts.company}</h3>
                </div>
            </div>
        </div>
    )
}

export default C4KLogo;