import React, { useEffect, useState } from 'react';
import Assets from '../../lib/Assets';
import StateUpdators from '../../lib/StateUpdators';
import Utils from '../../lib/Utils';


/**
 * Candidate Details
 * @param {string} accessToken The access token for the student 
 * @returns 
 */
const LessonQuizCandidateDetails = ({accessToken}) => {
    console.log('{LessonQuizCandidateDetails}');
    accessToken = {};
    const userInfo = Utils.getCachedUserInfoOnPretest();
    const [_details, setCandidateDetails] = useState({
        firstname: userInfo ? userInfo.firstname[0] : '',
        lastname: userInfo ? userInfo.lastname : '',
        email: userInfo ? userInfo.email : '',
        emailHash: userInfo ? userInfo.emailHash: ''
    });
    const [avatar, setUserAvatar] = useState({
        avatar: Assets.icons.userAvatar,
        avatarTitle: 'Candidate'
    })
    StateUpdators.setCandidateDetails = setCandidateDetails;
    useEffect(() => {
        const _image = new Image();
        _image.src = `https://www.gravatar.com/avatar/${_details.emailHash}`;
        _image.onload = () => {
            if(_image.width > 0) {
                setUserAvatar({
                    avatar: `https://www.gravatar.com/avatar/${_details.emailHash}`,
                    avatarTitle: `Candidate: ${userInfo.firstname} ${userInfo.lastname}`
                });
            }
        }
    },[_details,userInfo.firstname, userInfo.lastname]);
    return (
        <>
        <div className='bfdg-cand-details centred'>
            <div className='icon-hfhfg-usr'>
                <img className='round-avatar hhgfg-av rounded ptr' src={avatar.avatar} title={avatar.avatarTitle} alt={avatar.avatarTitle} />
            </div>
            <div className='jhf-ur-name bbgsj-jghg'>
                <h3>{_details.firstname}. {_details.lastname}</h3>
                <p id='ndjdh-jdhdh' className='u-name-handle ptr ndjdh-jdhdh' title={`${_details.email}`}>{_details.email}</p>
            </div>
        </div>
        </>
    )
}

export default LessonQuizCandidateDetails;