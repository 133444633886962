/**
 * State Store manager
 */
import { configureStore } from '@reduxjs/toolkit';
import courseInfoStateManager from './StateManagers/CourseInfo';

/**
 * Configure our store
 */
export default configureStore({
    reducer: {
        courseInfo: courseInfoStateManager
   }
});