import React, { useEffect } from 'react';
import Utils from '../../lib/Utils';
import BaseName from '../../lib/BaseName';
import CustomImage from './CustomImage';
import AccountHeader from './AccountHeader';
import Texts from '../../lib/Texts';
import LinkToOldCurriculums from './LinkToOldCurriculums';
import TrilaInfo from './TrilaInfo';

/**
 * Render curriculums. Renders 
 * @param {object} content The content containing subscriptions 
 * @returns 
 */
const RenderCurriculums = ({content}) => {
    console.log('{RenderCurriculms}');
    //console.log('{RenderCurriculms}: content=',content);
    useEffect(() => {
        Utils.setPageTitleFull({
            title: `Computers 4 Kids | Classesonline > Your Curriculums`
        })
    });
    return (
        <>
        <AccountHeader showPopupIfAccountIncomplete={true} />
        <div className='bbdg-curriculums-fhgf animate__animated animate__fadeIn'>
            <div className='w-80 h-centred bkg-w rounded nchfh-kfhf shadow box-b'>
                <h2>Your Curriculums</h2>
                {
                    content.isTrial ? <TrilaInfo expiresOn={content.expiresOn} /> : <></>
                }
                <div className='nfhdh-suvbs-jshs box-b'>
                    {
                        content.subscriptions.map((subscription,key) => {
                            return (
                                <div title={`${!subscription.hasExpired?`Go to ${subscription.curriculum}`:Texts.expired}`} key={key} className={`ptr hfgfg-ghd-nghg rounded bdr-bl shadow`} onClick={() => {
                                    if(!subscription.hasExpired) {
                                        window.location = `/${BaseName.name}/curriculum/${subscription.curriculumId}`;
                                    } else {
                                        Utils.handleError({
                                            msg: Texts.subscrExpired.replace('[$subscription]',subscription.curriculum)
                                        });
                                    }
                                }}>
                                    <div className='bgdg-icons-nfbfg rounded-top nsks-ljdks'>
                                        <CustomImage className={`rounded-top ${subscription.hasExpired?'hshs-jexpired-subdhs':''}`} alt='Subscription' src={`${Utils._assetsURL}${subscription.curriculumExtraInfo.iconId}`} />
                                        {
                                            subscription.hasExpired &&
                                            <div title={Texts.expired} className='ptr jshs-jdisos box-b bkg-wt shadow'>
                                                <p>Expired on: {subscription.expiryDate.split(' ')[0]}</p>
                                            </div>
                                        }
                                    </div>
                                    <div className='hdgdg-name-jh bvgg-gjhgh box-b rounded-bottom'>
                                        <h3>{subscription.curriculum}</h3>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <LinkToOldCurriculums />
            </div>
        </div>
        </>
    )
}

export default RenderCurriculums;