import React, { useState } from 'react';
import Separator from './Separator';
import Assets from '../../lib/Assets';
import Utils from '../../lib/Utils';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import CustomImage from './CustomImage';

/**
 * Educator Dashboard Sections Content Renderers. Some sections have unique content that must be rendered differently
 */
const EducatorDashboardSectionsContentRenderers = {
    /**
     * Curriculum overviews content renderer
     * @param {object} content The content object 
     */
    CurriculumOverviews: ({content}) => {
        console.log('{CurriculumOverviews}');
        return (
            <>
            <div
                className={`animate__animated animate__fadeIn centred jhsg-jfh gsfsf-jdhd-self-and-peer`} 
                dangerouslySetInnerHTML={{__html: content[0].content}}
            >
            </div>
            <div className='nsgs-docs w-90 centred box-b'>
            {
                content[0].docs.map((doc,i) => {
                    return (
                        <div onClick={() => {
                            Utils.goTo({url: `${Utils.c4kAssetsURL}${doc.docId}`, inNewWindow: true});
                            Utils.addAnalytics({
                                page: 'EducatorDashboard',
                                url: window.location.href,
                                title: `NS.EducatorDashboard.AssetAccesss.CurriculumOverviews`,
                                doc: `Resource.${doc.docId}`
                            });
                        }} key={i} className='shsh-kdjdh-lpws shadow rounded ptr' title={`View document: ${doc.name}`}>
                            <div className='doc-typs-jdhd-s'>
                                <CustomImage className='khsjm-lldodoe-ls' src={`${Utils.c4kAssetsURL}/curriculums/icons/${doc.icon}`} alt={'Doc'} />
                            </div>
                            <div className='jshs-jdhd-lsj'>
                                <p>{doc.name}</p>
                            </div>
                        </div>
                    )
                })
            }
            </div>
            <Separator styles={{height: '50px'}} />
            </>
        )
    },
    /**
     * Render ForTheClassroom content
     * @param {object} content The content object 
     */
    ForTheClassroom: ({content}) => {
        console.log('{EducatorDashboardSectionsContentRenderers.ForTheClassroom}');
        const _Renderers = {
            /**
             * Render Classroom Posters content
             * @param {object} __content The content object
             */
            ClassroomPosters: ({__content}) => {
                console.log('{ClassroomPosters}');
                return (
                    <>
                    <div
                        className={`animate__animated animate__fadeIn centred jhsg-jfh gsfsf-jdhd-self-and-peer`} 
                        dangerouslySetInnerHTML={{__html: __content.content}}
                    >
                    </div>
                    <div className='nsgs-docs w-80 centred kjs-ieyeo'>
                    {
                        __content.docs.map((doc,i) => {
                            return (
                                <div onClick={() => {
                                    Utils.goTo({url: `${Utils.c4kAssetsURL}${doc.docId}`, inNewWindow: true});
                                    Utils.addAnalytics({
                                        page: 'EducatorDashboard',
                                        url: window.location.href,
                                        title: `NS.EducatorDashboard.AssetAccesss.ClassroomPosters`,
                                        doc: `Resource.${doc.docId}`
                                    });
                                }} key={i} className='shsh-kdjdh shadow rounded ptr' title={`Download document: ${doc.name}`}>
                                    <div className='doc-typs-jdhd'>
                                        <img alt='Doc type' src={Assets.fileTyeps[doc.type]} />
                                    </div>
                                    <div className='jshs-jdhd'>
                                        <p>{doc.name}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    <Separator styles={{height: '50px'}} />
                    </>
                )
            },
            /**
             * Render NumberLabels content
             * @param {object} __content The content object
             */
            NumberLabels: ({__content}) => {
                console.log('{NumberLabels}');
                return (
                    <>
                    <div
                        className={`animate__animated animate__fadeIn centred jhsg-jfh gsfsf-jdhd-self-and-peer`} 
                        dangerouslySetInnerHTML={{__html: __content.content}}
                    >
                    </div>
                    <div className='nsgs-docs w-80 centred kjs-ieyeo'>
                    {
                        __content.docs.map((doc,i) => {
                            return (
                                <div onClick={() => {
                                    Utils.goTo({url: `${Utils.c4kAssetsURL}${doc.docId}`, inNewWindow: true});
                                    Utils.addAnalytics({
                                        page: 'EducatorDashboard',
                                        url: window.location.href,
                                        title: `NS.EducatorDashboard.AssetAccesss.NumberLabels`,
                                        doc: `Resource.${doc.docId}`
                                    });
                                }} key={i} className='shsh-kdjdh shadow rounded ptr' title={`Download document: ${doc.name}`}>
                                    <div className='doc-typs-jdhd'>
                                        <img alt='Doc type' src={Assets.fileTyeps[doc.type.toLocaleUpperCase()]} />
                                    </div>
                                    <div className='jshs-jdhd'>
                                        <p>{doc.name}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    </>
                )
            },
            /**
             * Render MathsWorksheets content
             * @param {object} __content The content object
             */
            MathsWorksheets: ({__content}) => {
                console.log('{MathsWorksheets}');
                return (
                    <>
                    <div
                        className={`animate__animated animate__fadeIn centred jhsg-jfh gsfsf-jdhd-self-and-peer`} 
                        dangerouslySetInnerHTML={{__html: __content.content}}
                    >
                    </div>
                    <div className='nsgs-docs w-80 centred kjs-ieyeo'>
                    {
                        __content.docs.map((doc,i) => {
                            return (
                                <div onClick={() => {
                                    Utils.goTo({url: `${Utils.c4kAssetsURL}${doc.docId}`, inNewWindow: true});
                                    Utils.addAnalytics({
                                        page: 'EducatorDashboard',
                                        url: window.location.href,
                                        title: `NS.EducatorDashboard.AssetAccesss.MathsWorksheets`,
                                        doc: `Resource.${doc.docId}`
                                    });
                                }} key={i} className='shsh-kdjdh shadow rounded ptr' title={`Download document: ${doc.name}`}>
                                    <div className='doc-typs-jdhd'>
                                        <img alt='Doc type' src={Assets.fileTyeps[doc.type.toLocaleUpperCase()]} />
                                    </div>
                                    <div className='jshs-jdhd'>
                                        <p>{doc.name}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    </>
                )
            },
            /**
             * Render NumeracySoftwareResources content
             * @param {object} __content The content object
             */
            NumeracySoftwareResources: ({__content}) => {
                console.log('{NumeracySoftwareResources}');
                const [value, setValue] = React.useState('1');
                const handleChange = (event, newValue) => {
                    event = {};
                    setValue(newValue);
                };
                return (
                    <>
                    <div
                        className={`animate__animated animate__fadeIn centred jhsg-jfh gsfsf-jdhd-self-and-peer`} 
                        dangerouslySetInnerHTML={{__html: __content.content}}
                    >
                    </div>
                    <div className='nsgs-docs w-80 centred'>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label='Resources'>
                                <Tab label='MS Excel' value={'1'} />
                                <Tab label='MS PowerPoint' value={'2'} />
                                <Tab label='PDF' value={'3'} />
                                <Tab label='ZIP' value={'4'} />
                            </TabList>
                        </Box>
                        <>
                        {
                            Object.entries(__content.docs).map((entry, key) => {
                                const values = {
                                    excel: '1',
                                    ppoint: '2',
                                    pdf: '3',
                                    zip: '4'
                                };
                                const value = values[entry[0]];
                                const _items = entry[1].length;
                                return (
                                    <TabPanel key={key} value={value}>
                                        <div className={`nsgs-docs w-80 animate__animated animate__fadeIn centred kjs-ieyeo only-items-${(_items)}`}>
                                        {
                                            entry[1].map((doc,i) => {
                                                return (
                                                    <div onClick={() => {
                                                        Utils.goTo({url: `${Utils.c4kAssetsURL}${doc.docId}`, inNewWindow: true});
                                                        Utils.addAnalytics({
                                                            page: 'EducatorDashboard',
                                                            url: window.location.href,
                                                            title: `NS.EducatorDashboard.AssetAccesss.NumeracySoftwareResources`,
                                                            doc: `Resource.${doc.docId}`
                                                        });
                                                    }} key={i} className='shsh-kdjdh shadow rounded ptr' title={`Download document: ${doc.name}`}>
                                                        <div className='doc-typs-jdhd'>
                                                            <img alt='Doc type' src={Assets.fileTyeps[doc.type.toLocaleUpperCase()]} />
                                                        </div>
                                                        <div className='jshs-jdhd'>
                                                            <p>{doc.name}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>
                                    </TabPanel>
                                )
                            })
                        }
                        </>
                    </TabContext>
                    </div>
                    </>
                )
            },
            /**
             * Render PowerPackWorksheets content
             * @param {object} __content The content object
             */
            PowerPackWorksheets: ({__content}) => {
                console.log('{PowerPackWorksheets}');
                return (
                    <>
                    <div
                        className={`animate__animated animate__fadeIn centred jhsg-jfh gsfsf-jdhd-self-and-peer`} 
                        dangerouslySetInnerHTML={{__html: __content.content}}
                    >
                    </div>
                    <div className='nsgs-docs w-80 centred kjs-ieyeo'>
                    {
                        __content.docs.map((doc,i) => {
                            return (
                                <div onClick={() => {
                                    Utils.goTo({url: `${Utils.assetsURL}DSC/teacher_resources/${doc.docId}`, inNewWindow: true});
                                    Utils.addAnalytics({
                                        page: 'EducatorDashboard',
                                        url: window.location.href,
                                        title: `NS.EducatorDashboard.AssetAccesss.PowerPackWorksheets`,
                                        doc: `Resource.${doc.docId}`
                                    });
                                }} key={i} className='shsh-kdjdh shadow rounded ptr' title={`Download document: ${doc.name}`}>
                                    <div className='doc-typs-jdhd'>
                                        <img alt='Doc type' src={Assets.fileTyeps[doc.type.toLocaleUpperCase()]} />
                                    </div>
                                    <div className='jshs-jdhd'>
                                        <p>{doc.name}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    <Separator styles={{height: '50px'}} />
                    </>
                )
            },
            /**
             * Render EmotionalIntelligence content
             * @param {object} __content The content object
             */
            EmotionalIntelligence: ({__content}) => {
                console.log('{EmotionalIntelligence}');
                return (
                    <>
                    <div
                        className={`animate__animated animate__fadeIn centred jhsg-jfh gsfsf-jdhd-self-and-peer`} 
                        dangerouslySetInnerHTML={{__html: __content.content}}
                    >
                    </div>
                    <div className='nsgs-docs w-80 centred kjs-ieyeo'>
                    {
                        __content.docs.map((doc,i) => {
                            return (
                                <div onClick={() => {
                                    Utils.goTo({url: `${Utils.c4kAssetsURL}${doc.docId}`, inNewWindow: true});
                                    Utils.addAnalytics({
                                        page: 'EducatorDashboard',
                                        url: window.location.href,
                                        title: `NS.EducatorDashboard.AssetAccesss.EmotionalIntelligence`,
                                        doc: `Resource.${doc.docId}`
                                    });
                                }} key={i} className='shsh-kdjdh shadow rounded ptr' title={`Download document: ${doc.name}`}>
                                    <div className='doc-typs-jdhd'>
                                        <img alt='Doc type' src={Assets.fileTyeps[doc.type.toLocaleUpperCase()]} />
                                    </div>
                                    <div className='jshs-jdhd'>
                                        <p>{doc.name}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    <div className='nsgs-docs w-80 centred kjs-ieyeo'>
                    {
                        __content.links.map((link,i) => {
                            return (
                                <div key={i} className='shsh-kdjdh-lsks w-90 centred shadow rounded bdr-bl ptr'>
                                    <div className='dd-odjs-ksj'>
                                        <img alt='Org logo' src={`${Utils.c4kAssetsURL}third_parties/logos/${link.logo}`} />
                                    </div>
                                    <div className='jshs-jdhd-loe'>
                                        <div className='nhsh-2zosls'>
                                            <h3>{link.title}</h3>
                                        </div>
                                        <div className='nsgs-pwms box-b' dangerouslySetInnerHTML={{__html: link.desc}}>
                                        </div>
                                        <div className='hs-ieslks-lsoe'>
                                            <div className='hgsh-iwuwkd'>
                                                <Button onClick={() => {
                                                    Utils.goTo({url: link.url, inNewWindow: true});
                                                    Utils.addAnalytics({
                                                        page: 'EducatorDashboard',
                                                        url: window.location.href,
                                                        title: `NS.EducatorDashboard.AssetAccesss.EmotionalIntelligence.Link`,
                                                        action: `FollowLink.${link.url}`
                                                    });
                                                }} variant='contained' color='primary' className='ptr force-round-btn' title={`Visit ${link.title} website`}>
                                                    Visit {link.title} website
                                                </Button>
                                            </div>
                                            <div className='nhdg-kfjd'>
                                                <Button onClick={() => {
                                                    Utils.goTo({url: link.getStartedUrl, inNewWindow: true});
                                                    Utils.addAnalytics({
                                                        page: 'EducatorDashboard',
                                                        url: window.location.href,
                                                        title: `NS.EducatorDashboard.AssetAccesss.EmotionalIntelligence.Link`,
                                                        action: `FollowLink.GetStarted.${link.getStartedUrl}`
                                                    });
                                                }} variant='contained' color='secondary' className='ptr force-round-btn' title={`Get started with ${link.title}`}>
                                                    Get Started
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    </>
                )
            },
        }
        return (
            <>
            {
                Array.isArray(content) ?
                <>
                {
                    content.map((cont,_key) => {
                        const RenderContent = _Renderers[cont.name];
                        return (
                            <React.Fragment key={_key}>
                               <RenderContent __content={cont} /> 
                            </React.Fragment>
                        )
                    })
                }
                </>
                :
                <>
                <div className='error'>
                    <p className='error'>This renderer expects `content` to be an array, found `{typeof content}`.</p>
                </div>
                </>
            }
            </>
        )
    },
    /**
     * Render plan and organize section content
     * @param {object} content The content to render
     * @returns 
     */
    PlanAndOrganize: ({content}) => {
        console.log('{EducatorDashboardSectionsContentRenderers.PlanAndOrganize}');
        const _Renderers = {
            /**
             * Render PlanningTables  content
             * @param {object} __content The content object to render
             * @returns 
             */
            PlanningTables: ({__content}) => {
                console.log('{PlanAndOrganize.PlanningTables}');
                const lessons = {};
                const [volume, setVolumeOnSelection] = useState({
                    name: "Volume R",
                    id: "7"
                });
                const _volumeNumbers = {
                    7: 'R',
                    8: 1,
                    9: 2,
                    10: 3,
                    11: 4,
                    12: 5,
                    13: 6,
                    14: 7
                };
                return (
                    <>
                    <div
                        className={`animate__animated animate__fadeIn centred jhsg-jfh gsfsf-jdhd-self-and-peer`} 
                        dangerouslySetInnerHTML={{__html: __content.content}}
                    >
                    </div>
                    <div className='nsgs-docs w-80 centred'>
                        <FormControl fullWidth>
                            <InputLabel id='assess-volumes-select-label'>Volume</InputLabel>
                            <Select
                                labelId='assess-volumes-select-label'
                                id='assess-volumes-select'
                                value={JSON.stringify(volume)}
                                label={volume.name}
                                onChange={(e) => {
                                    setVolumeOnSelection(JSON.parse(e.target.value));
                                }}
                            >
                                {
                                    Object.entries(__content.docs.volumes).map((vol, _k) => {
                                        const docType = 'pdf';
                                        const fileExt = 'pdf';
                                        lessons[vol[0]] = [];
                                        for(let lesson = 1; lesson <= vol[1]; lesson++) {
                                            lessons[vol[0]].push(
                                                () => 
                                                <div onClick={() => {
                                                    Utils.goTo({url: `${Utils.assetsURL}/DSC/${__content.docs.docsPath}/l${vol[0]}_${lesson}.${fileExt}`, inNewWindow: true});
                                                    Utils.addAnalytics({
                                                        page: 'EducatorDashboard',
                                                        url: window.location.href,
                                                        title: `NS.EducatorDashboard.PlanAndOrganize.PlanningTables`,
                                                        doc: `PlanningTables.${__content.docs.docsPath}/l${vol[0]}_${lesson}.${fileExt}`
                                                    });
                                                }} className='shsh-kdjdh shadow rounded ptr animate__animated animate__fadeIn' title={`Download document: ${`for Lesson ${lesson}`}`}>
                                                    <div className='doc-typs-jdhd'>
                                                        <img alt='Doc type' src={Assets.fileTyeps[docType.toLocaleUpperCase()]} />
                                                    </div>
                                                    <div className='jshs-jdhd'>
                                                        <p>{`Lesson ${lesson}`}</p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return (
                                            <MenuItem key={_k} value={JSON.stringify({name: `Volume ${_volumeNumbers[vol[0]]}`, id: vol[0]})}>{`Voume ${_volumeNumbers[vol[0]]}`}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className='nsgs-docs hdgs-kfhfh w-80 centred'>
                        {
                            lessons[volume.id].map((Lesson, _i) => {
                                return (
                                    <React.Fragment key={_i}>
                                        <Lesson />
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                    <Separator styles={{height: '50px'}} />
                    </>
                )
            },
            /**
             * Render Templates  content
             * @param {object} __content The content object to render
             * @returns 
             */
            Templates: ({__content}) => {
                console.log('{PlanAndOrganize.Templates}')
                return (
                    <>
                    <div className='w-90 centred'>
                        <div
                            className={`animate__animated animate__fadeIn centred jhsg-jfh gsfsf-jdhd-self-and-peer`} 
                            dangerouslySetInnerHTML={{__html: __content.content}}
                        >
                        </div>
                        <div className='nsgs-docs w-80 centred'>
                            {
                                __content.docs.map((doc,i) => {
                                    const docType = doc.docId.split('.')[1].toLocaleUpperCase();
                                    return (
                                        <div onClick={() => {
                                            Utils.goTo({url: `${Utils.assetsURL}/DSC/teacher_resources/${doc.docId}`, inNewWindow: true});
                                            Utils.addAnalytics({
                                                page: 'EducatorDashboard',
                                                url: window.location.href,
                                                title: `NS.EducatorDashboard.AssetAccesss.PlanAndOrganize`,
                                                doc: `Templates.${doc.docId}`
                                            });
                                        }} key={i} className='shsh-kdjdh shadow rounded ptr' title={`Download document: ${doc.name}`}>
                                            <div className='doc-typs-jdhd'>
                                                <img alt='Doc type' src={Assets.fileTyeps[docType]} />
                                            </div>
                                            <div className='jshs-jdhd'>
                                                <p>{doc.name}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <Separator styles={{height: '50px'}} />
                    </div>
                    </>
                )
            },
            /**
             * Render FileCoversandDividers  content
             * @param {object} __content The content object to render
             * @returns 
             */
            FileCoversandDividers: ({__content}) => {
                console.log('{PlanAndOrganize.FileCoversandDividers}')
                return (
                    <>
                    <div className='w-90 centred'>
                        <div
                            className={`animate__animated animate__fadeIn centred jhsg-jfh gsfsf-jdhd-self-and-peer`} 
                            dangerouslySetInnerHTML={{__html: __content.content}}
                        >
                        </div>
                        <div className='nsgs-docs w-80 centred'>
                            {
                                __content.docs.map((doc,i) => {
                                    const docType = doc.docId.split('.')[1].toLocaleUpperCase();
                                    return (
                                        <div onClick={() => {
                                            Utils.goTo({url: `${Utils.assetsURL}/DSC/teacher_resources/${doc.docId}`, inNewWindow: true});
                                            Utils.addAnalytics({
                                                page: 'EducatorDashboard',
                                                url: window.location.href,
                                                title: `NS.EducatorDashboard.AssetAccesss.PlanAndOrganize`,
                                                doc: `FileCoversandDividers.${doc.docId}`
                                            });
                                        }} key={i} className='shsh-kdjdh shadow rounded ptr' title={`Download document: ${doc.name}`}>
                                            <div className='doc-typs-jdhd'>
                                                <img alt='Doc type' src={Assets.fileTyeps[docType]} />
                                            </div>
                                            <div className='jshs-jdhd'>
                                                <p>{doc.name}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <Separator styles={{height: '50px'}} />
                    </div>
                    </>
                )
            }
        };
        return (
            <>
            {
                Array.isArray(content) ?
                <>
                {
                    content.map((cont,_key) => {
                        const RenderContent = _Renderers[cont.name];
                        return (
                            <React.Fragment key={_key}>
                               <RenderContent __content={cont} /> 
                            </React.Fragment>
                        )
                    })
                }
                </>
                :
                <>
                <div className='error'>
                    <p className='error'>This renderer expects `content` to be an array, found `{typeof content}`.</p>
                </div>
                </>
            }
            </>
        )
    },
    /**
     * Render assessments and feedback section content
     * @param {object} content The content to render
     * @returns 
     */
    AssessmentsAndFeedback: ({content}) => {
        console.log('{EducatorDashboardSectionsContentRenderers.AssessmentsAndFeedback}');
        const _Renderers = {
            /**
             * Render FormativeSummativeAssessments  content
             * @param {object} __content The content object to render
             * @returns 
             */
            FormativeSummativeAssessments: ({__content}) => {
                console.log('{AssessmentsAndFeedback.FormativeSummativeAssessments}');
                const lessons = {};
                const [volume, setVolumeOnSelection] = useState({
                    name: 'Volume R',
                    id: 'R'
                });
                return (
                    <>
                    <div
                        className={`animate__animated animate__fadeIn centred jhsg-jfh gsfsf-jdhd-self-and-peer`} 
                        dangerouslySetInnerHTML={{__html: __content.content}}
                    >
                    </div>
                    <div className='nsgs-docs w-80 centred'>
                        <FormControl fullWidth>
                            <InputLabel id='assess-volumes-select-label'>Volume</InputLabel>
                            <Select
                                labelId='assess-volumes-select-label'
                                id='assess-volumes-select'
                                value={JSON.stringify(volume)}
                                label={volume.name}
                                defaultValue=''
                                onChange={(e) => {
                                    setVolumeOnSelection(JSON.parse(e.target.value));
                                }}
                            >
                                {
                                    Object.entries(__content.docs.volumes).map((vol, _k) => {
                                        const docType = 'docx';
                                        const fileExt = 'doc';
                                        lessons[vol[0]] = [];
                                        for(let lesson = 1; lesson <= vol[1]; lesson++) {
                                            lessons[vol[0]].push(
                                                () => 
                                                <div onClick={() => {
                                                    Utils.goTo({url: `${Utils.assetsURL}/${__content.docs.docsPath}/V${vol[0]}_l${lesson}.${fileExt}`, inNewWindow: true});
                                                    Utils.addAnalytics({
                                                        page: 'EducatorDashboard',
                                                        url: window.location.href,
                                                        title: `NS.EducatorDashboard.AssetAccesss.FormativeSummativeAssessments`,
                                                        doc: `FormativeSummativeAssessment.${__content.docs.docsPath}/V${vol[0]}_l${lesson}.${fileExt}`
                                                    });
                                                }} className='shsh-kdjdh shadow rounded ptr animate__animated animate__fadeIn' title={`Download document: ${`for Lesson ${lesson}`}`}>
                                                    <div className='doc-typs-jdhd'>
                                                        <img alt='Doc type' src={Assets.fileTyeps[docType.toLocaleUpperCase()]} />
                                                    </div>
                                                    <div className='jshs-jdhd'>
                                                        <p>{`Lesson ${lesson}`}</p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return (
                                            <MenuItem key={_k} value={JSON.stringify({name: `Volume ${vol[0]}`, id: `${vol[0]}`})}>{`Voume ${vol[0]}`}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className='nsgs-docs hdgs-kfhfh w-80 centred'>
                        {
                            lessons[volume.id].map((Lesson, _i) => {
                                return (
                                    <React.Fragment key={_i}>
                                        <Lesson />
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                    <Separator styles={{height: '50px'}} />
                    </>
                )
            },
            /**
             * Render PeerAndSelfAssessments  content
             * @param {object} __content The content object to render
             * @returns 
             */
            PeerAndSelfAssessments: ({__content}) => {
                console.log('{AssessmentsAndFeedback.PeerAndSelfAssessments}')
                return (
                    <>
                    <div className='w-90 centred'>
                        <div
                            className={`animate__animated animate__fadeIn centred jhsg-jfh gsfsf-jdhd-self-and-peer`} 
                            dangerouslySetInnerHTML={{__html: __content.content}}
                        >
                        </div>
                        <div className='nsgs-docs w-80 centred'>
                            {
                                __content.docs.map((doc,i) => {
                                    const docType = doc.docId.split('.')[1].toLocaleUpperCase();
                                    return (
                                        <div onClick={() => {
                                            Utils.goTo({url: `${Utils.assetsURL}/DSC/teacher_resources/${doc.docId}`, inNewWindow: true});
                                            Utils.addAnalytics({
                                                page: 'EducatorDashboard',
                                                url: window.location.href,
                                                title: `NS.EducatorDashboard.AssetAccesss.AssessmentsAndFeedback`,
                                                doc: `PeerAndSelfAssessments.${doc.docId}`
                                            });
                                        }} key={i} className='shsh-kdjdh shadow rounded ptr' title={`Download document: ${doc.name}`}>
                                            <div className='doc-typs-jdhd'>
                                                <img alt='Doc type' src={Assets.fileTyeps[docType]} />
                                            </div>
                                            <div className='jshs-jdhd'>
                                                <p>{doc.name}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <Separator styles={{height: '50px'}} />
                    </div>
                    </>
                )
            }
        };
        return (
            <>
            {
                Array.isArray(content) ?
                <>
                {
                    content.map((cont,_key) => {
                        const RenderContent = _Renderers[cont.name];
                        return (
                            <React.Fragment key={_key}>
                               <RenderContent __content={cont} /> 
                            </React.Fragment>
                        )
                    })
                }
                </>
                :
                <>
                <div className='error'>
                    <p className='error'>This renderer expects `content` to be an array, found `{typeof content}`.</p>
                </div>
                </>
            }
            </>
        )
    },
    /**
     * Let this renderer handle undefined content renderer
     * @param {object} content The content to render 
     */
    DefaultRenderer: ({content}) => {
        console.log('{EducatorDashboardSectionsContentRenderers.DefaultRenderer}');
        return (
            <>
            <Separator styles={{height: '40px'}} />
            <div className='jfghdg-kjdhdg'>
                <span>Renderer not found. Using default renderer.</span>
            </div>
            <div
                className={`animate__animated animate__fadeIn centred jhsg-jfh dash-cont-default-jksjs`} 
                dangerouslySetInnerHTML={{__html: content}}
            >
            </div>
            </>
        )
    }
};

export default EducatorDashboardSectionsContentRenderers;