import React, { useState } from 'react';
import Assets from '../../lib/Assets';
import StateUpdators from '../../lib/StateUpdators';

/**
 * 
 * @param {object} props The info object
 * @returns 
 */
const CourseHeader = (props) => {
    console.log('{CourseHeader}');
    const [_info, setCourseHeaderInfo] = useState({
        title: props.title,
        courseTitle: props.courseTitle,
        subCourseTitle: props.subCourseTitle ?? ''
    });
    StateUpdators.setCourseHeaderInfo = setCourseHeaderInfo;
    return (
        <>
        {
            props.showShortName ?
            <>
            <div className={`curr-hdr-jfhfh rounded-small shadow bkg-white w-80 h-centred course-bkg-color-${props.courseColor}`}>
                <div className='bvhfh-icon rounded-left'>
                    <img title={`${props.shortName} Curriculum`} className='ptr' src={Assets.icons[props.shortName]} alt={props.shortName} />
                </div>
                <div className='fgddh-title'>
                    <div className='nvbfhf-vxfd'>
                        <h2 className='ptr nbfbfgf'>
                            <b onClick={() => {
                                if(props.onTitleClick && typeof props.onTitleClick === 'function') {
                                    props.onTitleClick();
                                }
                            }} title={_info.title}>{_info.title}</b>
                            {
                                _info.courseTitle &&
                                <b className='ptr' title={_info.courseTitle}>  &raquo;&nbsp;{_info.courseTitle}</b>
                            }
                            {
                                _info.subCourseTitle &&
                                <b className='ptr' title={_info.courseTitle}>  &raquo;&nbsp;{_info.subCourseTitle}</b>
                            }
                        </h2>
                    </div>
                </div>
            </div>
            </>:
            <>
            <div className={`curr-hdr-no-short-name rounded-small shadow bkg-white w-80 h-centred course-bkg-color-${props.courseColor}`}>
                <div className='fgddh-title'>
                    <div className='nvbfhf-vxfd'>
                        <h2 className='ptr nbfbfgf'>
                            <b onClick={() => {
                                if(props.onTitleClick && typeof props.onTitleClick === 'function') {
                                    props.onTitleClick();
                                }
                            }} title={_info.title}>{_info.title}</b>
                        {
                            _info.courseTitle &&
                            <b className='ptr' onClick={() => {
                                if(props.onCourseTitleClick && typeof props.onCourseTitleClick === 'function') {
                                    props.onCourseTitleClick();
                                }
                            }} title={_info.courseTitle}>  &raquo;&nbsp;{_info.courseTitle}</b>
                        }
                        {
                            _info.subCourseTitle &&
                            <b className='ptr' title={_info.courseTitle}>  &raquo;&nbsp;{_info.subCourseTitle}</b>
                        }
                        </h2>
                    </div>
                </div>
            </div>
            </>
        }
        </>
    )
}

export default CourseHeader;