import React, { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Utils from '../../lib/Utils';
import { Button, FormControl, InputAdornment, TextField } from '@mui/material';
import { Email } from '@mui/icons-material';
import Separator from './Separator';
import SmallLoader from './SmallLoader';
import StateUpdators from '../../lib/StateUpdators';
import LoginFormViewLoginPart from './LoginFormViewLoginPart';
import Texts from '../../lib/Texts';


/**
 * The ResetPasswordView form
 * @returns 
 */
const ResetPasswordViewPart = () => {
    console.log('{ResetPasswordViewPart}');
    const [input, setInput] = useState({
        username: ''
    })
    const [error, setError] = useState({
        username: false,
        msg: ''
    })
    const [validUsername, setValidUsername] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    StateUpdators.setLoggingIn = setLoggingIn;
    return (
        <>
        <div className='ncbs-ldks'>
            <div className='mjd-wpx'>
                <h1 className='mjdjhd-qa'>{`Welcome Back :)`}</h1>
                <p className='mdjes-pa'>{`Let's reset your account password.`}</p>
            </div>
            <div className='mjdjhd-soow box-b'>
                <div className='ndsh w-80 centred'>
                    <FormControl fullWidth>
                        <TextField
                            onFocus={() => {
                                if(error.username) {
                                    setError({
                                        ...error,
                                        username: false
                                    })
                                }
                            }}
                            error={error.username}
                            required
                            onChange={(e) => {
                                const _input = e.target.value;
                                setInput({
                                    ...input,
                                    username: _input
                                })
                                if(_input && Utils.isValidEmail(_input)) {
                                    setValidUsername(true);
                                } else {
                                    setValidUsername(false);
                                }
                            }}
                            onBlur={() => {
                                if(input.username) {
                                    setError({...error, username: false})
                                }
                            }}
                            value={input.username}
                            type='text'
                            label='Enter Your Email Address:'
                            InputProps={{
                                startAdornment: <InputAdornment position='start'><Email /></InputAdornment>,
                                endAdornment: <InputAdornment position='start'><CheckCircleIcon sx={{color: validUsername?'green':''}} /></InputAdornment>
                            }}
                        >
                        </TextField>
                        {
                            error.username ? <span className='error'>{error.msg}</span>:''
                        }
                        <Separator style={{height: '10px'}} />
                        <div className='mcjd-pdos'>
                            <span>{Texts.forStdsWE}</span>
                        </div>
                        <Separator style={{height: '20px'}} />
                        </FormControl>
                        {
                            loggingIn ? 
                            <>
                            <Separator style={{height: '20px'}} />
                            <div className='content-centre'>
                                <SmallLoader title='Authenticating...' customClasses='jhjsjs-wjsgs' />
                            </div> 
                            </>: ''
                        }
                </div>
            </div>
            <div className='mdjs-wmsk'>
                <div></div>
                <div className='content-centre ptr' title='Next'>
                    <Button variant='contained' color='primary' className='force-round-btn' onClick={() => {
                        if(!input.username) {
                            setError({
                                ...error,
                                username: true,
                                msg: 'Email is required'
                            })
                            return;
                        } else {
                            setError({
                                ...error,
                                username: false
                            })
                        }
                        if(!Utils.isValidEmail(input.username)) {
                            setError({
                                ...error,
                                username: true,
                                msg: 'Invalid email address'
                            })
                            return;
                        } else {
                            setError({
                                ...error,
                                username: false
                            })
                        }
                        setLoggingIn(true);
                        Utils.beginPasswordResetChallenge({...input});
                    }}>
                        Next
                    </Button>
                </div>
            </div>
            <div className='jdjhsh-lws'>
                <div className='content-centre ptr' title='Create your account now'>
                    <Button color='primary' disabled={true}>
                        Create Account
                    </Button>
                </div>
                <div className='content-centre ptr' title='Login to your account'>
                    <Button onClick={() => {
                        StateUpdators.setLoginFormViewLoginPart({
                            C: () => <LoginFormViewLoginPart reLogin={false} />
                        });
                    }}>
                        Login
                    </Button>
                </div>
            </div>
        </div>
        </>
    )
}

export default ResetPasswordViewPart;