import React, { Suspense, useEffect } from 'react';
import CourseHeader from './CourseHeader';
import MoreInfoView from './MoreInfoView';
import { useParams } from 'react-router-dom';
import Utils from '../../lib/Utils';
import LoadingComponent from './LoadingComponent';
import AccountHeader from './AccountHeader';

/**
 * Render a given course
 * @param {object} props The props object which contains the content props with lessons and type props
 * @returns 
 */
const RenderCourse = (props) => {
    console.log('{RenderCourse}');
    const token = JSON.parse(Utils.base64Decode(useParams().token));
    const View = React.lazy(() => import(`./_content_renders/${props.content.type}`));
    const color = props.content.courses ? props.content.courses[0].color : props.content.lessons[0].color;
    useEffect(() => {
        Utils.cacheCourse({title: token.courseTitle, token: token});
    },[token]);
    return (
        <>
        <AccountHeader showLessonsLink={true} />
        <div className='animate__animated animate__fadeIn'>
            <CourseHeader onTitleClick={() => {
                Utils.goToCurriculumCourses();
            }}
            onCourseTitleClick={() => {
                Utils.goToCourseLessons({course: {
                    ...props.content,
                    lessons: [],
                }, token: token});
            }}
            courseColor={color} 
            courseTitle={token.courseTitle} 
            title={token.curriculumTitle??Utils.getCurrentCurriculum().title} 
            shortName={token.extra.shortName} 
            />
            <div className='cbc-curr-mcnfhdh w-90 h-centred'>
                <Suspense fallback={<LoadingComponent />}>
                    <View content={props.content} token={token} />
                </Suspense>
            </div>
        </div>
        <MoreInfoView />
        </>
    )
}

export default RenderCourse;