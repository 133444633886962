import React from 'react';
import Texts from '../../lib/Texts';
import Assets from '../../lib/Assets';
import { Button } from '@mui/material';
import Utils from '../../lib/Utils';

/**
 * ICDL Categories view
 * @returns 
 */
const ICDLCategories = ({info}) => {
    console.log('{ICDLCategories}');
    return (
        <>
        <div className='hdgd-jfgfg-icdl box-b'>
            {
                info.complete &&
                <div className='jfhf-jfhf'>
                    <img alt={info.category} className='ptr ndgd0jfhf-jfhf rounded' title='Congrats' src={Assets.icons.congrats} />
                </div>
            }
            <h3 className={`${info.complete?'gdgs-complete':'incomplete'}`}>{info.complete?Texts.ICDLComplete:Texts.ICDLNotCompl} <u>{info.category}</u> lessons.</h3>
            <div className='jhfgd-nghfjs'>
                <img alt={info.category} className='hshgs-im' src={Assets.icons.icdl[info.e]} />
            </div>
            <Button title='View categories' onClick={() => {
                Utils.showAllICDLCategories();
            }}>
                View all units
            </Button>
        </div>
        </>
    )
}

export default ICDLCategories;