import React from 'react';

const LoadingImage = () => {
    return (
        <>
        <div title='Loading Image' className='ptr loading-image brd-dash loader-nvhfg v-centred-40 rounded-circle'>
        </div>
        </>
    )
}

export default LoadingImage;