import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from './CloseIcon';
import StateUpdators from '../../lib/StateUpdators';
import Forms from './Forms';
import Error from './Error';
import ReportError from './ReportError';
import RightSidePopin from './RightSidePopin';
import Utils from '../../lib/Utils';


/**
 * The login form
 * @returns 
 */
const LoginForm = ({reLogin}) => {
    console.log('{LoginForm}');
    const [open, setDialogState] = useState(false);
    StateUpdators.setLoginFormDialogState = setDialogState;
    //console.log('Utils.closed=',Utils.closed);
    return (
        <>
        <Dialog open={open && (!Utils.closed)} fullWidth maxWidth={'lg'} classes={{paperWidthLg: 'mdjsjd-override'}}>
            {
                open ?
                <>
                <div className='jdjsh-opsls rounded-big shadow bkg-white animate__animated animate__fadeIn'>
                    <div>
                        <CloseIcon onClose={() => {
                            if(reLogin) {
                                Utils.closed = true;
                            }
                            setDialogState(false)
                        }} />
                    </div>
                    <Forms.LoginForm reLogin={reLogin} />
                </div>
                </> :<></>
            }
        </Dialog>
        <Error />
        <ReportError />
        <RightSidePopin />
        </>
    )
}

export default LoginForm;