import React, { useState } from 'react';
import Assets from '../../lib/Assets';
import StateUpdators from '../../lib/StateUpdators';
import { Button, Dialog } from '@mui/material';

/**
 * Show error
 * @returns 
 */
const Error = () => {
    console.log('{Error}');
    const [error, setError] = useState({
        show: false,
        msg: 'An error occured'
    });
    StateUpdators.setError = setError;
    return (
        <>
        {
            error.show &&
            <Dialog open={error.show}>
                <div className='error-bnvgfg shadow rounded animate__animated animate__fadeIn'>
                    <img id='dismisss-icons0jfhfgh' src={Assets.icons.closeView} alt='Dismiss' title='Dismiss' className='ptr' onClick={() => {
                        StateUpdators.setError({show: false});
                    }}/>
                    <img className='error-icon-nshdh ptr' src={Assets.icons.error} alt='Error' title='An error occured' />
                    <h1>Error:</h1>
                    <p dangerouslySetInnerHTML={{__html: error.msg}}></p>
                    {
                        typeof error.extraRenders !== 'undefined' &&
                        <error.extraRenders />
                    }
                    <div className='report-issue'>
                        <Button title='Report this error' onClick={() => {
                            setError({show:false});
                            StateUpdators.setErrorInputForm({
                                show: true,
                                data: error
                            })
                        }}>
                            Report Issue
                        </Button>
                    </div>
                </div>
            </Dialog>
        }
        </>
    )
}

export default Error;