import React from 'react';
import Texts from '../../lib/Texts';
import Utils from '../../lib/Utils';
import Instructions from './_class_actions/Instructions';
import CRCInstructions from './_class_actions/CRCInstructions';
import StillWorkingOnThis from './StillWorkingOnThis';
import MultipleAlignments from './MultipleAlignments';
import Assets from '../../lib/Assets';
import FeedbackTool from './FeedbackTool';
import FeedbackToolCOC from './FeedbackToolCOC';
import CambridgeProgram from './CambridgeProgram';
import PaceAlignment from './PaceAlignment';


/**
 * Lesson page curriculum handlers. These handle custom functionalities on the ./lesson page.
 * These are used by the TaskPageItemsHTML component.
 */
const LessonPageCurriculumHandlers = {
    feedbackToolTitle: 'Parent Feedback',
    /**
     * The Highschool Curriculum handlers
     */
    HSC: {
        feedbackToolTitle: 'Feedback',
        /**
         * Handle the feedback tool click
         * @param {object} info The info object
         */
        onFeedbackToolClick: (info) => {
            console.log('[COC.onFeedbackToolClick]');
            console.log('COC.onFeedbackToolClick: ',info)
            Utils.showModal({
                Content: () => <FeedbackToolCOC lesson={{...info}} />
            })
        },
        /**
         * Render alignments
         * @param {object} info The info object 
         * @returns 
         */
        Alignments: ({info}) => {
            console.log('{LessonPageCurriculumHandlers.Alignments}');
            //console.log('{LessonPageCurriculumHandlers.Alignments}: info=',info);
            const doubleAlignment = ((info._paceAligned??false) && (info._paceAligned.aligned)) && ((info._cambridgeProg??false) && (info._cambridgeProg.aligned));
            //console.log('doubleAlignment=',doubleAlignment);
            return (
                <>
                {
                    doubleAlignment ?
                    <>
                    <div title={'Alignments'} className='ptr circle-item shadow item-6' onClick={() => {
                        Utils.addAnalytics({
                            page: 'Lesson',
                            url: window.location.href,
                            title: `NS.Lesson.DoubleAlignments`,
                            courseId: info.lesson.courseId,
                            lessonId: info.lesson.lessonId
                        });
                        Utils.showModal({
                            Content: () => <MultipleAlignments info={info} />
                        })
                    }}>
                        <div className='item-hffg-text rounded shadow box-b hddg-4'>
                            <div className='gdgd-instrs mdh-pace-text rounded-left'>
                                <h3 className='hfhd-jd'>{`Alignments`}</h3>
                            </div>
                            <div className='big-dot-js rounded-right'>
                            </div>
                        </div>
                        <div className='hshsgf-ehdt itsmfhd-hfhf'>
                            <img className='hsg-icons0-dkdh' alt={'Alignments'} src={Assets.taskPage.alignments} />
                        </div>
                    </div>
                    <div className='line-hddg-4'></div>
                    </>
                    :
                    <>
                    {
                        (info._cambridgeProg??false) && (info._cambridgeProg.aligned) ?
                        <>
                        <div title={'Cambridge Alignment'} className='ptr circle-item shadow item-6' onClick={() => {
                            Utils.addAnalytics({
                                page: 'Lesson',
                                url: window.location.href,
                                title: `NS.Lesson.ShowCambridgeAlignmentInfo`,
                                courseId: info.lesson.courseId,
                                lessonId: info.lesson.lessonId
                            });
                            Utils.showModal({
                                Content: () => <CambridgeProgram info={info._cambridgeProg.info} />
                            })
                        }}>
                            <div className='item-hffg-text rounded shadow box-b hddg-4'>
                                <div className='gdgd-instrs mdh-pace-text rounded-left'>
                                    <h3 className='hfhd-jd'>{`Cambridge`}</h3>
                                </div>
                                <div className='big-dot-js rounded-right'>
                                </div>
                            </div>
                            <div className='hshsgf-ehdt itsmfhd-hfhf'>
                                <img className='hsg-icons0-dkdh' alt={'Cambridge Alignment'} src={Assets.taskPage.alignments} />
                            </div>
                        </div>
                        <div className='line-hddg-4'></div>
                        </>
                        :
                        <>
                        {
                            (info._paceAligned??false) && (info._paceAligned.aligned) ?
                            <>
                            <div title={'PACE Alignment'} className='ptr circle-item shadow item-6' onClick={() => {
                                Utils.addAnalytics({
                                    page: 'Lesson',
                                    url: window.location.href,
                                    title: `NS.Lesson.PACEAlignment`,
                                    courseId: info.lesson.courseId,
                                    lessonId: info.lesson.lessonId
                                });
                                Utils.showModal({
                                    Content: () => <PaceAlignment info={info._paceAligned.info} />
                                })
                            }}>
                                <div className='item-hffg-text rounded shadow box-b hddg-4'>
                                    <div className='gdgd-instrs rounded-left'>
                                        <h3 className='hfhd-jd'>{`PACE ${info._paceAligned.info.code}`}</h3>
                                    </div>
                                    <div className='big-dot-js rounded-right'>
                                    </div>
                                </div>
                                <div className='hshsgf-ehdt itsmfhd-hfhf'>
                                    <img className='hsg-icons0-dkdh' alt={'PACE '} src={Assets.taskPage.pace} />
                                </div>
                            </div>
                            <div className='line-hddg-4'></div>
                            </>
                            :
                            <>
                                <div title={'Share'} className='ptr circle-item shadow item-6' onClick={() => {
                                    Utils.addAnalytics({
                                        page: 'Lesson',
                                        url: window.location.href,
                                        title: `NS.Lesson.ShowShare`,
                                        courseId: info.lesson.courseId,
                                        lessonId: info.lesson.lessonId
                                    });
                                    Utils.showFullscreenModal({
                                        Content: () => <StillWorkingOnThis info={{p:{className:'white-t'}}} />
                                    })
                                }}>
                                    <div className='item-hffg-text rounded shadow box-b hddg-4'>
                                        <div className='gdgd-instrs rounded-left'>
                                            <h3 className='hfhd-jd'>{'Share'}</h3>
                                        </div>
                                        <div className='big-dot-js rounded-right'>
                                        </div>
                                    </div>
                                    <div className='hshsgf-ehdt itsmfhd-hfhf'>
                                        <img className='hsg-icons0-dkdh' alt={'Share'} src={Assets.taskPage.share} />
                                    </div>
                                </div>
                                <div className='line-hddg-4'></div>
                            </>
                        }
                        </>
                    }
                    </>
                }
                </>
            )
        },
        /**
         * When the history part is clicked
         * @param {object} info The info object
         */
        onHistoryClick: (info) => {
            info = {};
            Utils.showFullscreenModal({
                Content: () => <StillWorkingOnThis info={{p:{className:'white-t'}}} />
            })
        },
        /**
         * When the Instructions item is clicked.
         * @param {object} info The info object (the lesson)
         */
        onInstructionsClick: (info) => {
            Utils.showLessonTaskPageModal({
                onClose: () => {
                    Utils.instructionsCurrentPage = false;
                    Utils.hideMaximizeView();
                },
                Content: () => <Instructions currentPageNumber={Utils.instructionsCurrentPage>=1?Utils.instructionsCurrentPage:1} curr lesson={info} />
            })
        },
        /**
         * Handle when the suggested outcome image ic clicked
         * @param {string} lessonOutcome The image url to show 
         */
        suggestedOutcomeTitleHandler: ({lessonOutcome}) => {
            Utils.showZoomedLessonOutcome({
                image: lessonOutcome,
                title: 'Suggested Lesson Outcome'
            });
        }
    },
    /**
     * The Community Curriculum handlers
     */
    COC: {
        feedbackToolTitle: 'Feedback',
        /**
         * Handle the feedback tool click
         * @param {object} info The info object
         */
        onFeedbackToolClick: (info) => {
            console.log('[COC.onFeedbackToolClick]');
            console.log('COC.onFeedbackToolClick: ',info)
            Utils.showModal({
                Content: () => <FeedbackToolCOC lesson={{...info}} />
            })
        },
        /**
         * Render alignments
         * @param {object} info The info object 
         * @returns 
         */
        Alignments: ({info}) => {
            console.log('{LessonPageCurriculumHandlers.Alignments}');
            //console.log('{LessonPageCurriculumHandlers.Alignments}: info=',info);
            const doubleAlignment = ((info._paceAligned??false) && (info._paceAligned.aligned)) && ((info._cambridgeProg??false) && (info._cambridgeProg.aligned));
            //console.log('doubleAlignment=',doubleAlignment);
            return (
                <>
                {
                    doubleAlignment ?
                    <>
                    <div title={'Alignments'} className='ptr circle-item shadow item-6' onClick={() => {
                        Utils.addAnalytics({
                            page: 'Lesson',
                            url: window.location.href,
                            title: `NS.Lesson.DoubleAlignments`,
                            courseId: info.lesson.courseId,
                            lessonId: info.lesson.lessonId
                        });
                        Utils.showModal({
                            Content: () => <MultipleAlignments info={info} />
                        })
                    }}>
                        <div className='item-hffg-text rounded shadow box-b hddg-4'>
                            <div className='gdgd-instrs mdh-pace-text rounded-left'>
                                <h3 className='hfhd-jd'>{`Alignments`}</h3>
                            </div>
                            <div className='big-dot-js rounded-right'>
                            </div>
                        </div>
                        <div className='hshsgf-ehdt itsmfhd-hfhf'>
                            <img className='hsg-icons0-dkdh' alt={'Alignments'} src={Assets.taskPage.alignments} />
                        </div>
                    </div>
                    <div className='line-hddg-4'></div>
                    </>
                    :
                    <>
                    {
                        (info._cambridgeProg??false) && (info._cambridgeProg.aligned) ?
                        <>
                        <div title={'Cambridge Alignment'} className='ptr circle-item shadow item-6' onClick={() => {
                            Utils.addAnalytics({
                                page: 'Lesson',
                                url: window.location.href,
                                title: `NS.Lesson.ShowCambridgeAlignmentInfo`,
                                courseId: info.lesson.courseId,
                                lessonId: info.lesson.lessonId
                            });
                            Utils.showModal({
                                Content: () => <CambridgeProgram info={info._cambridgeProg.info} />
                            })
                        }}>
                            <div className='item-hffg-text rounded shadow box-b hddg-4'>
                                <div className='gdgd-instrs mdh-pace-text rounded-left'>
                                    <h3 className='hfhd-jd'>{`Cambridge`}</h3>
                                </div>
                                <div className='big-dot-js rounded-right'>
                                </div>
                            </div>
                            <div className='hshsgf-ehdt itsmfhd-hfhf'>
                                <img className='hsg-icons0-dkdh' alt={'Cambridge Alignment'} src={Assets.taskPage.alignments} />
                            </div>
                        </div>
                        <div className='line-hddg-4'></div>
                        </>
                        :
                        <>
                        {
                            (info._paceAligned??false) && (info._paceAligned.aligned) ?
                            <>
                            <div title={'PACE Alignment'} className='ptr circle-item shadow item-6' onClick={() => {
                                Utils.addAnalytics({
                                    page: 'Lesson',
                                    url: window.location.href,
                                    title: `NS.Lesson.PACEAlignment`,
                                    courseId: info.lesson.courseId,
                                    lessonId: info.lesson.lessonId
                                });
                                Utils.showModal({
                                    Content: () => <PaceAlignment info={info._paceAligned.info} />
                                })
                            }}>
                                <div className='item-hffg-text rounded shadow box-b hddg-4'>
                                    <div className='gdgd-instrs rounded-left'>
                                        <h3 className='hfhd-jd'>{`PACE ${info._paceAligned.info.code}`}</h3>
                                    </div>
                                    <div className='big-dot-js rounded-right'>
                                    </div>
                                </div>
                                <div className='hshsgf-ehdt itsmfhd-hfhf'>
                                    <img className='hsg-icons0-dkdh' alt={'PACE '} src={Assets.taskPage.pace} />
                                </div>
                            </div>
                            <div className='line-hddg-4'></div>
                            </>
                            :
                            <>
                                <div title={'Share'} className='ptr circle-item shadow item-6' onClick={() => {
                                    Utils.addAnalytics({
                                        page: 'Lesson',
                                        url: window.location.href,
                                        title: `NS.Lesson.ShowShare`,
                                        courseId: info.lesson.courseId,
                                        lessonId: info.lesson.lessonId
                                    });
                                    Utils.showFullscreenModal({
                                        Content: () => <StillWorkingOnThis info={{p:{className:'white-t'}}} />
                                    })
                                }}>
                                    <div className='item-hffg-text rounded shadow box-b hddg-4'>
                                        <div className='gdgd-instrs rounded-left'>
                                            <h3 className='hfhd-jd'>{'Share'}</h3>
                                        </div>
                                        <div className='big-dot-js rounded-right'>
                                        </div>
                                    </div>
                                    <div className='hshsgf-ehdt itsmfhd-hfhf'>
                                        <img className='hsg-icons0-dkdh' alt={'Share'} src={Assets.taskPage.share} />
                                    </div>
                                </div>
                                <div className='line-hddg-4'></div>
                            </>
                        }
                        </>
                    }
                    </>
                }
                </>
            )
        },
        /**
         * When the history part is clicked
         * @param {object} info The info object
         */
        onHistoryClick: (info) => {
            info = {};
            Utils.showFullscreenModal({
                Content: () => <StillWorkingOnThis info={{p:{className:'white-t'}}} />
            })
        },
        /**
         * When the Instructions item is clicked.
         * @param {object} info The info object (the lesson)
         */
        onInstructionsClick: (info) => {
            Utils.showLessonTaskPageModal({
                onClose: () => {
                    Utils.instructionsCurrentPage = false;
                    Utils.hideMaximizeView();
                },
                Content: () => <Instructions currentPageNumber={Utils.instructionsCurrentPage>=1?Utils.instructionsCurrentPage:1} curr lesson={info} />
            })
        },
        /**
         * Handle when the suggested outcome image ic clicked
         * @param {string} lessonOutcome The image url to show 
         */
        suggestedOutcomeTitleHandler: ({lessonOutcome}) => {
            Utils.showZoomedLessonOutcome({
                image: lessonOutcome,
                title: 'Suggested Lesson Outcome'
            });
        }
    },
    /**
     * The Digital Skills Curriculum handlers
     */
    DSC: {
        /**
         * Handle the feedback tool click
         * @param {object} info The info object
         */
        onFeedbackToolClick: (info) => {
            console.log('[DSC.onFeedbackToolClick]');
            Utils.showModal({
                Content: () => <FeedbackTool lesson={{...info}} />
            })
        },
        /**
         * Render alignments
         * @param {object} info The info object 
         * @returns 
         */
        Alignments: ({info}) => {
            console.log('{LessonPageCurriculumHandlers.Alignments}');
            //console.log('{LessonPageCurriculumHandlers.Alignments}: info=',info);
            const doubleAlignment = ((info._paceAligned??false) && (info._paceAligned.aligned)) && ((info._cambridgeProg??false) && (info._cambridgeProg.aligned));
            //console.log('doubleAlignment=',doubleAlignment);
            return (
                <>
                {
                    doubleAlignment ?
                    <>
                        <div title={'Alignments'} className='ptr circle-item shadow item-6' onClick={() => {
                            Utils.addAnalytics({
                                page: 'Lesson',
                                url: window.location.href,
                                title: `NS.Lesson.DoubleAlignments`,
                                courseId: info.lesson.courseId,
                                lessonId: info.lesson.lessonId
                            });
                            Utils.showModal({
                                Content: () => <MultipleAlignments info={info} />
                            })
                        }}>
                            <div className='item-hffg-text rounded shadow box-b hddg-4'>
                                <div className='gdgd-instrs mdh-pace-text rounded-left'>
                                    <h3 className='hfhd-jd'>{`Alignments`}</h3>
                                </div>
                                <div className='big-dot-js rounded-right'>
                                </div>
                            </div>
                            <div className='hshsgf-ehdt itsmfhd-hfhf'>
                                <img className='hsg-icons0-dkdh' alt={'Alignments'} src={Assets.taskPage.alignments} />
                            </div>
                        </div>
                        <div className='line-hddg-4'></div>
                    </>
                    :
                    <>
                    {
                        (info._cambridgeProg??false) && (info._cambridgeProg.aligned) ?
                        <>
                            <div title={'Cambridge Alignment'} className='ptr circle-item shadow item-6' onClick={() => {
                                Utils.addAnalytics({
                                    page: 'Lesson',
                                    url: window.location.href,
                                    title: `NS.Lesson.ShowCambridgeAlignmentInfo`,
                                    courseId: info.lesson.courseId,
                                    lessonId: info.lesson.lessonId
                                });
                                Utils.showModal({
                                    Content: () => <CambridgeProgram info={info._cambridgeProg.info} />
                                })
                            }}>
                                <div className='item-hffg-text rounded shadow box-b hddg-4'>
                                    <div className='gdgd-instrs mdh-pace-text rounded-left'>
                                        <h3 className='hfhd-jd'>{`Cambridge`}</h3>
                                    </div>
                                    <div className='big-dot-js rounded-right'>
                                    </div>
                                </div>
                                <div className='hshsgf-ehdt itsmfhd-hfhf'>
                                    <img className='hsg-icons0-dkdh' alt={'Cambridge Alignment'} src={Assets.taskPage.alignments} />
                                </div>
                            </div>
                            <div className='line-hddg-4'></div>
                        </>
                        :
                        <>
                        {
                            (info._paceAligned??false) && (info._paceAligned.aligned) ?
                            <>
                            <div title={'PACE Alignment'} className='ptr circle-item shadow item-6' onClick={() => {
                                Utils.addAnalytics({
                                    page: 'Lesson',
                                    url: window.location.href,
                                    title: `NS.Lesson.PACEAlignment`,
                                    courseId: info.lesson.courseId,
                                    lessonId: info.lesson.lessonId
                                });
                                Utils.showModal({
                                    Content: () => <PaceAlignment info={info._paceAligned.info} />
                                })
                            }}>
                                <div className='item-hffg-text rounded shadow box-b hddg-4'>
                                    <div className='gdgd-instrs rounded-left'>
                                        <h3 className='hfhd-jd'>{`PACE ${info._paceAligned.info.code}`}</h3>
                                    </div>
                                    <div className='big-dot-js rounded-right'>
                                    </div>
                                </div>
                                <div className='hshsgf-ehdt itsmfhd-hfhf'>
                                    <img className='hsg-icons0-dkdh' alt={'PACE '} src={Assets.taskPage.pace} />
                                </div>
                            </div>
                            <div className='line-hddg-4'></div>
                            </>
                            :
                            <>
                                <div title={'Share'} className='ptr circle-item shadow item-6' onClick={() => {
                                    Utils.addAnalytics({
                                        page: 'Lesson',
                                        url: window.location.href,
                                        title: `NS.Lesson.ShowShare`,
                                        courseId: info.lesson.courseId,
                                        lessonId: info.lesson.lessonId
                                    });
                                    Utils.showFullscreenModal({
                                        Content: () => <StillWorkingOnThis info={{p:{className:'white-t'}}} />
                                    })
                                }}>
                                    <div className='item-hffg-text rounded shadow box-b hddg-4'>
                                        <div className='gdgd-instrs rounded-left'>
                                            <h3 className='hfhd-jd'>{'Share'}</h3>
                                        </div>
                                        <div className='big-dot-js rounded-right'>
                                        </div>
                                    </div>
                                    <div className='hshsgf-ehdt itsmfhd-hfhf'>
                                        <img className='hsg-icons0-dkdh' alt={'Share'} src={Assets.taskPage.share} />
                                    </div>
                                </div>
                                <div className='line-hddg-4'></div>
                            </>
                        }
                        </>
                    }
                    </>
                }
                </>
            )
        },
        /**
         * When the history part is clicked
         * @param {object} info The info object
         */
        onHistoryClick: (info) => {
            info = {};
            Utils.showFullscreenModal({
                Content: () => <StillWorkingOnThis info={{p:{className:'white-t'}}} />
            })
        },
        /**
         * When the Instructions item is clicked.
         * @param {object} info The info object (the lesson)
         */
        onInstructionsClick: (info) => {
            Utils.showLessonTaskPageModal({
                onClose: () => {
                    Utils.instructionsCurrentPage = false;
                    Utils.hideMaximizeView();
                },
                Content: () => <Instructions currentPageNumber={Utils.instructionsCurrentPage>=1?Utils.instructionsCurrentPage:1} curr lesson={info} />
            })
        },
        /**
         * Handle when the suggested outcome image ic clicked
         * @param {string} lessonOutcome The image url to show 
         */
        suggestedOutcomeTitleHandler: ({lessonOutcome}) => {
            Utils.showZoomedLessonOutcome({
                image: lessonOutcome,
                title: 'Suggested Lesson Outcome'
            });
        }
    },
    /**
     * The Community Based Curriculum handlers
     */
    TBC: {
        feedbackToolTitle: 'Feedback',
        /**
         * Handle the feedback tool click
         * @param {object} info The info object
         */
        onFeedbackToolClick: ({info}) => {
            info = {};
            Utils.showFullscreenModal({
                Content: () => <StillWorkingOnThis info={{p:{className:'white-t'}}} />
            })
        },
        /**
         * When the history part is clicked
         * @param {object} info The info object
         */
        onHistoryClick: (info) => {
            info = {};
            Utils.showFullscreenModal({
                Content: () => <StillWorkingOnThis info={{p:{className:'white-t'}}} />
            })
        },
        /**
         * Render alignments
         * @param {object} info The info object 
         * @returns 
         */
        Alignments: ({info}) => {
            return (
                <>
                <div title={'Share'} className='ptr circle-item shadow item-6' onClick={() => {
                    Utils.addAnalytics({
                        page: 'Lesson',
                        url: window.location.href,
                        title: `NS.Lesson.ShowShare`,
                        courseId: info.lesson.courseId,
                        lessonId: info.lesson.lessonId
                    });
                    Utils.showFullscreenModal({
                        Content: () => <StillWorkingOnThis info={{p:{className:'white-t'}}} />
                    })
                }}>
                    <div className='item-hffg-text rounded shadow box-b hddg-4'>
                        <div className='gdgd-instrs rounded-left'>
                            <h3 className='hfhd-jd'>{'Share'}</h3>
                        </div>
                        <div className='big-dot-js rounded-right'>
                        </div>
                    </div>
                    <div className='hshsgf-ehdt itsmfhd-hfhf'>
                        <img className='hsg-icons0-dkdh' alt={'Share'} src={Assets.taskPage.share} />
                    </div>
                </div>
                <div className='line-hddg-4'></div>
                </>
            )
        },
        /**
         * When the Instructions item is clicked.
         * @param {object} info The info object (the lesson)
         */
        onInstructionsClick: (info) => {
            Utils.showLessonTaskPageModal({
                onClose: () => {
                    Utils.instructionsCurrentPage = false;
                    Utils.hideMaximizeView();
                },
                Content: () => <Instructions currentPageNumber={Utils.instructionsCurrentPage>=1?Utils.instructionsCurrentPage:1} curr lesson={info} />
            })
        },
        /**
         * Handle when the suggested outcome image ic clicked
         * @param {string} lessonOutcome The image url to show 
         */
        suggestedOutcomeTitleHandler: ({lessonOutcome}) => {
            Utils.showZoomedLessonOutcome({
                image: lessonOutcome,
                title: 'Suggested Lesson Outcome'
            })
        }
    },
    /**
     * The Coding & Robotics Curriculum handlers
     */
    CRC: {
        feedbackToolTitle: 'Parent Feedback',
        /**
         * Handle the feedback tool click
         * @param {object} info The info object
         */
        onFeedbackToolClick: (info) => {
            console.log('[CRC.onFeedbackToolClick]');
            Utils.showModal({
                Content: () => <FeedbackTool lesson={{...info}} />
            })
        },
        /**
         * When the history part is clicked
         * @param {object} info The info object
         */
        onHistoryClick: (info) => {
            info = {};
            Utils.showFullscreenModal({
                Content: () => <StillWorkingOnThis info={{p:{className:'white-t'}}} />
            })
        },
        /**
         * Render alignments
         * @param {object} info The info object 
         * @returns 
         */
        Alignments: ({info}) => {
            return (
                <>
                <div title={'DBE Info'} className='ptr circle-item shadow item-6' onClick={() => {
                    Utils.addAnalytics({
                        page: 'Lesson',
                        url: window.location.href,
                        title: `NS.Lesson.ShowDBEInfo`,
                        courseId: info.lesson.courseId,
                        lessonId: info.lesson.lessonId
                    });
                    Utils.showFullscreenModal({
                        Content: () => <StillWorkingOnThis info={{p:{className:'white-t'}}} />
                    })
                }}>
                    <div className='item-hffg-text rounded shadow box-b hddg-4'>
                        <div className='gdgd-instrs hsgs-ueue-dbe rounded-left'>
                            <h3 className='hfhd-jd'>{'DBE Info'}</h3>
                        </div>
                        <div className='big-dot-js rounded-right'>
                        </div>
                    </div>
                    <div className='hshsgf-ehdt itsmfhd-hfhf'>
                        <img className='hsg-icons0-dkdh' alt={'DBE Info'} src={Assets.taskPage.share} />
                    </div>
                </div>
                <div className='line-hddg-4'></div>
                </>
            )
        },
        /**
         * When the Instructions item is clicked.
         * @param {object} info The info object (the lesson)
         */
        onInstructionsClick: (info) => {
            Utils.showLessonTaskPageModal({
                onClose: () => {
                    Utils.instructionsCurrentPage = false;
                    Utils.hideMaximizeView();
                },
                Content: () => <CRCInstructions lesson={info} />
            })
        },
        /**
         * Handle when the suggested outcome image ic clicked
         * @param {string} lessonOutcome The image url to show 
         */
        suggestedOutcomeTitleHandler: ({lessonOutcome}) => {
            Utils.showZoomedLessonOutcome({
                image: lessonOutcome,
                title: 'Suggested Lesson Outcome'
            })
        }
    },
    /**
     * When no handler is defined
     */
    Error: {
        error: true,
        msg: Texts.noValidCurriculumHandler,
        suggestedOutcomeTitleHandler: () => {},
        Alignments: () => <></>
    }
}

export default LessonPageCurriculumHandlers;