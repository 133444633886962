import React, { useState } from 'react';
import Assets from '../../lib/Assets';
import StateUpdators from '../../lib/StateUpdators';
import Utils from '../../lib/Utils';
import Instructions from './_class_actions/Instructions';

const MiniMizedView = () => {
    console.log('{MiniMizedView}');
    const [C, setMinimizedView] = useState({
        show: false,
        title: 'Maximixe'
    });
    StateUpdators.setMinimizedView = setMinimizedView;
    return (
        <>
        {
            C.show &&
            <div className='min-kshsh-fhfghf box-b animate__animated animate__lightSpeedInRight rounded shadow ptr' title='Click to Maximize' onClick={() => {
                Utils.showLessonTaskPageModal({
                    onClose: () => {
                        Utils.instructionsCurrentPage = false;
                        Utils.hideMaximizeView();
                    },
                    Content: () => <Instructions currentPageNumber={Utils.instructionsCurrentPage?Utils.instructionsCurrentPage:1} lesson={Utils.currentLessonInfo} />
                })
            }}>
                <div className='rounded-left'>
                    <img alt='Minimize' className='jfhd-mldjd' src={Assets.icons.maximize} />
                </div>
                <div className='jshshg-kfjdlsh-hd rounded-right'>
                    <p>{C.title}</p>
                </div>
            </div>
        }
        </>
    )
}

export default MiniMizedView;