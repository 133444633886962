import React from 'react';
import Theme from '../../lib/Theme';
import Assets from '../../lib/Assets';
import Utils from '../../lib/Utils';
import CommunityCurriculum from '../../lib/CommunityCurriculum';


/**
 * ElearnerLeftBottom
 * @returns 
 */
const ElearnerLeftBottom = () => {
    console.log('{ElearnerLeftBottom}');
    const curriculum = Utils.getCachedCurriculumInfo();
    return (
        <>
        {
            curriculum.extra.shortName === CommunityCurriculum.shortName &&
            <div className='jhs-jdhdjf ptr hdgg-e-learner'>
                <img title='E-Learner' className={Theme.content.animated.fadeIn} alt='E-Learner' src={Assets.eLearnerLogo} />
            </div>
        }
        </>
    )
}

export default ElearnerLeftBottom;