import React, { useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import Assets from '../../lib/Assets';
/**
 * Show more information
 * @returns 
 */
const MoreInfoView = () => {
    console.log('{MoreInfoView}');
    const [info, setMoreInfoViewInfo] = useState({
        show: false,
        text: '',
        title: ''
    });
    StateUpdators.setMoreInfoViewInfo = setMoreInfoViewInfo;
    return (
        <>
        {
            info.show &&
            <div className={`more-info-view animate__animated animate__fadeIn bkg-white rounded shadow bdr-full-${info.color}`}>
                <div className={`cbc-cat-color-${info.color??'not-given'} rounded-top`}>
                    <h3 className='info-ewvdhf' dangerouslySetInnerHTML={{__html: info.title}}></h3>
                    <img className='jfhfhgh-hf-icon ptr' onClick={() => {
                        StateUpdators.setMoreInfoViewInfo({
                            show: false
                        })
                    }} src={Assets.icons.closeViewWhite} alt='Dismiss' title='Dismiss' />
                </div>
                <div className='mfhfh-text'>
                    <p>{info.text}</p>
                </div>
            </div>
        }
        </>
    )
}

export default MoreInfoView;