import React from 'react';
import BaseName from '../../lib/BaseName';
import Utils from '../../lib/Utils';
import Assets from '../../lib/Assets';
import { Email, WhatsApp } from '@mui/icons-material';

/**
 * The footer
 * @returns 
 */
const COLFooter = () => {
    console.log('{COLFooter}');
    return (
        <>
        <div className='mcjhdh-ldjks'>
            <div className='mcjhdh-ldjks-lsks w-90 centred'>
                <div className='nxs-als box-b'>
                    <div className='mchdhd-ls'>
                        <h3>Physical Address:</h3>
                        <p>16 Baalen Rd</p>
                        <p>Marina da Gama</p>
                        <p>7945</p>
                        <p>Cape Town</p>
                        <p>South Africa</p>
                    </div>
                    <div className='mchdhd-ls'>
                        <h3>Postal Address:</h3>
                        <p>P.O Box 31143</p>
                        <p>Tokai</p>
                        <p>7966</p>
                        <p>Cape Town</p>
                        <p>South Africa</p>
                    </div>
                    <div className='nchd-lks'>
                        <div className='ndhsjs content-centre'>
                            <a href={`/${BaseName.name}`}>Home</a>
                            <a href={Utils.c4kURL}>Computers 4 Kids</a>
                            <a href={Utils.elearnerURL}>e-Learner</a>
                            <a href={Utils.aboutC4KURL}>About Us</a>
                        </div>
                        <div className='mchdhd-ls' id='get-in-touch'>
                            <h3 className='mxjs-ss'>Get in touch</h3>
                            <div className='mnshsh-lspw'>
                                <div className='ndh-ldls ptr' title='Reach us via email' onClick={() => {
                                    window.location = `mailto:${Utils.emails.c4k.info}?subject=Getting In Touch`;
                                }}>
                                    <div className='content-centre'>
                                        <Email sx={{ fontSize: 30 }} />
                                    </div>
                                    <div className='dsada'>
                                        <p>{Utils.emails.c4k.info}</p>
                                    </div>
                                </div>
                                <div className='mjdhs-ldks ptr' title='Reach us via WhatsApp/Call' onClick={() => {
                                    Utils.goTo({url: Utils.contacts.wa, inNewWindow: true})
                                }}>
                                    <div className='content-centre'>
                                        <WhatsApp sx={{ fontSize: 30 }} />
                                    </div>
                                    <div className='dsada'>
                                        <p>{Utils.cellphones.c4k.number1}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mchd-ppsl ndhsls-lsl'>
                    <div className='mfjd-kkf'>
                        <img alt='' src={Assets.c4kLogo} />
                        <img alt='' src={Assets.eLearnerLogo} />
                        <img alt='' src={Assets.colLogo} />
                    </div>
                    <p>&copy; Copyright Computers 4 Kids, {Utils.getYear()}.</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default COLFooter;