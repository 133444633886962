import React from 'react';

/**
 * Render an image in a cell of a MUI DataGrid row
 * @param {object} params The params object 
 * @returns 
 */
const MUIDataGridCellImage = ({params,srcBase}) => {
    //console.log('{MUIDataGridCellImage}');
    //console.log('srcBase=',srcBase)
    return (
        <>
        <img title={params.row.lessonTitle??''} alt={params.row.lessonTitle??''} className='ptr animate__animated animate__fadeIn hdgg-kfhf-ls' src={`${srcBase}${params.row.badgeIcon}`} />
        </>
    )
}

export default MUIDataGridCellImage;