import React, { useEffect, useRef, useState } from 'react';
import '../../css/sidefeedback.css';
import StateUpdators from '../../lib/StateUpdators';

/**
 * 
 * @returns 
 */
const SideFeedback = () => {
    console.log('{SideFeedback}');
    const THREE_SECONDS = 3000;
    const [feedback, setSidefeedback] = useState({
        show: false,
        feedback: ''
    });
    const ref = useRef();
    StateUpdators.setSidefeedback = setSidefeedback;
    if(feedback.show) {
        ref.current = setTimeout(() => {
            setSidefeedback({show: false});
        },THREE_SECONDS);
    }
    useEffect(() => {
        return () => clearTimeout(ref.current);
    },[]);
    return(
        <>
        {
            feedback.show &&
            <div className="modalBoxSide animate__animated animate__fadeInLeft">
                <div id="popUpMessage">
                    <p>{feedback.feedback}</p>
                </div>
            </div>
        }
        </>
    )
}

export default SideFeedback;