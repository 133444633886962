import React, { useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';       
import Forms from './Forms';
import CloseIcon from './CloseIcon';

/**
 * Collects information from a user
 * @returns 
 */
const QuickInfoCollectForm = () => {
    console.log('{QuickInfoCollectForm}');
    const [Content, setQuickInfoCollectFormContent] = useState(Forms.PreTest);
    StateUpdators.setQuickInfoCollectFormContent = setQuickInfoCollectFormContent;
    const [_info, setShowQuickInfoCollectForm] = useState({
        show: false
    });
    StateUpdators.setShowQuickInfoCollectForm = setShowQuickInfoCollectForm;
    return (
        <>
        {
            _info.show &&
            <div className='quick-collect-form animate__animated animate__fadeIn w-60 shadow rounded bkg-white'>
                <CloseIcon onClose={() => {
                    StateUpdators.setShowQuickInfoCollectForm({show: false});
                    StateUpdators.setDisabled(false);
                }} />
                <Content />
            </div>
        }
        </>
    )
}

export default QuickInfoCollectForm;



