import { Button } from '@mui/material';
import React from 'react';

/**
 * Show Quiz Start Brief Info
 * @param {string} quizTitle The quiz title
 * @param {number} questions Number of questions
 * @param {object} extraConfigs The extraConfigs object
 * @param {fn} onStart The onStart function/callback
 * @param {number} duration Durationn of the quiz
 * @returns React View
 */
const QuizStartBriefInfo = ({quizTitle,questions,extraConfigs,onStart,duration}) => {
    console.log('{QuizStartBriefInfo}');
    return (
        <>
        <div className='bcbfg rounded w-90 centred bdr-blue shadow animate__animated animate__fadeIn'>
            <h1>You are about to take this quiz.</h1>
            <h2>Quiz: {quizTitle}</h2>
            <h2>Questions: {questions}</h2>
            <h2>Duration: {duration} minutes</h2>
            <h2>Number Of Attempts: 1</h2>
            <div className='nvhfh-kfjghg w-90 centred'>
                <p>Be calm and take your time before you start, you've got this 🙂.</p>
                <p>When you are ready, click the <b>Start</b> button below.</p>
                <p>When you have completed the test, click the <b>Submit</b> button to see your results instantly.</p>
                <p>The <b>Questions Answered</b> section to the right keeps track of the questions you have answered so far. Click on any number to jump to a specific question.</p>
                <p>If a question image looks unclear, you can hover on it to zoom in for a clear view.</p>
                {
                    extraConfigs && extraConfigs.OtherInfo &&
                    <extraConfigs.OtherInfo />
                }
                <h3 id='good-luck-exams'>Good luck! 🤞</h3>
            </div>
            <Button title='Start test' variant='contained' color='primary' className='force-round-btn' onClick={() => {
                onStart();
            }}>
                Start
            </Button>
        </div>
        </>
    )
}

export default QuizStartBriefInfo;