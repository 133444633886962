import { createSlice } from '@reduxjs/toolkit';

/**
 * State management slices and reducers.
 */
export  const courseInfoStateManager = createSlice({
    name: 'courseInfo',
    initialState: {
        value: {}
    },
    reducers: {
        /**
         * Set course info in to the store
         * @param {object} state The state object
         * @param {fn} action The action to dispatch
         * @returns any
         */
        setCourseInfo: (state, action) => {
            return {
                ...state,
                value: action.payload
            }
        },
        /**
         * Get course info from the store
         * @param {object} state The state object
         * @returns 
         */
        getCourseInfo: (state) => {
            return {
                ...state.value
            }
        }
    }
});

export const {setCourseInfo, getCourseInfo} = courseInfoStateManager.actions;

export default courseInfoStateManager.reducer;