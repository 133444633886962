import React from 'react';

/**
 * Tech talk content view
 * @param {object} info The info object
 * @returns 
 */
const TechTalkContent = ({info}) => {
    console.log('{TechTalkContent}');
    return (
        <>
        <div className='jdhd-jfhf-jhfhfg w-70 box-b centred shadow'>
            <div className='jdhdh-lsjfh rounded-left'>
                <h3 className='nhsg'>Jump to:</h3>
            </div>
            <div className='iryr-kfhf-whdhd rounded-right'>
                {
                    Object.entries(info.firstLetters).map((entry,k) => {
                        return (
                            <div title={`Jump to ${entry[0]}`} className='jjsj-item' key={k}><a href={`#tech-talk-term-${entry[0]}`}>{entry[0]}</a></div>
                        )
                    })
                }
            </div>
        </div>
        {
            <div className='jfhd-the-gdks w-80 centred'>
                {
                    Object.entries(info.firstLetters).map((entry,k) => {
                        return (
                            <table className='hsgsf-tale' key={k}>
                                <thead>
                                    <tr>
                                        <th className='tech-talk-term' id={`tech-talk-term-${entry[0]}`} colSpan={2}>{entry[0]}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.entries(info.definitions).filter(_entry => _entry[1].term.startsWith(entry[0])).map((entry,i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className='hdgdg-td-jfhfg'>
                                                        <h3 id={entry[0]} dangerouslySetInnerHTML={{__html: entry[0]}}></h3>
                                                    </td>
                                                    <td className='hdgdg-td-jfhfg'>
                                                        <p dangerouslySetInnerHTML={{__html: entry[1].definition}}></p>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        )
                    })
                }
            </div>
        }
        </>
    )
}

export default TechTalkContent;