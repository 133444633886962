import React, { useEffect, useState } from 'react';
import Separator from '../Separator';
import Utils from '../../../lib/Utils';
import { DataGrid } from '@mui/x-data-grid';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import MUIDataGridCellImage from '../MUIDataGridCellImage';
import Texts from '../../../lib/Texts';
import SmallLoader from '../SmallLoader';

/**
 * The Digital Skills Curriculum PINS View 
 * @returns 
 */
const CRCPINSView = () => {
    console.log('{CRCPINSView}')
    const [data, setDBPINSData] = useState({
        album: [],
        fetched: false
    });

    //console.log('data=',data);

    /**
     * Data grid columns
     */
    const columns = [
        { headerClassName: 'hgsg-jhdgd-ljs',field: 'id', headerName: 'BadgeId', width: 80 },
        { headerClassName: 'hgsg-jhdgd-ljs',field: 'lessonNumber', headerName: 'Lesson #', width: 80, },
        { headerClassName: 'hgsg-jhdgd-ljs',field: 'lessonTitle', headerName: 'Lesson', width: 200, },
        { headerClassName: 'hgsg-jhdgd-ljs',field: 'pin', headerName: 'PIN', width: 80, renderCell: (params) => <><b>{params.row.pin}</b></> },
        { headerClassName: 'hgsg-jhdgd-ljs',field: 'icon', headerName: 'Badge Icon', width: 200, renderCell: (params) => <MUIDataGridCellImage srcBase={Utils.CRCDBAssetsURL} params={params} /> }
    ];
    let _terms  = [];
    let _volumes = {};
    Object.values(data.album).forEach(entry => {
        let addedVolume = [];
        const __term = JSON.stringify({
            termId: entry.courseId,
            title: entry.course
        })
        if(_terms.indexOf(__term) === -1) {
            _terms.push(__term)
        }
        _volumes[entry.courseId] = data.album.filter(_entry => {
            if(_entry.courseId === entry.courseId) {
                if(addedVolume.indexOf(_entry.courseCategoryId) < 0) {
                    addedVolume.push(_entry.courseCategoryId);
                    return true;
                }
                return false;
            }
            return false;
        }).map(vol => {
            return {
                volumeId: vol.courseCategoryId, 
                title: vol.courseCategory
            }
        })
    });
    const [term, setTerm] = useState(JSON.stringify({
            termId: 25,
            title: 'Term 1',
        })
    );
   
    const _term = JSON.parse(term);
    const [volume,setVolume] = useState(data.fetched ? _volumes[_term.termId][0] : '');
    //console.log('volume=',volume);
    useEffect(() => {
        if(!data.fetched) {
            Utils.fetchDigitalPINS({dataSetter: setDBPINSData, curriculumId: 104});
        }
    },[data.fetched]);
    return (
        <>
        <div className='jfhdgd-kfkfj w-40 centered'>
            {
                data.fetched &&
                <>
                <FormControl fullWidth>
                    <InputLabel id='term-digitial-pins-select-label'>Term</InputLabel>
                    <Select
                        labelId='term-digitial-pins-select-label'
                        id='term-digitial-pins-select'
                        value={term}
                        label={JSON.parse(term).title}
                        onChange={(e) => {
                            setTerm(e.target.value);
                            setVolume('');
                        }}
                    >
                        {
                            _terms.map((_term,i) => {
                                return (
                                    <MenuItem value={_term} key={i}>{JSON.parse(_term).title}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                <Separator style={{height: '20px'}} />
                <FormControl fullWidth>
                    <InputLabel id='vol-digitial-pins-select-label'>Volume</InputLabel>
                    <Select
                        labelId='vol-digitial-pins-select-label'
                        id='vol-digitial-pins-select'
                        value={volume?JSON.stringify(volume):volume}
                        label={volume.title}
                        onChange={(e) => {
                            setVolume(e.target.value?JSON.parse(e.target.value):'');
                        }}
                    >
                        {
                            _volumes[_term.termId].map((__vol,i) => {
                                return (
                                    <MenuItem value={__vol?JSON.stringify(__vol):__vol} key={i}>{__vol.title}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                </>
            }
        </div>
        {
            !volume ?
            <>
            {
                !data.fetched ?
                <>
                <SmallLoader className='hshsjdfdj' />
                </>
                :
                <>
                <div className='ndjs-jkdjkdhd-ks'>
                    <p className='nshs-leoe'>{Texts.selectAVol}</p>
                </div>
                </>
            }
            </>
            :
            <>
            <Separator style={{height: '20px'}} />
            <div id='list-table-no-fjf' style={{ height: 400}} className='w-50 centred shadow padd-btm-30 animate__animated animate__fadeIn'>
                <DataGrid 
                    rows={data.album.filter(entry => {
                        return entry.courseCategoryId ? entry.courseCategoryId === volume.volumeId : false;
                    })}
                    columns={columns}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            }
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 30 },
                        },
                    }}
                    pageSizeOptions={[30, 60]}
                    checkboxSelection
                />
            </div>
            </>
        }
        </>
    )
}

export default CRCPINSView;


/**
 * {
            id: 255,
            course: 'Volume R',
            courseCategory: null,
            badgeId: 255,
            pin: 7764,
            lessonNumber: 1,
            lastUpdated: '2023-10-11 13:40:37',
            lessonId: 279,
            lessonTitle: 'Shape',
            courseId: 7,
            courseCategoryId: null,
            badgeIcon: 'l7_1.png'
        }
 */