import React from 'react';
import Assets from '../../lib/Assets';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import Utils from '../../lib/Utils';

/**
 * Render something when view/feature is not yet ready
 * @param {object} info The info object
 * @param {fn|boolean} onBackButtonClick The onBackButtonClick method
 * @param {object|boolean} ExtraRenders The ExtraRenders object to render
 * @returns 
 */
const StillWorkingOnThis = ({info,onBackButtonClick = false, ExtraRenders = false}) => {
    console.log('{StillWorkingOnThis}');
    const _p = info?info.p:{};
    return (
        <>
        {
            onBackButtonClick &&
            <div className='ptr ffds-efsvd' title='Back' onClick={() => {
                if(onBackButtonClick) onBackButtonClick();
                Utils.hideFullScreenModal();
            }}>
                <Button variant='contained' className='force-round-btn'>
                    <span><ArrowBackIcon /></span> Back
                </Button>
            </div>
        }
        <div className='stil-work-nhfbgf animate__animated animate__fadeIn'>
            <img src={Assets.misc.stillWorkingOnThis} className='rounded' alt='Still working on this' />
            <p {..._p}>We are still working on this functionality.</p>
            <p {..._p}>Something cool is coming up, please check back soon!</p>
            {
                ExtraRenders &&
                <>
                <ExtraRenders />
                </>
            }
        </div>
        </>
    )
}

export default StillWorkingOnThis;