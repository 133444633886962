
/**
 * For PACE institutions
 */
const CharacterTraits = {
    viewOptions: {
        systemicSameTraitAllDay: 'Students see same character trait all day (Systemic)',
        random: 'Each student sees different character traits (Random)'
    }
}

export default  CharacterTraits;