import React from 'react';
import CurriculumSubscriptionPayment from './CurriculumSubscriptionPayment';

/**
 * Payment initializers
 */
const Payments = {
    /**
 * Initialize a Curriculum subscrion payment
 * @returns 
 */
    CurriculumSubscriptions: () => <CurriculumSubscriptionPayment />,
    /**
     * When we cannot find a valid initializer
     * @returns 
     */
    DefaultInitializer: () => {
        console.log('{Payments.DefaultInitializer}')
        return (
            <><div className='error'><p className='error'>Invalid payment initializer</p></div></>
        )
    }
};

export default Payments;