import { Button } from '@mui/material';
import React from 'react';
import Utils from '../../lib/Utils';
import Assets from '../../lib/Assets';
import { Close } from '@mui/icons-material';

/**
 * Licensee Reg Form view
 * @returns 
 */
const LicenseeRegForm = () => {
    console.log('{LicenseeRegForm}');
    return (
        <div className='hshs09jdhd hshs09jdhd-oso box-b animate__animated animate__fadeInRight w-90 white-bkg shadow rounded centred'>
            <div className='ksjs-peoe ptr' title='Close/Dismiss'>
                <Close sx={{height: 60}} onClick={() => {
                    Utils.resetRegView()
                }} />
            </div>
            <div className='jshshs'>
                <img className='kmjs-wow' src={Assets.licenseeAgreement} alt='' />
                <div className='msjsh-llss box-b'>
                    <p className='hshs-ieoe'>Please follow the link below to fill the <b>e-Learner Foundation Online Access Agreement</b> form:</p>
                </div>
                <Button onClick={() => {
                    Utils.goTo({url: 'https://computers4kids.co.za/onboarding/', inNewWindow: true})
                }}>
                    Got to the Licensee registration form
                </Button>
            </div>
        </div>
    )
}

export default LicenseeRegForm;