import React, { useEffect } from 'react';
import ElearnerAndICDLLogos from '../ElearnerAndICDLLogos';
import Texts from '../../../lib/Texts';
import Assets from '../../../lib/Assets';
import CandidateDetails from '../CandidateDetails';
import ActionModal from '../ActionModal';
import ScoreAnimation from '../ScoreAnimation';
import Utils from '../../../lib/Utils';

/**
 * The El PreTest App
 * @returns 
 */
const ElearnerQuizResults = ({results}) => {
    console.log('{ElearnerQuizResults}');
    useEffect(() => {
        Utils.setPageTitle('Pre Test > Results');
    },[]);
    return (
        <>
        <ElearnerAndICDLLogos />
        <div className='content-ngfgdfs animate__animated animate__fadeIn'>
            <h1>{Texts.pretest.header}</h1>
            <div className='jfhfgf-nfhfhf bkg-white rounded centred w-80'>
                <img id='form-steps' className='bgfgdh-mfhfh' src={Assets.icons.formSteps[2]} alt='Form Steps' />
                <CandidateDetails />
                <div className='results-nfhfgfh'>
                    <h2>Your Test Results</h2>
                    <div className='w-50p centred'>
                        <ScoreAnimation score={results.score} />
                    </div>
                    <h3>You have scored {results.score}%.</h3>
                    <h2 className='recom-mhfhggf' dangerouslySetInnerHTML={{__html: results.recommendation}}></h2>
                </div>
            </div>
        </div>
        <ActionModal />
        </>
    )
}

export default ElearnerQuizResults;