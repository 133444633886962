import React, { useEffect, useState } from 'react';
import Assets from '../../lib/Assets';
import StateUpdators from '../../lib/StateUpdators';
import Utils from '../../lib/Utils';


/**
 * 
 * @param {number} index The current image index
 * @returns 
 */
const RenderAnimatedChildrenImages = ({index}) => {
    console.log('{RenderAnimatedChildrenImages}');
    const [fadeMode, setFadeMode] = useState('animate__fadeIn');
    StateUpdators.setFadeMode = setFadeMode;
    useEffect(() => {
        const timerId = setTimeout(() => {
            StateUpdators.setFadeMode('animate__fadeOut')
        }, Utils.FOUR_SECONDS);
        return () => clearTimeout(timerId);
    })
    return (
        <>
        <img style={{
            backgroundImage: `url(${Assets.sections.children[0]})`,
            backgroundSize: 'cover'
        }} className={`kjs-ldks rounded-right animate__animated ${fadeMode} jsh-${index}`} alt='' src={Assets.sections.children[index]} />
        </>
    )
}

export default RenderAnimatedChildrenImages;