import React, { useEffect, useState } from 'react';
import Utils from '../../lib/Utils';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StateUpdators from '../../lib/StateUpdators';
import PluggableLoader from './PluggableLoader';

/**
 * Tech talk view
 * @param {object} lesson The lesson object 
 * @returns 
 */
const TechTalk = ({lesson}) => {
    const [Content, setTechTalkContent] = useState({
        C: () => <PluggableLoader show={true} msg={'Fetching...'} />
    });
    StateUpdators.setTechTalkContent = setTechTalkContent;
    useEffect(() => {
        Utils.addAnalytics({
            page: 'TechTalk',
            url: window.location.href,
            title: 'NS.TechTalk'
        });
        Utils.fetchTeckTalk({courseId: lesson.lesson.courseId});
    },[lesson.lesson.courseId]);
    return (
        <>
        <div className='ptr ffds-efsvd' title='Back' onClick={() => {
            Utils.hideFullScreenModal();
        }}>
            <Button variant='contained' className='force-round-btn'>
                <span><ArrowBackIcon /></span> Back
            </Button>
        </div>
        <div className='hdgdg-ds box-b w-80 centered rounded shadow animate__animated animate__fadeIn'>
            <h3>Tech Talk</h3>
            <div className='hdgsg-jfhfh'>
                <Content.C />
            </div>
        </div>
        </>
    )
}

export default TechTalk;