import React, {useEffect, useState } from 'react';
import {KeyboardArrowRight } from '@mui/icons-material';
import UserGuidesCache from '../../lib/UserGuidesCache';
import CurrentViewingUserGuide from './CurrentViewingUserGuide';
import Utils from '../../lib/Utils';

/**
 * User Guides View
 * @param {object} data The data/content
 * @returns 
 */
const UserGuidesView = ({data}) => {
    console.log('{UserGuidesView}');
    const [activeDocs, setActiveDocs]  = useState(data.content[0]);
    useEffect(() => {
        Utils.setDefaultViewingUserGuideDocs();
    },[]);
    return (
        <>
        <div className='animate__animated animate__fadeIn'>
            <div className='hdr-jdhdg w-80 h-centred'>
                <h3>User Guides</h3>
            </div>
            <div className='w-100 mjh-gghgh'>
                <div className='lefy-section box-b'>
                    {
                        data.content.map((content,key) => {
                            return (
                                <React.Fragment key={key}>
                                    <div className='sec-tion-mfhfg'>
                                        <div className='nfhfg-sj-peub'>
                                            <div className={`nvhfg-jghgh ptr rounded box-b w-90 h-centred fgfgf-${key}`} key={key} onClick={(e) => {
                                                if(e.currentTarget.nextElementSibling.style.display === 'none') {
                                                    e.currentTarget.nextElementSibling.nextElementSibling.classList.add('ishsg-icons-rotate-down');
                                                    e.currentTarget.nextElementSibling.style.display = 'block';
                                                } else {
                                                    e.currentTarget.nextElementSibling.style.display = 'none';
                                                    e.currentTarget.nextElementSibling.nextElementSibling.classList.remove('ishsg-icons-rotate-down');
                                                }
                                            }}>
                                                <h1 className='tp-lv-hdr'>{content.title}</h1>
                                            </div>
                                            <div style={{
                                                display: 'none'
                                            }} className='nvhfg-jghgh ptr rounded box-b w-90 h-centred'>
                                                {
                                                    content.subTopics.map((item,_key) => {
                                                        UserGuidesCache[item.id] = content;
                                                        return (
                                                            <h3 key={_key} id={item.id} className={`cs-jfhjf first-title-${_key}`} onClick={(e) => {
                                                                Utils.removeActiveLeftDocsViewing();
                                                                e.currentTarget.classList.add('active-docs-vdudhdgf');
                                                                setActiveDocs(UserGuidesCache[e.currentTarget.id]);
                                                            }}>{item.title}</h3>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <KeyboardArrowRight className='hfhgg-clodjdh ptr' titleAccess='Toggle Sections' />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>
                <div className='right-sect-jfhgh box-b'>
                    <CurrentViewingUserGuide info={activeDocs} />
                </div>
            </div>
            <div className='ndheh-mhdhg'>
                <span className='ndheh-mhdhg'>Last updated: {data.lastUpdated}</span>
            </div>
        </div>
        </>
    )
}

export default UserGuidesView;