import React, { useEffect } from 'react';
import Texts from '../../../lib/Texts';
import ActionModal from '../ActionModal';
import ScoreAnimation from '../ScoreAnimation';
import LessonQuizCandidateDetails from '../CandidateDetails';
import ClassLogos from '../ClassLogos';
import Utils from '../../../lib/Utils';
import { Button } from '@mui/material';

/**
 * Display Lesson Quiz Results
 * @returns 
 */
const LessonQuizResults = ({results}) => {
    console.log('{LessonQuizResults}');
    useEffect(() => {
        Utils.clearOnWindowCloseEvent();
        Utils.setPageTitle('Lesson Quiz > Results');
    },[]);
    return (
        <>
        <ClassLogos styles={{paddingTop: '10px'}} curriculum={{shortName: Utils.getCachedCurriculumInfo().extra.shortName}} />
        <div className='content-ngfgdfs animate__animated animate__fadeIn'>
            <h1>{Texts.lessonQuiz.header}</h1>
            <div className='jfhfgf-nfhfhf bkg-white rounded centred w-80'>
                <LessonQuizCandidateDetails />
                <div className='results-nfhfgfh'>
                    <h2>Your Quiz Results</h2>
                    <div className='w-50p centred'>
                        <ScoreAnimation score={results.score} />
                    </div>
                    <h3>You have scored {results.score}%.</h3>
                    <h3>Time Taken: {results.timeTaken}</h3>
                    <h4 className={results.score<80?'results-not-good':'good-results'}>{results.remark}</h4>
                    <Button variant='contained' color='primary' onClick={() => {
                        const currentLessonURL = Utils.getCurrentLessonURL();
                        if (currentLessonURL) window.location = currentLessonURL;
                    }}>
                        Go to lesson
                    </Button>
                </div>
            </div>
        </div>
        <ActionModal />
        </>
    )
}

export default LessonQuizResults;