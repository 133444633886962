const CountryList = [
    { 1: { Id: 1, DialCode: "+93", Name: "Afghanistan", ISOCode3: "Afghanistan" } },
    { 2: { Id: 2, DialCode: "+355", Name: "Albania", ISOCode3: "Albania" } },
    { 3: { Id: 3, DialCode: "+213", Name: "Algeria", ISOCode3: "Algeria" } },
    { 4: { Id: 4, DialCode: "+376", Name: "Andorra", ISOCode3: "Andorra" } },
    { 5: { Id: 5, DialCode: "+244", Name: "Angola", ISOCode3: "Angola" } },
    { 6: { Id: 6, DialCode: "+1", Name: "Antigua and Barbuda", ISOCode3: "Antigua and Barbuda" } },
    { 7: { Id: 7, DialCode: "+54", Name: "Argentina", ISOCode3: "Argentina" } },
    { 8: { Id: 8, DialCode: "+374", Name: "Armenia", ISOCode3: "Armenia" } },
    { 9: { Id: 9, DialCode: "+61", Name: "Australia", ISOCode3: "Australia" } },
    { 10: { Id: 10, DialCode: "+43", Name: "Austria", ISOCode3: "Austria" } },
    { 11: { Id: 11, DialCode: "+994", Name: "Azerbaijan", ISOCode3: "Azerbaijan" } },
    { 12: { Id: 12, DialCode: "+1", Name: "Bahamas", ISOCode3: "Bahamas" } },
    { 13: { Id: 13, DialCode: "+973", Name: "Bahrain", ISOCode3: "Bahrain" } },
    { 14: { Id: 14, DialCode: "+880", Name: "Bangladesh", ISOCode3: "Bangladesh" } },
    { 15: { Id: 15, DialCode: "+1", Name: "Barbados", ISOCode3: "Barbados" } },
    { 16: { Id: 16, DialCode: "+375", Name: "Belarus", ISOCode3: "Belarus" } },
    { 17: { Id: 17, DialCode: "+32", Name: "Belgium", ISOCode3: "Belgium" } },
    { 18: { Id: 18, DialCode: "+501", Name: "Belize", ISOCode3: "Belize" } },
    { 19: { Id: 19, DialCode: "+229", Name: "Benin", ISOCode3: "Benin" } },
    { 20: { Id: 20, DialCode: "+975", Name: "Bhutan", ISOCode3: "Bhutan" } },
    { 21: { Id: 21, DialCode: "+591", Name: "Bolivia", ISOCode3: "Bolivia" } },
    { 22: { Id: 22, DialCode: "+387", Name: "Bosnia and Herzegovina", ISOCode3: "Bosnia and Herzegovina" } },
    { 23: { Id: 23, DialCode: "+267", Name: "Botswana", ISOCode3: "Botswana" } },
    { 24: { Id: 24, DialCode: "+55", Name: "Brazil", ISOCode3: "Brazil" } },
    { 25: { Id: 25, DialCode: "+673", Name: "Brunei ", ISOCode3: "Brunei " } },
    { 26: { Id: 26, DialCode: "+357", Name: "Bulgaria", ISOCode3: "Bulgaria" } },
    { 27: { Id: 27, DialCode: "+226", Name: "Burkina Faso", ISOCode3: "Burkina Faso" } },
    { 28: { Id: 28, DialCode: "+257", Name: "Burundi", ISOCode3: "Burundi" } },
    { 29: { Id: 29, DialCode: "+225", Name: "Côte d'ivoire", ISOCode3: "Côte d'ivoire" } },
    { 30: { Id: 30, DialCode: "+855", Name: "Cambodia", ISOCode3: "Cambodia" } },
    { 31: { Id: 31, DialCode: "+237", Name: "Cameroon", ISOCode3: "Cameroon" } },
    { 32: { Id: 32, DialCode: "+1", Name: "Canada", ISOCode3: "Canada" } },
    { 33: { Id: 33, DialCode: "+236", Name: "Central African Republic", ISOCode3: "Central African Republic" } },
    { 34: { Id: 34, DialCode: "+235", Name: "Chad", ISOCode3: "Chad" } },
    { 35: { Id: 35, DialCode: "+56", Name: "Chile", ISOCode3: "Chile" } },
    { 36: { Id: 36, DialCode: "+86", Name: "China", ISOCode3: "China" } },
    { 37: { Id: 37, DialCode: "+57", Name: "Colombia", ISOCode3: "Colombia" } },
    { 38: { Id: 38, DialCode: "+269", Name: "Comoros", ISOCode3: "Comoros" } },
    { 39: { Id: 39, DialCode: "+242", Name: "Congo", ISOCode3: "Congo" } },
    { 40: { Id: 40, DialCode: "+420", Name: "Czech Republic", ISOCode3: "Czech Republic" } },
    { 41: { Id: 41, DialCode: "+506", Name: "Costa Rica", ISOCode3: "Costa Rica" } },
    { 42: { Id: 42, DialCode: "+385", Name: "Croatia", ISOCode3: "Croatia" } },
    { 43: { Id: 43, DialCode: "+53", Name: "Cuba", ISOCode3: "Cuba" } },
    { 44: { Id: 44, DialCode: "+357", Name: "Cyprus", ISOCode3: "Cyprus" } },
    { 45: { Id: 45, DialCode: "+45", Name: "Denmark", ISOCode3: "Denmark" } },
    { 46: { Id: 46, DialCode: "+253", Name: "Djibouti", ISOCode3: "Djibouti" } },
    { 47: { Id: 47, DialCode: "+1", Name: "Dominica", ISOCode3: "Dominica" } },
    { 48: { Id: 48, DialCode: "+1", Name: "Dominican Republic", ISOCode3: "Dominican Republic" } },
    { 49: { Id: 49, DialCode: "+593", Name: "Ecuador", ISOCode3: "Ecuador" } },
    { 50: { Id: 50, DialCode: "+20", Name: "Egypt", ISOCode3: "Egypt" } },
    { 51: { Id: 51, DialCode: "+503", Name: "El Salvador", ISOCode3: "El Salvador" } },
    { 52: { Id: 52, DialCode: "+240", Name: "Equatorial Guinea", ISOCode3: "Equatorial Guinea" } },
    { 53: { Id: 53, DialCode: "+291", Name: "Eritrea", ISOCode3: "Eritrea" } },
    { 54: { Id: 54, DialCode: "+372", Name: "Estonia", ISOCode3: "Estonia" } },
    { 55: { Id: 55, DialCode: "+268", Name: "Eswatini (Swaziland)", ISOCode3: "Eswatini (Swaziland)" } },
    { 56: { Id: 56, DialCode: "+251", Name: "Ethiopia", ISOCode3: "Ethiopia" } },
    { 57: { Id: 57, DialCode: "+679", Name: "Fiji", ISOCode3: "Fiji" } },
    { 58: { Id: 58, DialCode: "+358", Name: "Finland", ISOCode3: "Finland" } },
    { 59: { Id: 59, DialCode: "+33", Name: "France", ISOCode3: "France" } },
    { 60: { Id: 60, DialCode: "+241", Name: "Gabon", ISOCode3: "Gabon" } },
    { 61: { Id: 61, DialCode: "+220", Name: "Gambia", ISOCode3: "Gambia" } },
    { 62: { Id: 62, DialCode: "+995", Name: "Georgia", ISOCode3: "Georgia" } },
    { 63: { Id: 63, DialCode: "+49", Name: "Germany", ISOCode3: "Germany" } },
    { 64: { Id: 64, DialCode: "+233", Name: "Ghana", ISOCode3: "Ghana" } },
    { 65: { Id: 65, DialCode: "+30", Name: "Greece", ISOCode3: "Greece" } },
    { 66: { Id: 66, DialCode: "+1", Name: "Grenada", ISOCode3: "Grenada" } },
    { 67: { Id: 67, DialCode: "+502", Name: "Guatemala", ISOCode3: "Guatemala" } },
    { 68: { Id: 68, DialCode: "+224", Name: "Guinea", ISOCode3: "Guinea" } },
    { 69: { Id: 69, DialCode: "+245", Name: "Guinea-bissau", ISOCode3: "Guinea-bissau" } },
    { 70: { Id: 70, DialCode: "+592", Name: "Guyana", ISOCode3: "Guyana" } },
    { 71: { Id: 71, DialCode: "+509", Name: "Haiti", ISOCode3: "Haiti" } },
    { 72: { Id: 72, DialCode: "+379", Name: "Holy See", ISOCode3: "Holy See" } },
    { 73: { Id: 73, DialCode: "+504", Name: "Honduras", ISOCode3: "Honduras" } },
    { 74: { Id: 74, DialCode: "+36", Name: "Hungary", ISOCode3: "Hungary" } },
    { 75: { Id: 75, DialCode: "+354", Name: "Iceland", ISOCode3: "Iceland" } },
    { 76: { Id: 76, DialCode: "+91", Name: "India", ISOCode3: "India" } },
    { 77: { Id: 77, DialCode: "+62", Name: "Indonesia", ISOCode3: "Indonesia" } },
    { 78: { Id: 78, DialCode: "+98", Name: "Iran", ISOCode3: "Iran" } },
    { 79: { Id: 79, DialCode: "+964", Name: "Iraq", ISOCode3: "Iraq" } },
    { 80: { Id: 80, DialCode: "+353", Name: "Ireland", ISOCode3: "Ireland" } },
    { 81: { Id: 81, DialCode: "+972", Name: "Israel", ISOCode3: "Israel" } },
    { 82: { Id: 82, DialCode: "+39", Name: "Italy", ISOCode3: "Italy" } },
    { 83: { Id: 83, DialCode: "+1", Name: "Jamaica", ISOCode3: "Jamaica" } },
    { 84: { Id: 84, DialCode: "+81", Name: "Japan", ISOCode3: "Japan" } },
    { 85: { Id: 85, DialCode: "+962", Name: "Jordan", ISOCode3: "Jordan" } },
    { 86: { Id: 86, DialCode: "+7", Name: "Kazakhstan", ISOCode3: "Kazakhstan" } },
    { 87: { Id: 87, DialCode: "+254", Name: "Kenya", ISOCode3: "Kenya" } },
    { 88: { Id: 88, DialCode: "+686", Name: "Kiribati", ISOCode3: "Kiribati" } },
    { 89: { Id: 89, DialCode: "+965", Name: "Kuwait", ISOCode3: "Kuwait" } },
    { 90: { Id: 90, DialCode: "+996", Name: "Kyrgyzstan", ISOCode3: "Kyrgyzstan" } },
    { 91: { Id: 91, DialCode: "+371", Name: "Latvia", ISOCode3: "Latvia" } },
    { 92: { Id: 92, DialCode: "+961", Name: "Lebanon", ISOCode3: "Lebanon" } },
    { 93: { Id: 93, DialCode: "+266", Name: "Lesotho", ISOCode3: "Lesotho" } },
    { 94: { Id: 94, DialCode: "+231", Name: "Liberia", ISOCode3: "Liberia" } },
    { 95: { Id: 95, DialCode: "+218", Name: "Libya", ISOCode3: "Libya" } },
    { 96: { Id: 96, DialCode: "+423", Name: "Liechtenstein", ISOCode3: "Liechtenstein" } },
    { 97: { Id: 97, DialCode: "+370", Name: "Lithuania", ISOCode3: "Lithuania" } },
    { 98: { Id: 98, DialCode: "+352", Name: "Luxembourg", ISOCode3: "Luxembourg" } },
    { 99: { Id: 99, DialCode: "+261", Name: "Madagascar", ISOCode3: "Madagascar" } },
    { 100: { Id: 100, DialCode: "+265", Name: "Malawi", ISOCode3: "Malawi" } },
    { 101: { Id: 101, DialCode: "+60", Name: "Malaysia", ISOCode3: "Malaysia" } },
    { 102: { Id: 102, DialCode: "+960", Name: "Maldives", ISOCode3: "Maldives" } },
    { 103: { Id: 103, DialCode: "+223", Name: "Mali", ISOCode3: "Mali" } },
    { 104: { Id: 104, DialCode: "+356", Name: "Malta", ISOCode3: "Malta" } },
    { 105: { Id: 105, DialCode: "+692", Name: "Marshall Islands", ISOCode3: "Marshall Islands" } },
    { 106: { Id: 106, DialCode: "+222", Name: "Mauritania", ISOCode3: "Mauritania" } },
    { 107: { Id: 107, DialCode: "+230", Name: "Mauritius", ISOCode3: "Mauritius" } },
    { 108: { Id: 108, DialCode: "+52", Name: "Mexico", ISOCode3: "Mexico" } },
    { 109: { Id: 109, DialCode: "+691", Name: "Micronesia", ISOCode3: "Micronesia" } },
    { 110: { Id: 110, DialCode: "+373", Name: "Moldova", ISOCode3: "Moldova" } },
    { 111: { Id: 111, DialCode: "+377", Name: "Monaco", ISOCode3: "Monaco" } },
    { 112: { Id: 112, DialCode: "+976", Name: "Mongolia", ISOCode3: "Mongolia" } },
    { 113: { Id: 113, DialCode: "+382", Name: "Montenegro", ISOCode3: "Montenegro" } },
    { 114: { Id: 114, DialCode: "+212", Name: "Morocco", ISOCode3: "Morocco" } },
    { 115: { Id: 115, DialCode: "+258", Name: "Mozambique", ISOCode3: "Mozambique" } },
    { 116: { Id: 116, DialCode: "+264", Name: "Namibia", ISOCode3: "Namibia" } },
    { 117: { Id: 117, DialCode: "+674", Name: "Nauru", ISOCode3: "Nauru" } },
    { 118: { Id: 118, DialCode: "+977", Name: "Nepal", ISOCode3: "Nepal" } },
    { 119: { Id: 119, DialCode: "+31", Name: "Netherlands", ISOCode3: "Netherlands" } },
    { 120: { Id: 120, DialCode: "+64", Name: "New Zealand", ISOCode3: "New Zealand" } },
    { 121: { Id: 121, DialCode: "+505", Name: "Nicaragua", ISOCode3: "Nicaragua" } },
    { 122: { Id: 122, DialCode: "+227", Name: "Niger", ISOCode3: "Niger" } },
    { 123: { Id: 123, DialCode: "+234", Name: "Nigeria", ISOCode3: "Nigeria" } },
    { 124: { Id: 124, DialCode: "+47", Name: "Norway", ISOCode3: "Norway" } },
    { 125: { Id: 125, DialCode: "+968", Name: "Oman", ISOCode3: "Oman" } },
    { 126: { Id: 126, DialCode: "+92", Name: "Pakistan", ISOCode3: "Pakistan" } },
    { 127: { Id: 127, DialCode: "+680", Name: "Palau", ISOCode3: "Palau" } },
    { 128: { Id: 128, DialCode: "+507", Name: "Panama", ISOCode3: "Panama" } },
    { 129: { Id: 129, DialCode: "+675", Name: "Papua New Guinea", ISOCode3: "Papua New Guinea" } },
    { 130: { Id: 130, DialCode: "+595", Name: "Paraguay", ISOCode3: "Paraguay" } },
    { 131: { Id: 131, DialCode: "+51", Name: "Peru", ISOCode3: "Peru" } },
    { 132: { Id: 132, DialCode: "+63", Name: "Philippines", ISOCode3: "Philippines" } },
    { 133: { Id: 133, DialCode: "+48", Name: "Poland", ISOCode3: "Poland" } },
    { 134: { Id: 134, DialCode: "+351", Name: "Portugal", ISOCode3: "Portugal" } },
    { 135: { Id: 135, DialCode: "+974", Name: "Qatar", ISOCode3: "Qatar" } },
    { 136: { Id: 136, DialCode: "+40", Name: "Romania", ISOCode3: "Romania" } },
    { 137: { Id: 137, DialCode: "+7", Name: "Russia", ISOCode3: "Russia" } },
    { 138: { Id: 138, DialCode: "+250", Name: "Rwanda", ISOCode3: "Rwanda" } },
    { 139: { Id: 139, DialCode: "+1", Name: "Saint Kitts and Nevis", ISOCode3: "Saint Kitts and Nevis" } },
    { 140: { Id: 140, DialCode: "+1", Name: "Saint lucia", ISOCode3: "Saint lucia" } },
    { 141: { Id: 141, DialCode: "+1", Name: "Saint Vincent and the Grenadines", ISOCode3: "Saint Vincent and the Grenadines" } },
    { 142: { Id: 142, DialCode: "+685", Name: "Samoa", ISOCode3: "Samoa" } },
    { 143: { Id: 143, DialCode: "+378", Name: "San Marino", ISOCode3: "San Marino" } },
    { 144: { Id: 144, DialCode: "+239", Name: "Sao Tome and Principe", ISOCode3: "Sao Tome and Principe" } },
    { 145: { Id: 145, DialCode: "+966", Name: "Saudi Arabia", ISOCode3: "Saudi Arabia" } },
    { 146: { Id: 146, DialCode: "+221", Name: "Senegal", ISOCode3: "Senegal" } },
    { 147: { Id: 147, DialCode: "+381", Name: "Serbia", ISOCode3: "Serbia" } },
    { 148: { Id: 148, DialCode: "+248", Name: "Seychelles", ISOCode3: "Seychelles" } },
    { 149: { Id: 149, DialCode: "+232", Name: "Sierra Leone", ISOCode3: "Sierra Leone" } },
    { 150: { Id: 150, DialCode: "+65", Name: "Singapore", ISOCode3: "Singapore" } },
    { 151: { Id: 151, DialCode: "+421", Name: "Slovakia", ISOCode3: "Slovakia" } },
    { 152: { Id: 152, DialCode: "+386", Name: "Slovenia", ISOCode3: "Slovenia" } },
    { 153: { Id: 153, DialCode: "+677", Name: "Solomon Islands", ISOCode3: "Solomon Islands" } },
    { 154: { Id: 154, DialCode: "+252", Name: "Somalia", ISOCode3: "Somalia" } },
    { 155: { Id: 155, DialCode: "+27", Name: "South Africa", ISOCode3: "South Africa" } },
    { 156: { Id: 156, DialCode: "+34", Name: "Spain", ISOCode3: "Spain" } },
    { 157: { Id: 157, DialCode: "+94", Name: "Sri Lanka", ISOCode3: "Sri Lanka" } },
    { 158: { Id: 158, DialCode: "+249", Name: "Sudan", ISOCode3: "Sudan" } },
    { 159: { Id: 159, DialCode: "+597", Name: "Suriname", ISOCode3: "Suriname" } },
    { 160: { Id: 160, DialCode: "+46", Name: "Sweden", ISOCode3: "Sweden" } },
    { 161: { Id: 161, DialCode: "+41", Name: "Switzerland", ISOCode3: "Switzerland" } },
    { 162: { Id: 162, DialCode: "+963", Name: "Syria", ISOCode3: "Syria" } },
    { 163: { Id: 163, DialCode: "+992", Name: "Tajikistan", ISOCode3: "Tajikistan" } },
    { 164: { Id: 164, DialCode: "+886", Name: "Taiwan", ISOCode3: "Taiwan" } },
    { 165: { Id: 165, DialCode: "+255", Name: "Tanzania", ISOCode3: "Tanzania" } },
    { 166: { Id: 166, DialCode: "+66", Name: "Thailand", ISOCode3: "Thailand" } },
    { 167: { Id: 167, DialCode: "+670", Name: "Timor-leste", ISOCode3: "Timor-leste" } },
    { 168: { Id: 168, DialCode: "+228", Name: "Togo", ISOCode3: "Togo" } },
    { 169: { Id: 169, DialCode: "+676", Name: "Tonga", ISOCode3: "Tonga" } },
    { 170: { Id: 170, DialCode: "+1", Name: "Trinidad and Tobago", ISOCode3: "Trinidad and Tobago" } },
    { 171: { Id: 171, DialCode: "+216", Name: "Tunisia", ISOCode3: "Tunisia" } },
    { 172: { Id: 172, DialCode: "+90", Name: "Turkey", ISOCode3: "Turkey" } },
    { 173: { Id: 173, DialCode: "+993", Name: "Turkmenistan", ISOCode3: "Turkmenistan" } },
    { 174: { Id: 174, DialCode: "+688", Name: "Tuvalu", ISOCode3: "Tuvalu" } },
    { 175: { Id: 175, DialCode: "+256", Name: "Uganda", ISOCode3: "Uganda" } },
    { 176: { Id: 176, DialCode: "+380", Name: "Ukraine", ISOCode3: "Ukraine" } },
    { 177: { Id: 177, DialCode: "+971", Name: "United Arab Emirates", ISOCode3: "United Arab Emirates" } },
    { 178: { Id: 178, DialCode: "+44", Name: "United Kingdom", ISOCode3: "United Kingdom" } },
    { 179: { Id: 179, DialCode: "+1", Name: "United States of America", ISOCode3: "United States of America" } },
    { 180: { Id: 180, DialCode: "+598", Name: "Uruguay", ISOCode3: "Uruguay" } },
    { 181: { Id: 181, DialCode: "+998", Name: "Uzbekistan", ISOCode3: "Uzbekistan" } },
    { 182: { Id: 182, DialCode: "+678", Name: "Vanuatu", ISOCode3: "Vanuatu" } },
    { 183: { Id: 183, DialCode: "+58", Name: "Venezuela", ISOCode3: "Venezuela" } },
    { 184: { Id: 184, DialCode: "+84", Name: "Vietnam", ISOCode3: "Vietnam" } },
    { 185: { Id: 185, DialCode: "+967", Name: "Yemen", ISOCode3: "Yemen" } },
    { 186: { Id: 186, DialCode: "+260", Name: "Zambia", ISOCode3: "Zambia" } },
    { 187: { Id: 187, DialCode: "+263", Name: "Zimbabwe", ISOCode3: "Zimbabwe" } }
]

/**
 * Export
 */
export default CountryList