import React, { useEffect, useRef } from 'react';
import Utils from '../../../lib/Utils';
import CRCPopupHandleTitle from '../CRCPopupHandleTitle';


/**
 * View lesson instructions
 * @param {object} props The props object
 * @returns 
 */
const CRCInstructions = ({lesson}) => {
    console.log('{CRCInstructions} lesson:',lesson);
    //const [lan, setLanguage] = useState('eng');
    //StateUpdators.setLanguage = setLanguage;
    const _lesson = useRef({lessonId: lesson.lesson.lessonId, courseId: lesson.lesson.courseId});
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Instructions',
            url: window.location.href,
            title: 'NSCRC.Instructions',
            lessonId: _lesson.current.lessonId,
            courseId: _lesson.current.courseId
        });
    },[]);
    return (
        <>
        <div className='hfgfgf-nvfg-task- w-70 h-centred rounded-small animate__animated animate__fadeIn'>
            <h4 className='jdhd-hfg-i' dangerouslySetInnerHTML={{__html: `${lesson.curriculumTitle} > ${lesson.courseTitle} > ${lesson.subCourseTitle?` ${lesson.subCourseTitle} >`:''} ${lesson.lesson.number}. ${lesson.lesson.lessonTitle}`}}></h4>
            <CRCPopupHandleTitle
                title={'Instructions'}
                onLeftIconClick={() => {
                    Utils.closeLessonTaskPageModal();
                }}
            />
        </div>
        <div className='bgg-booklet content-center jsks-eops white-bkg bdr-bl shadow nndjdjdj w-70 h-centred rounded-small animate__animated animate__fadeIn'>
            <div className='hhsd-kdbdjvwingd shadow rounded box-b' dangerouslySetInnerHTML={{__html: lesson.lesson.activity}}></div>
        </div>
        </>
    )
}

export default CRCInstructions;