import React  from 'react';
import CommunityCurriculum from '../../lib/CommunityCurriculum';
import Utils from '../../lib/Utils';
import Theme from '../../lib/Theme';
import Assets from '../../lib/Assets';

/**
 * PoweredByC4KLogoRightBottom
 * @returns 
 */
const PoweredByC4KLogoRightBottom = () => {
    console.log('{PoweredByC4KLogoRightBottom}');
    const curriculum = Utils.getCachedCurriculumInfo();
    return (
        <>
        {
            curriculum.extra.shortName === CommunityCurriculum.shortName &&
            <div className='jhs-jdhdjf ptr powered-by'>
                <img title='Powered by Computers 4 kids' className={Theme.content.animated.fadeIn} alt='Computers 4 Kids' src={Assets.poweredByC4K} />
            </div>
        }
        </>
    )
}

export default PoweredByC4KLogoRightBottom;