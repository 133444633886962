import React from 'react';
import Assets from '../../lib/Assets';

/**
 * User Notifications
 * @returns 
 */
const Notifications = () => {
    console.log('{Notifications}');
    return (
        <>
        <div className='jdhdh-jfhf'>
            <h3>Notifications</h3>
        </div>
        <div className='jddh-jghf-mdhd'>
            <div className='jfhfh'>
                <img alt='Nothing at the moment' src={Assets.icons.emptyBox} />
                <h4>Nothing at the moment.</h4>
            </div>
        </div>
        </>
    )
}

export default Notifications;