import React from 'react';

/**
 * 
 * @param {object} info The info object 
 * @returns 
 */
const CambridgeProgram = ({info}) => {
    console.log('{CambridgeProgram}')
    //console.log('{CambridgeProgram}: info=',info)
    return (
        <>
        <div className='text-centre'>
            <h3>Cambridge International Curriculum alignment</h3>
        </div>
        <div className='jchdj-kdjkdj'>
            <div className={`mcndj-lsks ${(info.primaryId && info.secondaryId)?'jshs-pwsl':'kjsd-ldjdkd'}`}>
                {
                    info.primaryId &&
                    <>
                    <div className='msks-ldkd'>
                        <div className='ncjdjs-pwjs content-centre msks0s'>
                            <p>Primary IDs</p>
                        </div>
                        <div className='kdjskpel ndhs-lspws'>
                        {
                            info.primaryId.split("\n").map((el,i) => {
                                return (
                                    <p className='jjs-ldksk' key={i}>{el}</p>
                                )
                            })
                        }
                        </div>
                    </div>
                    </>
                }
                {
                    info.secondaryId &&
                    <>
                    <div className='msks-ldkd'>
                        <div className='ncjdjs-pwjs content-centre msks0s'>
                            <p>Secondary Lower IDs</p>
                        </div>
                        <div className='ndhs-lspws'>
                        {
                            info.secondaryId.split("\n").map((el,i) => {
                                return (
                                    <p className='jjs-ldksk' key={i}>{el}</p>
                                )
                            })
                        }
                        </div>
                    </div>
                    </>
                }
            </div>
            <div className='mcndj-lsks'>
                <div className='ncjdjs-pwjs content-centre'>
                    <p>Subjects</p>
                </div>
                <div className='sjs-pdkd box-b'>
                {
                    info.subjects.split("\n").map((el,i) => {
                        return (
                            <p className='ksjs-pmdjs' key={i}>{el}</p>
                        )
                    })
                }
                </div>
            </div>
        </div>
        </>
    )
}

export default CambridgeProgram;