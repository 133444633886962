import { Button } from '@mui/material';
import React from 'react';
import Utils from '../../lib/Utils';


const TableActions = {
    /**
     * Full actions view
     * @returns 
     */
    ActionsOnMultipleStudents: () => {
        console.log('{TableActions.ActionsOnMultipleStudents}');
        return (
            <ActionsOnMultipleStudents />
        )
    },
    /**
     * Partial actions view
     * @returns 
     */
    ActionsOnASingleStudent: () => {
        console.log('{TableActions.ActionsOnASingleStudent}')
        return (
            <ActionsOnASingleStudent />
        )
    }
};


/**
 * Full actions view. For actions that can be applied to multiple users/students.
 * @param {object} students A students array to perform actions on
 * @returns 
 */
export const ActionsOnMultipleStudents = ({students}) => {
    console.log('{ActionsOnMultipleStudents}');
    return (
        <>
        <div className='nfgd-kfjfhd'>
            <div className='jhdjsjs nhs-left'>
                <h3 className='mgn-0'>Actions:</h3>
            </div>
            <div className='jshsh-jdjhs'>
                <Button variant='contained' color='error' className='ptr' title='Delete selected students' onClick={() => {
                    Utils.deleteInstitutionStudents(students);
                }}>
                    Delete
                </Button>
                <Button variant='contained' color='warning' className='ptr' title='Disable/Enable selected students'>
                    Enable/Disable
                </Button>
                <Button variant='contained' color='primary' className='ptr' title='More actions on the selected students'>
                    More Actions
                </Button>
            </div>
        </div>
        </>
    )
}

/**
 * Partial actions view. For actions that ONLY can be applied to a single user/student.
 * @param {number} studentId A students array to perform actions on
 * @returns
 */
export const ActionsOnASingleStudent = ({studentId}) => {
    console.log('{ActionsOnASingleStudent}');
    return (
        <>
        <div className='nfgd-kfjfhd'>
            <div className='jhdjsjs nhs-left'>
                <h3 className='mgn-0'>Actions:</h3>
            </div>
            <div className='jshsh-jdjhs'>
                <Button variant='contained' color='error' className='ptr' title='Update selected student' onClick={() => {
                    Utils.updateInstitutionStudents({studentId: studentId});
                }}>
                    Update
                </Button>
                <Button variant='contained' color='warning' className='ptr' title='Disable/Enable selected student' onClick={() => {
                    Utils.updateInstitutionStudents({studentId: studentId});
                }}>
                    Enable/Disable
                </Button>
                <Button variant='contained' color='primary' className='ptr' title='More actions on the selected student'>
                    More Actions
                </Button>
            </div>
        </div>
        </>
    )
}

export default TableActions;