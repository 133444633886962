import React from 'react';
import '../../css/support.css';
import C4KLogo from './C4KLogo';
import GuidesSearchView from './GuidesSearchView';
import GuidesTopicsView from './GuidesTopicsView';

/**
 * 
 * @param {object} token The token info 
 * @returns 
 */
const SupportApp = ({token}) => {
    console.log('{SupportApp}')
    console.log('token=',token);
    return (
        <>
        <div  className='hsgs0kdjs box-b greyish-bkg animate__animate__fadeIn'>
            <div className='jhhss-jdnd-support-app white-bkg box-b rounded shadow'>
                <div className='nhs-lefyjhs'>
                    <C4KLogo />
                    <GuidesSearchView />
                    <GuidesTopicsView />
                </div>
                <div>
                </div>
            </div>
        </div>
        </>
    )
}

export default SupportApp;