import React, { useEffect, useState } from 'react';
import StateUpdators from '../../lib/StateUpdators';
import Utils from '../../lib/Utils';
import SmallLoader from './SmallLoader';
import Separator from './Separator';

/**
 * Student settings
 * @param {object} data The data object
 * @returns 
 */
const StudentSettings = ({data}) => {
    console.log('{StudentSettings}');
    data = {};
    const [Content, setStudentSettingsContent] = useState({
        C: () => <div className='w-60 hsgsg-jshsh centred'><Separator styles={{height: '200px'}} /><SmallLoader customClasses='jjhs-djdh' show={true} /></div>
    })
    StateUpdators.setStudentSettingsContent = setStudentSettingsContent;
    useEffect(() => {
        Utils.checkIfNewLMSSpecialAccount();
    },[])
    return (
        <>
        <Content.C />
        </>
    )
}

export default StudentSettings;