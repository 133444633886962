import React from 'react';
import Assets from '../../lib/Assets';
import Texts from '../../lib/Texts';

const AllICDLCategories = () => {
    console.log('{AllICDLCategories}');
    return (
        <>
        <div className='hfhdh-qxs w-70 centred rounded shadow'>
            <h3>{Texts.aboutICDLCats}</h3>
            <img alt={'ICDL Units'} src={Assets.icons.icdl.all} />
        </div>
        </>
    )
}

export default AllICDLCategories;