import React, { useEffect, useRef, useState } from 'react';
import Utils from '../../lib/Utils';
import StateUpdators from '../../lib/StateUpdators';
import { MuiOtpInput } from 'mui-one-time-password-input';
import PluggableLoader from './PluggableLoader';
import Separator from './Separator';
import { Button } from '@mui/material';
import LoginFormViewLoginPart from './LoginFormViewLoginPart';
import Texts from '../../lib/Texts';

/**
 * ResetPasswordOTPView
 * @param {number} challengeId The challengeId  
 * @returns 
 */
const ResetPasswordOTPView = ({challengeId}) => {
    console.log('{ResetPasswordOTPView}');
    const TWO_SECS = 2000;
    const [pin, setPIN] = useState('');
    const [_validating, setValidating] = useState({
        doing: true,
        done: false
    });
    const handleOnChange = (newValue) => {
        setPIN(newValue);
    }
    const validating = useRef(null);
    let timer = useRef(null);
    const handleOnComplete = (value) => {
        if(!validating.current) {
            setValidating({
                doing: false,
                done: false,
            });
            validating.current = true;
            timer = setTimeout(() => {
                Utils.validatePasswordResetCode({challengeId: challengeId, code: parseInt(value)});
            }, TWO_SECS);
        } else {
            if(_validating.retry??false) {
                setValidating({
                    doing: false,
                    done: false,
                });
                validating.current = true;
                timer = setTimeout(() => {
                    Utils.validatePasswordResetCode({challengeId: challengeId, code: parseInt(value)});
                }, TWO_SECS);
            }
        }
    }
    const matchIsNumeric = (text) => {
        const isNumber = typeof text === 'number';
        return (isNumber || (text !== '')) && !isNaN(Number(text));
    }
    // eslint-disable-next-line
    const validateChar = (value,index) => {
        return matchIsNumeric(value);
    }
    const [V,setValidationResults] = useState({
        C: () => (<></>)
    });
    const _msg = 'Validating code';
    StateUpdators.setValidationResults = setValidationResults;
    StateUpdators.setValidating = setValidating;
    StateUpdators.setPIN = setPIN;
    useEffect(() => {
        const __timer = timer.current;
        return () => clearTimeout(__timer);
    });
    return (
        <>
        <div className='ncbs-ldks'>
            <div className='mjd-wpx'>
                <h1 className='mjdjhd-qa'>{`Welcome Back :)`}</h1>
                <p className='mdjes-pa'>{`Let's reset your account password.`}</p>
            </div>
            <div className='mjdjhd-soow box-b'>
                <div className='w-80 centred'>
                {
                    _validating.doing ? 
                    <>
                    <p>Enter the 6-digit code you received from us:</p>
                    <span className='ndhsh-bs-sure'>{Texts.bSure}</span>
                    <Separator style={{height: '20px'}} />
                    <MuiOtpInput
                        autoFocus
                        TextFieldsProps={{ placeholder: '-' }}
                        value={pin} 
                        length={6}
                        onChange={handleOnChange} 
                        onComplete={handleOnComplete}
                        validateChar={validateChar}
                    />
                    </>:
                    _validating.done ?
                    <>
                    <V.C />
                    </> :
                    <div className=' content-centre'>
                        <PluggableLoader inlineCSS={false} title={_msg} show={true} parentWidth={300} parentHeight={80} width={80} />
                    </div>
                }
                </div>
                <Separator style={{height: '30px'}} />
                <div className='w-80 centred text-align-right'>
                    <Button color='error' className='ptr force-round-btn' title='Cancel' onClick={() => {
                        StateUpdators.setLoginFormViewLoginPart({
                            C: () => <LoginFormViewLoginPart />
                        });
                    }}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
        </>
    )
}

export default ResetPasswordOTPView;