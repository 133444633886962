import { Info } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import Utils from '../../lib/Utils';
import BaseName from '../../lib/BaseName';

/**
 * TrilaInfo
 * @returns 
 */
const TrilaInfo = ({expiresOn}) => {
    console.log('{TrilaInfo}');
    //console.log('{TrilaInfo}: expiresOn=',expiresOn)
    return (
        <>
        <div className='gsfs-iei box-b rounded shadow animate__animated animate__fadeIn w-80 centred'>
            <div className='content-centre'>
                <Info sx={{
                    fontSize: 40
                }} />
            </div>
            {
                expiresOn.hasExpired ? 
                <div className='nsbg-wps-expired'>
                    <p className='mgn-0'>You are on a {expiresOn.days} day trial subscription plan, and it expired on {expiresOn.day} {expiresOn.date}, {expiresOn.year} at {expiresOn.time}. Pay for a plan to continue access.</p>
                </div> :
                <div className='nsbg-wps'>
                    <p className='mgn-0'>You are on a {expiresOn.days} day trial subscription plan, valid until {expiresOn.day} {expiresOn.date}, {expiresOn.year} at {expiresOn.time}.</p>
                </div>
            }
            <div className='content-centre'>
                <Button onClick={() => {
                    Utils.goTo({url: `/${BaseName.name}/subscribe`});
                }} className='ptr force-round-btn' title='Subscribe for uninturrpted access' variant='contained' color='primary'>
                    View Plans
                </Button>
            </div>
        </div>
        </>
    )
}

export default TrilaInfo;