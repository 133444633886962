import React, { useEffect } from 'react';
import Utils from '../lib/Utils';
import AbsoluteCOLLogo from './tools/AbsoluteCOLLogo';
import HomePageNavMenu from './tools/HomePageNavMenu';
import MobileNavMenu from './tools/MobileNavMenu';
import TheSignupView from './tools/TheSignupView';
import COLFooter from './tools/COLFooter';
import Error from './tools/Error';
import ReportError from './tools/ReportError';
import RightSidePopin from './tools/RightSidePopin';
import Loader from './tools/Loader';

/**
 * The signup form
 * @returns 
 */
const Signup = () => {
    console.log('{Signup}');
    useEffect(() => {
        Utils.setPageTitle('Create a new account');
        Utils.addAnalytics({
            page: 'SignUp',
            url: window.location.href,
            title: 'NS.SignUp'
        });
    },[])
    return (
        <>
        <div className='c4k-home hg-poo-g animate__animated animate__fadeIn'>
            <div className='mshsh-lkdks'>
                <div className='ksjsjs'>
                    <AbsoluteCOLLogo styles={{
                        left: '20px',
                        top: '20px'
                    }} />
                </div>
                <div className='nshsghd-ldkdj-nav'>
                    <HomePageNavMenu page={'signup'} />
                </div>
            </div>
            <MobileNavMenu page={'signup'} />
            <TheSignupView />
        </div>
        <COLFooter />
        <Error />
        <ReportError />
        <RightSidePopin />
        <Loader />
        </>
    )
}

export default Signup;