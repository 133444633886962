import { Edit } from '@mui/icons-material';
import React, { useState } from 'react';
import Utils from '../../lib/Utils';
import UploadProfilePciture from './UploadProfilePciture';
import ActionModal2 from './ActionModal2';
import StateUpdators from '../../lib/StateUpdators';

/**
 * Display a user avater
 * @param {string} src The avatar src 
 * @returns 
 */
const UserAvatar = ({shouldUpdateAvatar,src}) => {
    console.log('{UserAvatar}');
    const action = shouldUpdateAvatar ? 'Upload your profile picture' : 'Update your profile picture';
    const [_avSRc, setUserAvater] = useState(src);
    const accept = {
        'image/png': ['.png'],
        'image/jp*': ['.jpeg','.jpg']
    };
    StateUpdators.setUserAvater = setUserAvater;
    return (
        <>
        <div className='i-agaf-hfuns'>
            <img alt='User Avatar' className='nfhfh-u-avater' src={_avSRc} />
            {
                <div className='ptr chdh-jfjf' onClick={() => {
                    Utils.showModal2({Content: () => (<UploadProfilePciture accept={accept} />)});
                }}>
                    <Edit titleAccess={`${action}`} />
                </div>
            }
        </div>
        <ActionModal2 />
        </>
    )
}

export default UserAvatar;